import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { RiArrowDropDownLine } from "react-icons/ri";
import i18next from "i18next";
import { TbWorldDownload } from 'react-icons/tb';
import { MdArrowDropDown } from 'react-icons/md';
const Navbar = ({ bgcolor }) => {
  const language = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
    },

    {
      code: 'ar',
      name: 'العربية',
      country_code: 'sa',
    }
  ]
  // setting mobile nav
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  // close menu on click
  const closeMenu = () => setClick(false);

  // change nav color when scrolling

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <div
      className={
        color || !bgcolor
          ? "header bg-black  z-50 spacingYtext rajez spacingYlogo"
          : "header bg-black bg-opacity-70 rajez"

        // : "header  bg-gradient-to-b from-black via-black bg-opacity-0 rajez"
      }
    >
      <nav className="navbar">
        <Link to="/" className="logo">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            className="cursor-pointer md:w-28 w-24"
          />
        </Link>

        <ul className={click ? "nav-menu active md:space-y-0 space-y-4" : "nav-menu"}>
          <li className="group dropdown drop-shadow-2xl md:flex hidden nav-item text-sm  font-semibold nav-desk  text-white hover:text-primary group relative cursor-pointer">
            WHY i2V ?
            <div className="group-hover:block dropdown-menu absolute hidden transition-all duration-300 translate-y-2 h-auto top-10 md:-left-10">
              <div className="top-0 bg-black shadow-xl shadow-white/20 text-white text-xs w-52 flex flex-col">
                <Link to="/whyi2v" className=" hover:bg-primary hover:text-white py-2 m-1">
                  WHY i2V ?
                </Link>
                <Link to="/success-stories" className=" hover:bg-primary hover:text-white py-2 m-1 uppercase">
                  Success Stories
                </Link>
                <Link to="/certification" className=" hover:bg-primary hover:text-white py-2 m-1 uppercase">
                  Certifications
                </Link>
                <Link to="/news" className=" hover:bg-primary hover:text-white py-2 m-1">
                  NEWS & EVENTS
                </Link>
              </div>
            </div>
          </li>
          {/* for mobile start */}
          <li className="md:hidden block w-full bg-gradient-to-r from-black/50 via-primary to-black/50">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="py-2 text-white flex justify-center items-center  w-full">
                    WHY i2V ?
                    <RiArrowDropDownLine
                      className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8 "}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-500 font-semibold">
                    <ul className="bg-white text-black py-4 space-y-2 divide-y divide-red-400 rounded">
                      <li className="hover:bg-gray-50">
                        <Link to="/whyi2v">Why i2V ?</Link>
                      </li>

                      <li className="hover:bg-gray-50">
                        <Link to="/success-stories"> Success Stories</Link>
                      </li>
                      <li className="hover:bg-gray-50">
                        <Link to="/certification">Certifications</Link>
                      </li>
                      <li>
                        <Link to="/news">News & Events </Link>
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
          {/* for mobile end */}

          <li className="group dropdown drop-shadow-2xl md:flex hidden nav-item text-sm uppercase font-semibold nav-desk  text-white hover:text-primary group relative cursor-pointer">
            PRODUCTS
            <div className="group-hover:block dropdown-menu absolute hidden transition-all  duration-300 translate-y-2 h-auto top-10 md:-left-10">
              <div className="top-0 bg-black shadow-xl shadow-white/20 text-white text-start text-xs w-64  flex flex-col">
                <Link to="/ip-Video" className=" hover:bg-primary hover:text-white p-3 m-1">
                  IP Video Management Software
                </Link>
                <div className="group dropdownHover hover:bg-primary hover:text-white m-1">
                  <Link to="/ai-Based-Video" className="flex justify-between items-center dropRotate p-3">
                    AI Based Video Analytics
                    <RiArrowDropDownLine className="iconRotate w-6 h-6" />
                  </Link>

                  <ul className="dropdown-content absolute whitespace-nowrap  hidden text-white text-start bg-black pl-4 top-4 ml-60 border-primary border-y-2 divide-y divide-primary divide-opacity-40 md:h-[80vh] 2xl:h-fit overflow-auto">
                    <Link to="/crowd-counting-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      Crowd & counting Detection
                    </Link>
                    <Link to="/queue-analysis-length-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      queue analysis and length Detection
                    </Link>
                    <Link to="/no-ppe-kit-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      No PPE Kit Detection
                    </Link>
                    <Link to="/perimeter-intrusion-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      Perimeter / intrusion detection
                    </Link>
                    <Link to="/boundary-loitering-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      boundary Loitering detection
                    </Link>
                    <Link to="/people-counting" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      People Counting
                    </Link>
                    <Link to="/abandoned-object-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      abandoned object detection
                    </Link>
                    <Link to="/object-removal-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      object removal detection
                    </Link>
                    <Link to="/camera-tampring-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      Camera Tampering Detection
                    </Link>
                    <Link to="/armed-person-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      armed person Detection
                    </Link>
                    <Link to="/video-based-firedetection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      video based fire detection
                    </Link>
                    <Link to="/human-pattern-recognition" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      human pattern recognition
                    </Link>
                    <Link to="/anpr" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      anpr / lpr
                    </Link>
                    <Link to="/stopped-vehicle-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      stopped VEHICLE detection
                    </Link>

                    <Link to="/wrong-way-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      Wrong way detection
                    </Link>
                    <Link to="/speed-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      speed detection
                    </Link>
                    <Link to="/no-helmet-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      no helmet Detection
                    </Link>
                    <Link to="/debris-garbage-detection" className="hover:bg-primary hover:text-white py-[7px] px-4 block">
                      Debris and Garbage Detection
                    </Link>
                  </ul>
                </div>
                {/* <Link to="/intelligent-traffic" className=" hover:bg-primary hover:text-white p-2 m-2">
                  Intelligent Traffic Solutions
                </Link> */}
                <div className="group dropdownHover hover:bg-primary hover:text-white m-1">
                  <Link to="/intelligent-traffic" className="flex justify-between items-center dropRotate p-3">
                    Intelligent Traffic Solutions
                    <RiArrowDropDownLine className="iconRotate w-6 h-6" />
                  </Link>

                  <ul className="dropdown-content absolute whitespace-nowrap  hidden text-white text-start bg-black pl-4 top-24 ml-60 border-primary border-y-2  divide-y divide-primary divide-opacity-40">

                    <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                      <Link to="/automatic-number-plate-recognition" className="">
                        Automatic Number Plate Recognition (ANPR) /<br /> License Plate Recognition (LPR)
                      </Link>
                    </li>
                    <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                      <Link to="/automatic-traffic-counting-classification" className=" ">
                        Automatic traffic counting and classification (ATCC)
                      </Link>
                    </li>
                    <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                      <Link to="/video-incident-detection-system" className="">
                        Video Incident Detection System (VIDS)
                      </Link>
                    </li>
                    <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                      <Link to="/violation" className="">
                        Violations
                      </Link>
                    </li>


                  </ul>
                </div>
                <Link to="/central-monitoring" className=" hover:bg-primary hover:text-white p-4 m-1">
                  Central Monitoring System
                </Link>
                <Link to="/cloud-surveillance" className=" hover:bg-primary hover:text-white p-4 m-1">
                  Cloud Surveillance
                </Link>
                <Link to="/integrated-command" className=" hover:bg-primary hover:text-white p-4 m-1">
                  command & Control Center /  PSIM
                </Link>
                <Link to="/face-recognition" className=" hover:bg-primary hover:text-white p-4 m-1">
                  Face Recognition
                </Link>
              </div>
            </div>
          </li>

          {/* for mobile start */}
          <li className="md:hidden block w-full">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="py-2 text-white flex items-center bg-gradient-to-r from-black/50 via-primary to-black/50 justify-center w-full">
                    PRODUCTS
                    <RiArrowDropDownLine
                      className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8"}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-500 font-semibold">
                    <div className="space-y-3 pt-3">
                      <li className="w-full text-base font-medium uppercase bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
                        <Link to="/ip-Video">
                          <div className="py-3 text-white flex items-center   justify-center w-full">
                            IP Video Management Software
                          </div>
                        </Link>
                      </li>
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="py-2 text-base font-medium uppercase text-white flex items-center bg-gradient-to-r from-black/50 via-primary to-black/50 justify-center px-7 w-full">
                              AI Based Video Analytics
                              <RiArrowDropDownLine
                                className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8"}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-gray-500 font-semibold">
                              <ul className="bg-white text-black py-4 space-y-2 divide-y divide-red-400 md:rounded">
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/ai-Based-Video" className="">
                                    Overview
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/crowd-counting-detection" className="">
                                    Crowd & Counting Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/queue-analysis-length-detection" className="">
                                    Queue Analysis And Length Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/no-ppe-kit-detection" className="">
                                    No PPE Kit Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/perimeter-intrusion-detection" className="">
                                    Perimeter / Intrusion Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/boundary-loitering-detection" className="">
                                    Boundary Loitering Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/people-counting" className="">
                                    People Counting
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/abandoned-object-detection" className=" ">
                                    Abandoned Object Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/object-removal-detection" className="">
                                    Object Removal Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/camera-tampring-detection" className="">
                                    Camera Tampering Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/armed-person-detection" className="">
                                    Armed Person Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/video-based-firedetection" className="">
                                    Video Based Fire Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/human-pattern-recognition" className="">
                                    Human Pattern Recognition
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/anpr" className="">
                                    ANPR / LPR
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/stopped-vehicle-detection" className="">
                                    Stopped Vehicle Detection
                                  </Link>
                                </li>

                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/wrong-way-detection" className="">
                                    Wrong Way Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/speed-detection" className="">
                                    Speed Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/no-helmet-detection" className="">
                                    No Helmet Detection
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/debris-garbage-detection" className="">
                                    Debris Garbage Detection
                                  </Link>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="py-2 text-base font-medium uppercase text-white flex items-center bg-gradient-to-r from-black/50 via-primary to-black/50 justify-center px-7 w-full">
                              Intelligent Traffic Solutions
                              <RiArrowDropDownLine
                                className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8"}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-gray-500 font-semibold">
                              <ul className="bg-white text-black py-4 space-y-2 divide-y divide-red-400 md:rounded">
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/intelligent-traffic" className="">
                                    Overview
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/automatic-number-plate-recognition" className="">
                                    Automatic Number Plate Recognition (ANPR) / LPR
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/automatic-traffic-counting-classification" className=" ">
                                    Automatic traffic counting and classification (ATCC)
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/video-incident-detection-system" className="">
                                    Video Incident Detection System (VIDS)
                                  </Link>
                                </li>
                                <li className="hover:bg-primary hover:text-white py-[7px] px-4">
                                  <Link to="/violation" className="">
                                    Violations
                                  </Link>
                                </li>
                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <li className="w-full text-base font-medium uppercase bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
                        <Link to="/central-monitoring">
                          <div className="py-3 text-white flex items-center   justify-center w-full">
                            Central Monitoring System
                          </div>
                        </Link>
                      </li>
                      <li className="w-full text-base font-medium uppercase bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
                        <Link to="/cloud-surveillance">
                          <div className="py-3 text-white flex items-center   justify-center w-full">
                            Cloud Surveillance
                          </div>
                        </Link>
                      </li>
                      <li className="w-full text-base font-medium uppercase bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
                        <Link to="/integrated-command">
                          <div className="py-3 text-white flex items-center   justify-center w-full">
                            Integrated command Center
                          </div>
                        </Link>
                      </li>
                      <li className="w-full text-base font-medium uppercase bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
                        <Link to="/face-recognition">
                          <div className="py-3 text-white flex items-center   justify-center w-full">
                            Face Recognition
                          </div>
                        </Link>
                      </li>





                    </div>


                  </Disclosure.Panel>

                </>
              )}
            </Disclosure>
          </li>
          {/* for mobile end */}
          <li className="md:hidden block w-full">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="py-2 text-white flex items-center bg-gradient-to-r from-black/50 via-primary to-black/50 justify-center w-full">
                    PARTNER
                    <RiArrowDropDownLine
                      className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8"}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-500 font-semibold">
                    <div className="space-y-3 py-3">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="py-2 text-white flex font-medium items-center bg-gradient-to-r from-black/50 via-primary to-black/50 justify-center px-7 w-full">
                              BECOME A CHANNEL PARTNER
                              <RiArrowDropDownLine
                                className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8"}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-gray-500 font-semibold">
                              <ul className="bg-white text-black py-4 space-y-2 divide-y divide-red-400 md:rounded">
                                <li className="hover:bg-gray-50">
                                  <Link to="/whytochoose">Why to choose i2v</Link>
                                </li>

                              </ul>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <li className="w-full bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
                        <Link to="/technology-partner"> <div className="py-2 text-white uppercase font-medium  flex items-center   justify-center w-full">
                          Technology Partner
                        </div> </Link>
                      </li>

                    </div>


                  </Disclosure.Panel>

                </>
              )}
            </Disclosure>
          </li>
          <li className="group dropdown drop-shadow-2xl md:flex hidden nav-item text-sm uppercase font-semibold nav-desk  text-white hover:text-primary group relative cursor-pointer">
            PARTNER
            <div className="group-hover:block dropdown-menu absolute hidden transition-all duration-300 translate-y-2 h-auto top-10 md:-left-10">
              <div className="top-0 bg-black text-start shadow-xl shadow-white/20 text-white text-xs w-52 flex flex-col">
                {/* <Link to="/channel-partner" className=" hover:bg-primary hover:text-white py-2 m-1">
                  CHANNEL PARTNER
                </Link> */}
                <div className="group dropdownHover hover:bg-primary hover:text-white m-1">
                  <Link to="/channel-partner" className="flex justify-between items-center dropRotate px-3 py-[7px]">
                    BECOME A CHANNEL PARTNER
                    {/* <RiArrowDropDownLine className="iconRotate w-6 h-6" /> */}
                  </Link>

                  {/* <ul className="dropdown-content absolute whitespace-nowrap  hidden text-white text-start bg-black px-4  top-1 ml-48 md:w-72 border-primary border-y-2  divide-y divide-primary divide-opacity-40">

                    <Link to="/whytochoose" className="block py-2 my-1 px-4 hover:bg-primary hover:text-white">
                      Why to choose i2V
                    </Link>


                  </ul> */}
                </div>
                <Link to="/technology-partner" className=" hover:bg-primary hover:text-white py-2 m-1 px-3">
                  TECHNOLOGY PARTNER
                </Link>

              </div>
            </div>
          </li>

          <li className="group dropdown drop-shadow-2xl md:flex hidden nav-item text-sm uppercase font-semibold nav-desk  text-white hover:text-primary group relative cursor-pointer">
            <h2> SUPPORT  </h2>
            <div className="group-hover:block dropdown-menu absolute hidden transition-all duration-300 translate-y-2 h-auto top-10 md:-left-10">
              <div className="top-0 bg-black shadow-xl shadow-white/20 text-white text-xs w-52 flex flex-col">
                <Link to="/camera-brand" className=" hover:bg-primary hover:text-white py-2 m-1">
                  Camera Brand
                </Link>
                <Link to="/faq" className=" hover:bg-primary hover:text-white py-2 m-1">
                  Faq
                </Link>
                <Link to="/contact-us" className=" hover:bg-primary hover:text-white py-2 m-1">
                  Contact Support
                </Link>
                <Link to="/download" className=" hover:bg-primary hover:text-white py-2 m-1">
                  Resources
                </Link>
                <Link to="/PrivacyPolicy" className=" hover:bg-primary hover:text-white py-2 m-1">
                Privacy Policy
                </Link>
                <Link to="/TermsOfUse" className=" hover:bg-primary hover:text-white py-2 m-1">
                Terms of Use
                </Link>
              </div>
            </div>
          </li>
          {/* for mobile start */}
          <li className="md:hidden block w-full">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="py-2 text-white flex items-center bg-gradient-to-r from-black/50 via-primary to-black/50 justify-center px-7 w-full">
                    SUPPORT
                    <RiArrowDropDownLine
                      className={open ? "rotate-180 transform w-8 h-8" : "w-8 h-8"}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-500 font-semibold">
                    <ul className="bg-white text-black py-4 space-y-2 divide-y divide-red-400 md:rounded">
                      <li className="hover:bg-gray-50">
                        <Link to="/camera-brand">Camera Brand</Link>
                      </li>
                      <li>
                        <Link to="/faq">Faq</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Support</Link>
                      </li>
                      <li>
                        <Link to="/download">Resources</Link>
                      </li>
                      <li>
                        <Link to="/PrivacyPolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/TermsOfUse">Terms of Use</Link>
                      </li>
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
          {/* mobile end */}

          <li className="group dropdown drop-shadow-2xl md:flex hidden nav-item text-sm uppercase font-semibold nav-desk  text-white hover:text-primary group relative cursor-pointer">
            <Link to="/career"> CAREER </Link>

          </li>
          {/* mobile start */}
          <li className="w-full bg-gradient-to-r from-black/50 via-primary to-black/50 md:hidden block">
            <Link to="/career"> <div className="py-2 text-white flex items-center   justify-center w-full">
              CAREER

            </div> </Link>
          </li>
          <li className="md:hidden block bg-gradient-to-r from-black/50 via-primary to-black/50 w-full">
            <Link to="/contact-us"> <div className="py-2 text-white flex items-center  justify-center w-full">
              CONTACT US

            </div> </Link>
          </li>
          {/*mobile end */}
        </ul>
        <div className="drop-shadow-2xl md:block hidden group">
          <Link
            to="/contact-us"
            className="hover:animate-pulse px-8 py-2  text-white text-sm uppercase  border-2 border-[#EC2028]  font-semibold  rounded-full  group-hover:bg-primary">

            Contact Us

          </Link>
        </div>
        {/* laguage dropdown start */}
        <div className="relative group">
          <div className="flex items-center cursor-pointer hover:bg-primary text-blue-500 hover:text-white rounded">
            <TbWorldDownload className="w-6 h-6" />
            <MdArrowDropDown className="" />
          </div>
          <ul className="absolute top-6 group-hover:block hidden py-4 text-sm space-y-2 bg-black">
            <li className="text-white hover:bg-primary px-4">
              <button onClick={() => i18next.changeLanguage("en")} >English</button>

            </li>
            <li className="text-white hover:bg-primary px-4">
              <button onClick={() => i18next.changeLanguage("fr")} >French</button>

            </li>
            <li className="text-white hover:bg-primary px-4">
              <button onClick={() => i18next.changeLanguage("gr")} >Greek</button>

            </li>
          </ul>

        </div>
        {/* language dropdown end */}

        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
      </nav>
      {bgcolor ? <hr
        className={color ? "bg-black text-black" : "bg-white  w-[93%] mx-auto"}
      /> : null}

    </div>
  );
};

export default Navbar;
