import React from 'react'
import { Link } from "react-router-dom";
const DetailPage = () => {
    return (
        <div className='lg:px-[4rem] px-5  lg:py-20 py-10 '>

            <div className='flex gap-4 pt-3'>
                <p className='text-primary text-base font-semibold'>
                    JOB ID :
                </p>
                <p className='text-black text-base font-semibold'>i2V/CVE/01</p>
            </div>

            <div className='flex gap-4 pt-3'>
                <p className='text-primary text-base font-semibold'>
                    Title :
                </p>
                <p className='text-black text-base font-semibold lg:pl-0 pl-3'>Computer Vison Engineer</p>
            </div>

            <div className='flex gap-4 pt-4 items-center'>
                <div className='bg-black px-4 py-2 '>
                    <img src='/assets/images/icon1.png' alt='img' />
                </div>
                <p className='text-primary text-sm font-semibold'>Rs. 50,000 - 2,00,000 PA.</p>
            </div>
            <div className='flex gap-4 pt-3 items-center'>
                <div className='bg-black lg:px-5  px-7 lg:py-2 py-2 '>
                    <img src='/assets/images/icon2.png' alt='img' />
                </div>
                <p className='text-primary text-sm text-justify tracking-tighter font-semibold'>Pune, Ahmedabad, Gurgaon/Gurugram, Chennai, Bangalore/Bengaluru, Mumbai (All Areas)</p>
            </div>

            <div className='lg:flex gap-4 bg-black  lg:w-[60%] p-4 mt-8'>
                <div className='flex '>
                    <p className='text-white text-base font-semibold'>Job Posted :</p>
                    <p className='text-white text-base font-semibold'>6 days ago</p>
                </div>
                <div className='flex lg:pt-0 pt-2'>
                    <p className='text-white text-base font-semibold'>| Job Applicants :</p>
                    <p className='text-white text-base font-semibold'> 469</p>
                </div>
                <div className='flex lg:pt-0 pt-2'>
                    <p className='text-white text-base font-semibold'> | Job Views :</p>
                    <p className='text-white text-base font-semibold'> 902</p>
                </div>
            </div>


            <div className='pt-10'>
                <h3 className="text-[26px] text-black font-bold text-start w-full">
                    Description / Summary
                </h3>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                    the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
                    the leap into electronic typesetting, remaining essentially unchanged.                </p>

            </div>

            <div className='pt-5'>
                <h3 className="text-[26px] text-black font-bold text-start w-full">
                    Requirements
                </h3>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                    1- Lorem Ipsum is simply dummy text of the printing and typesetting industry.                 </p>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                    2- Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.                 </p>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                    3- It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.                </p>
            </div>


            <div className='pt-5'>
                <h3 className="text-[26px] text-black font-bold text-start w-full">
                    Methods to Apply
                </h3>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                    1- Lorem Ipsum is simply dummy text                </p>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                    2- Lorem Ipsum is simply dummy text               </p>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                    3- Lorem Ipsum is simply dummy text              </p>
                <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                    4- Lorem Ipsum is simply dummy text              </p>
            </div>


            <div className='lg:flex gap-4 pt-4 '>
                <p className='text-primary text-base font-semibold'>
                    Last Date to Apply :
                </p>
                <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
            </div>

            <div className='lg:flex gap-10 pt-5 '>
            <Link to="/career">  <button className=' bg-black text-white hover:bg-primary px-20 py-2 text-lg rounded-sm mt-5 lg:mt-0 '>
                    Go Back
                </button>
                </Link> 
                <button className='bg-black text-white hover:bg-primary px-20 py-2 text-lg rounded-sm mt-10 lg:mt-0'>
                    Apply Now
                </button>
            </div>

        </div>
    )
}

export default DetailPage