import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const AutomaticNumberPlateRecognition = () => {

    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Detect license plates in the field of view and saves license plate & vehicle image in the database.",
        "Detects Cars, buses, trucks, autos, bikes, or any other vehicle having a license plate on highways, city junctions, and entry-exit gates.",
        "Real-Time detection of license plate in the camera view and recognition of English alphanumeric characters in standard and non-standard formats.",
        "Perform Optical Character Recognition (OCR), and store vehicle JPEG images and details for example date, time stamp, and site location into the database.",
        "Store license plates of vehicles that are stolen, wanted, hot-listed, or suspicious.",
        "Trigger an automatic alert in the control room, when it detects the vehicle from the hot listed category.",
        "Compare against the Watch List database with different character combinations to detect any matches.",
        "Integrates with third-party systems for example VMS, Command and Control software, e-challan, Red light violation, and other traffic management solutions."
    ]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/anpr-banner.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Automatic"
                title1="   Number Plate Recognition /"
                title2="License"
                title3="Plate Recognition"
                pos="bottom"
            />
            <SubPagesIntro
                firstTitle="Automatic"
                secondTitle="Number Plate Recognition /"
                thirdTitle="License"
                forthTitle="Plate Recognition"
                peraOne="i2V’s ANPR / LPR Application Software is based on the Artificial Intelligence model and Deep Learning methodology/ for the Detection and Recognition of License Plates/Number Plates. It detects & identifies Unique License Plates of Vehicles by recognizing the characters on the License Plates through Optical Character Recognition and stores the complete details of license plates along with an Image in the Database."
                imgSrc="/assets/images/products/anprTwo.png"
                data={listItem}
            />
            
            {/* <div className=' lg:px-[4rem] px-5 lg:pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                        ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Detect license plates in the field of view and saves license plate & vehicle image in database.</li>
                            <li>Detects Cars, buses, trucks, auto, bikes or any other vehicle having license plate on highways, city junctions, entry-exit gates.</li>
                            <li>Real Time detection of license plate in the camera view and recognition of English alpha numeric characters in standard and non-standard formats.</li>
                            <li>Perform Optical Character Recognition (OCR), store vehicle JPEG image and details for example: date, time stamp and site location into the database.</li>
                            <li>Store license plates of vehicles which are stolen, wanted, hot-listed or suspicious.</li>
                            <li>Trigger an automatic alert in the control room, when it detects the vehicle from the hot listed category.</li>
                            <li>Compare against Watch List database with different character combinations to detect any matches.</li>
                            <li>Integrates with thrid party systems for example VMS, Command and Control software, e-challan, Red light violation and other traffic management solutions.</li>
                        </motion.ul>
                    </div>
                </div>
            </div> */}

            <Footer />
        </>
    )
}

export default AutomaticNumberPlateRecognition