import { useInView } from "framer-motion";
import React, { useRef } from "react";
import BgVideo from "../../image/bgVideo.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Innovate = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const {t} = useTranslation();
  return (
    <div className="relative md:py-20 bg-center bg-cover" style={{ backgroundImage: `url(${BgVideo})` }}>
      <div className="containeri   md:grid grid-cols-2 pt-20 relative">
        <div
          className="md:flex flex-col  items-start space-y-4 md:px-12"
          ref={ref}
        >
          <h2
            className="text-2xl font-bold  uppercase text-primary text-start drop-shadow-md shadow-blue-600/50"
            style={{
              transform: isInView ? "none" : "translateX(-400px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            {t("Let’s Innovate Together.")}
          </h2>
          <p className="text-start">
            {t("What is better than partners building innovative solutions together? We believe that innovations have no limitations and are open to extending our approach to the vanguard.")}
          </p>
          <div>
          <Link to="/channel-partner"> <button className="flex gap-3  hover:bg-primary duration-200 bg-black rounded-full px-6 py-1 cursor-pointer">
            <p className=" text-sm text-white px-3 py-2">
              {t("Join Our Partner Community")}
            </p>
          </button> </Link>
          </div>
        </div>
        <div className="flex justify-center md:py-0 py-8  ">
          <img
            ref={ref}

            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
            src="/assets/images/innovate3.jpg"
            alt="Join-Our-Partner-Community"
            className="md:absolute -top-10 w-100 max-w-lg rounded md:w-auto w-full shadow-xl "
          />
        </div>
      </div>
    </div>
  );
};

export default Innovate;
