import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const CrowdCountingDetection = () => {

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true })
    const listItem=["Identify and count the no. of people in the video.",
    "Rules based on no. of people increasing (upper limit) or decreasing (lower limit) than a certain threshold value.",
    "Raise alerts based on the time a person or people stay in a particular zone of the video.",
    "Benefits: Effective crowd management in public places.",
]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/CrowdCountingDetection.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Crowd"
                title1="& Counting Detection"
                pos="top"
            />
            <SubPagesIntro
                firstTitle="Crowd"
                secondTitle="& Counting Detection"
                peraOne="The Crowd Detection video analytic immediately detects crowd formations and triggers alarms when a specified number of people (capacity) or a specified percentage of people (occupancy) is reached in the selected area."
                imgSrc="/assets/images/products/crowd.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]} 
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]} 
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}  
                data={listItem}
            />

            {/* <div className=' lg:px-[4rem] px-5 pb-12'>
                <div className='bg-black lg:px-8 px-2 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Improve public safety in defined regions of interest.</li>
                            <li>Identify people in indoor and outdoor environment
                                and monitoring with high quality video for future
                                forensic analysis.</li>
                            <li>Identify the no. of people in the video.</li>
                            <li>Identify the movement speed of person in video</li>
                            <li>Highlight camera feeds during live monitoring setti.</li>
                            <li>Eliminate need of human observation of video on a
                                24x7.</li>
                            <li>Rules based on no. of people exceeding certain
                                threshold value.</li>
                            <li>Email, sound, sms on Alert to authorized person.</li>

                        </motion.ul>
                    </div>
                </div>
            </div> */}
            {/* <SubPages /> */}
            <Footer />
        </>
    )
}

export default CrowdCountingDetection