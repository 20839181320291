import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import Face from '../components/SeventhProduct/Face'

const SeventhProduct = () => {
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/face-banner.png"
        
                title="Face"
                title1= "Recognition"
            />
            <Face/>
            <ContactFooter />
            <Footer />
        </>
    )
}

export default SeventhProduct