import React, { useRef } from 'react'
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

const SubPagesIntro = ({ firstTitle, secondTitle, peraOne, peraTwo, imgSrc,  data,thirdTitle,forthTitle }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <>
            <div className="lg:px-[4rem] px-5 md:pt-8 pt-4">
                <motion.div className="grid md:grid-cols-12 md:gap-8 container-default pb-12 items-start"
                    ref={ref}
                    initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                    animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                    transition={{ duration: isInView ? 1 : 0 }}
                >
                    <div className="col-span-6 flex flex-col space-y-3 z-10">
                        <h3 className="text-[26px] text-black font-bold text-start w-full">
                            <span className="text-primary"> {firstTitle}  </span>  {secondTitle}
                            {
                            thirdTitle?
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                            <span className="text-primary"> {thirdTitle}  </span>  {forthTitle}
                        </h3>:null
                        }
                        </h3>
                        

                        <div
                            className="h-1 w-full bg-primary"

                        ></div>
                        <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                            {peraOne}
                        </p>
                        {
                            peraTwo ? <p className="text-black text-base leading-relaxed text-justify w-[95%] pb-6">
                                {peraTwo}
                            </p> : null
                        }

                        {/* image mobile start */}
                        <div
                            className="md:hidden block">
                            <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
                                <img src={imgSrc} alt="i2v" />
                            </div>
                        </div>
                        {/* image mobile end */}

                        <div className='bg-black lg:px-8 px-2 rounded'>
                            <div className='py-8 '>
                                <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                                <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                                <ul className="list-disc text-white text-justify p-6 space-y-2"

                                >
                                    {
                                        data.map((e, i) => (
                                            <li key={i}>{e}</li>
                                        ))
                                    }

                                </ul>
                            </div>
                        </div>

                        {/* heighlight start */}
                        {/* {
                            one ? <div className='bg-black lg:px-8 px-2 rounded'>
                                <div className='py-8 '>
                                    <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                                    <ul className="list-disc text-white text-start p-6 space-y-2"

                                    >
                                        {
                                            one ? <li>{one}</li> : null
                                        }
                                        {
                                            two ? <li>{two}</li> : null
                                        }
                                        {
                                            three ? <li>{three}</li> : null
                                        }
                                        {
                                            four ? <li>{four}</li> : null
                                        }
                                        {
                                            five ? <li>{five}</li> : null
                                        }
                                        {
                                            six ? <li>{six}</li> : null
                                        }
                                        {
                                            seven ? <li>{seven}</li> : null
                                        }
                                        {
                                            eight ? <li>{eight}</li> : null
                                        }
                                        {
                                            nine ? <li>{nine}</li> : null
                                        }
                                        {
                                            ten ? <li>{ten}</li> : null
                                        }
                                        {
                                            elevan ? <li>{elevan}</li> : null
                                        }

                                        {
                                            lastItem ? <li>{lastItem}</li> : null
                                        }

                                        
                                        {
                                            underOne ? <ul className="list-disc px-4">
                                                {
                                                    underOne ? <li>{underOne}</li> : null
                                                }
                                                {
                                                    underTwo ? <li>{underTwo}</li> : null
                                                }
                                                {
                                                    underThree ? <li>{underThree}</li> : null
                                                }
                                                {
                                                    underFour ? <li>{underFour}</li> : null
                                                }
                                                {
                                                    underFive ? <li>{underFive}</li> : null
                                                }
                                            </ul> : null
                                        }
                                        {
                                            twelve ? <li>{twelve}</li> : null
                                        }


                                    </ul>
                                </div>
                            </div> : null
                        } */}
                        {/* heightlight end */}

                    </div>

                    <div
                        className="col-span-6 w-full md:flex flex-col hidden md:justify-end z-10 md:pt-0 pt-4">
                        <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
                            <img src={imgSrc} alt="i2v" />
                        </div>

                    </div>
                </motion.div>


                {/* <ul>
                    {
                        data.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))
                    }
                </ul> */}


            </div>

        </>
    )
}

export default SubPagesIntro