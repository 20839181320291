import React from "react";
import Banner from "../components/common/Banner";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";

const CameraBrand = () => {
  const data = [
    {
      id: 24,
      img: "/assets/images/ONVIF.png",
    },
    {
      id: 1,
      img: "/assets/images/logo1 (1).png",
    },
    {
      id: 2,
      img: "/assets/images/logo1 (2).png",
    },


    {
      id: 3,
      img: "/assets/images/logo1 (8).png",
    },
    {
      id: 4,
      img: "/assets/images/logo1 (6).png",
    },


    {
      id: 5,
      img: "/assets/images/logo1 (7).png",
    },
    {
      id: 6,
      img: "/assets/images/logo1 (35).png",
      // img: "/assets/images/logo1 (8).png",
    },
    {
      id: 7,
      img: "/assets/images/logo1 (9).png",
    },

    {
      id: 8,
      img: "/assets/images/logo1 (13).png",
    },
    {
      id: 9,
      img: "/assets/images/logo1 (21).png",
    },
    {
      id: 10,
      img: "/assets/images/logo1 (15).png",
    },
    {
      id: 11,
      img: "/assets/images/logo1 (37).png",
    },

    {
      id: 12,
      img: "/assets/images/logo1 (14).png",
     
    },

    {
      id: 13,
      img: "/assets/images/logo1 (24).png",
    },

    {
      id: 14,
      img: "/assets/images/logo1 (26).png",
    },
    {
      id: 15,
      img: "/assets/images/logo1 (27).png",
    },
    {
      id: 16,
      img: "/assets/images/logo1 (28).png",
    },


    {
      id: 17,
      img: "/assets/images/logo1 (33).png",
    },

    {
      id: 18,
      // img: "/assets/images/logo1 (35).png",
      img:"/assets/images/logo1 (4).png",
    },

    {
      id: 19,
      img: "/assets/images/logo1 (16).png",
      
    },
    {
      id: 20,
      img: "/assets/images/logo1 (41).png",
      
    },
    {
      id: 21,
      img: "/assets/images/logo1 (39).png",
    },
    {
      id: 22,
      img: "/assets/images/logo1 (40).png",
    },
    {
      id: 23,
      img: "/assets/images/logo1 (38).png",
    },

  ];
  return (
    <>
      <Navbar />

      <Banner
        img="/assets/images/support.png"
        pageTitle="“We are building advanced solutions to streamline the video-based monitoring with accuracy and less dependence on human observance.”"
        title="i2V"
        title1=" CAMERA BRANDS" />

      <div className=" lg:px-16 px-5 lg:mt-16 mt-7">
        <div className="grid lg:grid-cols-3 gap-4">
          <div className="bg-black text-white p-8 flex items-center flex-col space-y-4 rounded-md group hover:scale-95 duration-500">
            <img
              className="w-20 group-hover:scale-105 duration-500"
              src="/assets/images/camera.png"
            />
            <p>
            i2V Supports almost any IP camera/ encoder including ONVIF. One can add a camera to i2V software by specifying its streaming URL.
            </p>
          </div>
          <div className="bg-black text-white p-8 flex items-center flex-col space-y-4 rounded-md group hover:scale-95 duration-500">
            <img
              className="w-20 group-hover:scale-105 duration-500 "
              src="/assets/images/dvrs.png"
            />
            <p>
            i2V also supports DVRs with an open streaming interface for example RTSP.
            </p>
          </div>
          <div className="bg-black text-white p-8 flex items-center flex-col space-y-4 rounded-md group hover:scale-95 duration-500">
            <img
              className="w-20 group-hover:scale-105 duration-500"
              src="/assets/images/mail.png"
              alt="i2v-person"
            />

            <p>
            Below is only the partial list of Camera brands. Please check your camera brand by contacting us at {" "}
              <a href="mailto:i2v@i2vsys.com" className="text-primary">i2v@i2vsys.com</a>
            </p>
          </div>
        </div>
      </div>

      <div className="lg:px-16 px-5 lg:pt-20 pt-12 ">
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-semibold">
            <span className="text-primary ">i2V</span> CAMERA BRANDS
          </h2>
          <p className="max-w-5xl mx-auto lg:text-center text-justify tracking-tighter">
          Our software solutions are compatible with many cameras makes and models and if you don’t find your’s then contact us and<br/> <span className="uppercase">We will get it done.</span>
          </p>
        </div>
        <div className='grid lg:grid-cols-6 grid-cols-2  md:gap-7  md:pb-20 pb-8 md:pt-10'>
          {data.map((item, e) => (
            <div key={e} className="relative pt-4">
              <div className=''>
                <img src={item.img} className=" mx-auto hover:scale-110 duration-500" alt='i2v' />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ContactFooter />
      <Footer />
    </>
  );
};

export default CameraBrand;
