import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const NoHelmetDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Compatible with all ONVIF Standard IP Cameras.",
        "Automates the Violation Process & Regulates the Violation.",
        "Eliminates Manual Processes of Catching Commuters with No Helmets.",
        "Improves Safety & Security of Commuters.",
        "Automatic E-Challan/Ticket Generation for Violators (Speed Violation & Red-Light Violation Detection, Triple Riding Detection, No Helmet Detection).",
        "Help gather Evidence & Proof, that can be used in Contempt of Court."
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/NoHelmetDetectionBanner.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title=" No Helmet"
                title1=" Detection"
                pos="center"
            />
            <SubPagesIntro
                firstTitle="No Helmet"
                secondTitle="Detection"
                peraOne="Video-based No Helmet Detection is mainly targeted for the Intelligent Traffic Management Solutions (ITES/ITMS) for Safe City, Smart City and City Surveillance along with ANPR for Full Proof ITES/ITMS Solutions for Curbing No Helmet Violations."
                peraTwo="It works by the Evidence Capturing Mechanism of Commuters traveling on a Two-Wheeler without a Helmet in tandem with the ANPR System and based on the Artificial Intelligence Model and Utilizes Deep Learning Methodology. It detects for Rider’s Head with or without a Helmet on a Two-Wheeler Vehicle."
                imgSrc="/assets/images/products/nohelmet12.jpg"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}
            />
            {/* <div className=' lg:px-[4rem] px-3 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Compatible with all ONVIF Standard IP Cameras.
                            </li>
                            <li>Automates the Violation Process & Regulates the Violation. </li>

                            <li>Eliminates Manual Processes of Catching Commuters with No Helmets. </li>
                            <li>Improves Safety & Security of Commuters.
                            </li>
                            <li>Increases Productivity & Efficiency of Traffic Professionals.</li>
                            <li>Help gather Evidences & Proof, that can be used in Contempt of Court.</li>

                        </motion.ul>
                    </div>

                </div>

            </div> */}
            <Footer />
        </>
    )
}

export default NoHelmetDetection