import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const Face = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <>

            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-8 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-start"
                        ref={ref}

                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black text-start font-bold w-full">
                                <span className="text-primary">FACE</span> RECOGNITION
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <div className="space-y-4 py-4">
                                <p className="text-black text-base leading-relaxed text-justify w-[95%]">

                                i2v Facial recognition is a way of identifying or confirming an individual’s identity using their face. Facial recognition systems can be used to identify people in photos, videos, or in real time. The face capture process transforms analog information (a face) into a set of digital information (data) based on the person's facial features. Your face's analysis is essentially turned into a mathematical formula. The numerical code is called a faceprint. In the same way that thumbprints are unique, each person has their faceprint.</p>

                            </div>

                            <div className='bg-black lg:px-8 px-5 rounded'>
                                <div className='py-8 '>
                                    <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                                    <ul className="list-disc text-white text-justify md:p-6 py-6 pl-6 space-y-2"

                                    >
                                        <li>Detect faces and give alerts/outputs within 3 meters or max depending on the camera resolution and Lens settings.

                                        </li>
                                        <li>Recognition of faces on live cameras as well as on recorded videos.
                                        </li>
                                        <li>Counting the faces that occurred in the multiple cameras.
                                        </li>
                                        <li>Detect any matches with faces on the “Watch List” database and provide alerts to the control room.
                                        </li>
                                        <li>
                                        Detect multiple faces from group images & Search for similar faces from a single camera or across multiple cameras.
                                        </li>
                                        <li>An enrolled person can be searched by names or similar faces and also search via. unknown faces using a single image or group photo.</li>
                                        <li>Recognition is less than 1 second in video mode.</li>
                                        <li>Supported codecs: H.264, H.265, MJPEG, MPEG1, MPEG2, and MPEG4.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10"

                        >
                            <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">

                                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                                    OUR MORE PRODUCTS
                                </h1>
                                <div className="h-[0.10rem] w-full bg-white "></div>

                                <Link to="/ip-Video"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + IP Video Management Software
                                </div> </Link>
                                <Link to="/ai-Based-Video"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + AI Based Video Analytics
                                </div></Link>
                                <Link to="/intelligent-traffic">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Intelligent Traffic Solutions
                                </div> </Link>
                                <Link to="/central-monetoring">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Central Monitoring System
                                </div> </Link>
                                <Link to="/cloud-surveillance">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Cloud Surveillance
                                </div> </Link>
                                <Link to="/integrated-command">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Integrated Command Center
                                </div></Link>
                                <Link to="/face-recognition">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Face Recognition
                                </div> </Link>


                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>



        </>

    );
};

export default Face;
