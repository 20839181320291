import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const CloudSurveillance = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (

        <>

            <div className="">
                <div className="lg:px-[4rem] px-5  pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-start"
                        ref={ref}

                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-2 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary">Cloud</span> Surveillance
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                            i2V’s cloud system provides its customers with the most convenient and easy way to do the security of their premises with IP cameras. Due to the internet cloud, there is no need for any machines other than IP cameras to run, view and record the videos.
                            </p>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%]">Now, you do not need NVRs to run your cameras, you do not need any tangible storage device like SAN or NAS. The cloud takes care of all these things. You can view the live view of cameras or any recorded video of the past on your PC or mobile from anywhere. The only thing you need is to have an internet connection and buy our cloud service.</p>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%]">The solution is very useful, especially at civilian premises like Residential, schools, buildings, hospitals, shops, etc.</p>
                            <div className='bg-black lg:px-8 px-3 rounded'>
                                <div className='pt-5 '>
                                    <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                                    <ul className="list-disc text-white text-justify md:p-6 py-6 pl-6 space-y-2">
                                        <li>Integration with Most IP cameras, encoders, or DVRs. i2v has the most open software in the world that can integrate maximum camera models and makes.

                                        </li>
                                        <li>Open Standard Protocols and Video Formats (RTSP, HTTP, H.265, H264, and Mpeg4).
                                        </li>
                                        <li>Open Mobile-based Viewing (Android/iOS).
                                        </li>
                                        <li>Video Analytics (like Missing objects, Abandoned Baggage, Intrusion, Fire, Perimeter Breach) & SMS/Email alerts.
                                        </li>
                                        <li>
                                        User & Camera-based Authentication/Authorization.
                                        </li>
                                        <li>Performance, Failover, Stability & Scalability in terms of cameras per server (i2v software can save hardware costs by up to 25%).</li>
                                        <li>Scalability with respect to the number of users & Database Management.</li>
                                        <li>Edge-based Recording and its usage (for a few camera models).</li>
                                        <li>Video Security Encryption.</li>
                                        <li>Two-way audio between camera and Web/Mobile (for a few camera models).</li>
                                        <li>User-Friendly Cloud/Web-based interface.</li>
                                        <li>Event Management and broadcasting streaming.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10"

                        >
                            <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">

                                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                                    OUR MORE PRODUCTS
                                </h1>
                                <div className="h-[0.10rem] w-full bg-white "></div>

                                <Link to="/ip-Video"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + IP Video Management Software
                                </div></Link>
                                <Link to="/ai-Based-Video"> <div className=" font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + AI Based Video Analytics
                                </div></Link>
                                <Link to="/intelligent-traffic"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Intelligent Traffic Solutions
                                </div></Link>
                                <Link to="/central-monetoring"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Central Monitoring System
                                </div></Link>
                                <Link to="/cloud-surveillance"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs    ">
                                    + Cloud Surveillance
                                </div></Link>
                                <Link to="/integrated-command"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Integrated Command Center
                                </div></Link>
                                <Link to="/face-recognition">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Face Recognition
                                </div></Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>


            <div className="grid md:grid-cols-1 gap-8 lg:px-[4rem] pb-8">
                <div className="relative">
                    <div className="shadow-lg rounded-md shadow-[#00000086]">
                        <div className=" rounded-md  shadowbox ">
                            <div className="bg-black px-6 py-3 rounded-t-md">
                                <h2 className="text-lg text-left text-white font-semibold">
                                    Advantages of i2V cloud system :
                                </h2>
                            </div>
                            <div className="bg-white px-10 py-7 rounded-b-md text-left">
                                <ul className="list-disc space-y-2">
                                    <li>With i2V’s cloud system, you can view your premises from any remote location by accessing the IP address of the cameras located at your premises.</li>
                                    <li>You can see the live as well as recorded video, through i2V’s cloud system.</li>
                                    <li>i2V’s cloud system can save the recorded video for up to 3 months or longer based on customer requirements.</li>
                                    <li>You can view the cameras on any device like PC, laptop, tablet, and mobile.</li>
                                    <li>You can view cameras on i2V client along with all the popular browsers like Google Chrome, and Mozilla Firefox is best suited to view your cameras on a PC, or laptop. i2V provides a mobile app for Android and ios users.</li>
                                    <li>i2V provides enough client connections so that more than one person can view the cameras at the same time.</li>
                                    <li>i2V cloud systems also send alarms, pop-ups, SMS, and email on any breach or unusual activity that happens at the customer premises.</li>
                                    <li>i2V video analytics make the process easy for their customers so that they do not need to keep track of cameras deliberately.</li>
                                    <li>i2V cloud provides one-touch activation as well as auto-activating video analytics.</li>
                                    <li>The video analytics provided by i2V are Intrusion detection, Motion detection, Perimeter violation, Missing object detection, and Abandoned object detection.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <div className=' lg:px-[4rem] px-5 py-12'>
                <div className='bg-black lg:px-8 px-3 rounded'>
                    <div className='py-5 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Integration with Most IP cameras, encoders or DVRs. i2v has most open softwares in the world that can integrate maximum camera models and makes.

                            </li>
                            <li>Open Standard Protocols and Video Formats (RTSP, http, html5, H.265, H264, Mpeg4).
                            </li>
                            <li>Open Mobile based Viewing (Android/iOS).
                            </li>
                            <li>Video Analytics (like Missing object, Abandoned Baggage, Intrusion, Fire, Perimeter Breach) & SMS/Email alerts.
                            </li>
                            <li>
                                User & Camera based Authentication/Authorization.
                            </li>
                            <li>Performance, Failover, Stability & Scalability in terms of cameras per server (i2v software can save hardware costs by up to 25%).</li>
                            <li>Scalability with respect to number of users & Database Management.</li>
                            <li>Edge based Recording and its usage (for few camera models).</li>
                            <li>Video Security Encryption.</li>
                            <li>Two-way audio between camera and Web/Mobile (for few camera models).</li>
                            <li>User Friendly Cloud/Web based interface.</li>
                            <li>Event Management and broadcasting streaming.</li>

                        </motion.ul>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default CloudSurveillance