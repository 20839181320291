import React, {  useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

import Banner from "../../components/common/Banner";
import { BsArrowRightCircleFill } from "react-icons/bs";
import ViolationSection from "./ViolationSection";

const Violation = () => {
    
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/violation.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."

                title1=" Violations"
                pos="center"
            />
            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-8 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:grid-cols-2   justify-between container-default pb-12 items-start"
                        ref={ref}
                        
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary"> Violations </span>
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pt-6">
                            Traffic violations can easily be defined as an act that violates the traffic laws of the respective state and country. With the increase in the number of traffic rule breaks, accidents also increase. Traffic violations occur when drivers break laws that regulate vehicle operations on roads and highways.
                            </p>
                            

                            <div className="space-y-2 capitalize">
                                <a href="#redLight" className="grid grid-cols-12  pl-2 md:pl-6   bg-gradient-to-l items-center from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24} /> <span className="py-2 col-span-10 md:text-start">Red Light Violation Detection (RLVD)</span></a>
                                <a href="#noHelmet" className="grid grid-cols-12  items-center pl-2 md:pl-6   bg-gradient-to-l from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24} /> 	<span className="py-2 col-span-10 md:text-start">No Helmet Violation Detection</span></a>
                                <a href="#speed" className="grid grid-cols-12 items-center pl-2 md:pl-6  bg-gradient-to-l from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24} /> <span className="py-2 col-span-10 md:text-start">Speed Violation Detection</span></a>
                                <a href="#stopped" className="grid grid-cols-12  items-center pl-2 md:pl-6  bg-gradient-to-l from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24} /> <span className="py-2 col-span-10 md:text-start">Stopped Vehicle /illegal Parking Violation Detection</span></a>
                                <a href="#Triple" id="redLight" className="grid grid-cols-12  items-center pl-2 md:pl-6  bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24} /> <span className="py-2 col-span-10 md:text-start">Triple Riding Violation Detection</span></a>
                                <a href="#wrongviolation" className="grid grid-cols-12  items-center pl-2 md:pl-6 bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24}/> <span className="py-2 col-span-10 md:text-start">wrong way detection</span></a>
                                
                            </div>

                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10">
                            <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
                                <img src="/assets/images/products/violation.png" alt="i2v" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            
            <ViolationSection />
            <Footer />
        </>
    )
}

export default Violation