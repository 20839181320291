import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const AutomaticTrafficCountingClassification = () => {

    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Well suited to Mobile ATCC units.",
        "Advanced state of art Video & Image processing technology.",
        "ATCC is a complete IP Video based solution that provides high accuracy in real-time vehicle counting and classification.",
        "Snapshot capture of each vehicle that is counted and classified.",
        "Integrated with Advanced Video Management software facilitating the maintenance of complete reporting and statistics.",
        "24x7 monitoring of traffic flow on national highways."
    ]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/ATCC.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Automatic"
                title1=" Traffic Counting and Classification"
                pos="center"
            />
            <SubPagesIntro
                firstTitle="Automatic"
                secondTitle="Traffic Counting and Classification (ATCC)"
                peraOne="i2V ATCC is based on analytics on video from the camera. It will do vehicle counting & classification through images in the video on a real-time basis. It's an IP-based video solution that provides high accuracy in real-time vehicle counting and classification. Counts and Classifies vehicles through live video feed taken from the IP/CCTV camera which is located at the desired location on the highway or junction. The data of count and classification can be sent to a central location on a real-time basis. The Solution can detect and classify the vehicles into different classes such as BIKE, CAR, LCV, HCV, and OSV."
                imgSrc="/assets/images/products/automaticTraffic.png"
                data={listItem}
            />


            {/* <div className=' lg:px-[4rem] px-5 md:pb-16'>
                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Well suited to Mobile ATCC units.</li>
                            <li>Advanced state of art Video & Image processing technology.</li>
                            <li>ATCC is complete IP Video based solution that provides high accuracy in real time vehicle counting and classification.</li>
                            <li>Snapshot capture of each vehicle that is counted and  classified.</li>
                            <li>Integrated with an Advanced Video Management software facilitating maintenance of complete reporting and statistics.</li>
                            <li>24x7 monitoring of traffic flow on national highways.</li>

                        </motion.ul>
                    </div>
                </div>
            </div> */}

            <Footer />
        </>
    )
}

export default AutomaticTrafficCountingClassification