import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const QueueAnalysisLengthDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = ["Queue length detection using head and/or person count.",
        "Average Wait Time Calculation per customer or Passenger.",
        "Give alerts to authorities if crowd/queue length increases by a certain value.",
        "Helps in Performance and crowd management.",
        "Effective POS (Point of sale) Management.",
        
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/QueueAnalysislengthDetectionBanner.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Queue"
                title1="  Analysis and Length
                Detection"
                pos="top"
            />
            <SubPagesIntro
                firstTitle="Queue"
                secondTitle="Analysis and Length
            Detection"
                peraOne="This video analytics is made to effectively manage the crowd. It is used to detect Queue length in terms of the number of people and heads in a Queue or a Crowd. It does real-time detection of the queue through video surveillance cameras and is highly useful at public transport places like airports & metros, banking, and retail stores are other applications where customer crowds can be effectively managed. It also calculates the average wait time for each customer or passenger."
               
                imgSrc="/assets/images/products/queue.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                data={listItem}
            />

            {/* <div className=' lg:px-[4rem] px-5 pb-12'>
                <div className='bg-black lg:px-8 px-2 rounded'>
                    <div className='py-6 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Queue length detection using head and/or person
                                count.</li>
                            <li>Average Wait Time Calculation per customer or per
                                Passenger.</li>
                            <li>Crowd counting.</li>
                            <li>Works on any video surveillance cameras IP and
                                analog.</li>
                            <li> Works in real time. </li>
                            <li> Give alerts to authorities if crowd value increase
                                certain value.</li>
                            <li>Helps in Performance Management of working staff.</li>
                            <li> Helps in effective Crowd Management.</li>
                            <li>Effective POS (Point of sale) Management.</li>
                        </motion.ul>
                    </div>

                </div>

            </div> */}

            <Footer />
        </>
    )
}

export default QueueAnalysisLengthDetection