import React from "react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Heading from "../common/Heading";

const VideoService = () => {
  const myref = useRef(null);
  const executeScroll = () => myref.current.scrollIntoView();
  const { t } = useTranslation();

  const [videoUrl, setVideoUrl] = useState(
    "https://www.youtube.com/embed/juNdJsGzx2I"
  );
  const [videoText, SetVideoText] = useState("I2v Introduction");
  const [videoPara, setVideoPara] = useState(
    "i2V stands for Intelligent Integrated Video. i2V is a technology R&D company focusing on design, development and production of Intelligent Surveillance solutions. i2V specializes in Proactive Video Surveillance products with an aim of providing high quality software to clients worldwide."
  );

  return (
    <div className="text-white py-16 space-y-12 flex justify-center items-center bg-black">
      <div ref={myref} className="md:max-w-[71rem] 2xl:max-w-6xl md:w-full space-y-10">
        <Heading
          title="DEMO - VIDEO ANALYTICS"
          para="With A User-Friendly Interface, We Make Analytics Simply More Effective."
        />
        <div className="flex md:flex-row flex-col-reverse gap-12">
          <div className="flex-1 space-y-8 md:px-0 px-6">
            {/* 1st video */}
            <div className="grid grid-cols-5 gap-4">
              <div
                className="col-span-2 shadow-xl rounded-[10px] flex cursor-pointer"
                style={{ boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.35)" }}
                onClick={(e) => {
                  executeScroll();
                  setVideoUrl("https://www.youtube.com/embed/t6MHXij_0oI");
                  SetVideoText("Speed Violation Detection");
                  setVideoPara(
                    "i2V Speed Violation Detection is the state of the art video based speed violation detection system which determines the vehicular speed by calculating the time between the multiple frames of the vehicle and captures image of the number plate of the violating vehicle."
                  );
                }}
              >
                <img
                  src="/assets/images/speed.jpg"
                  alt="speed"
                  className="hover:scale-105 duration-500 object-cover hover:bg-black rounded-xl w-full h-24"
                />
                <img
                  className="text-white absolute w-12 h-8 m-2 drop-shadow-2xl bg-white rounded-xl"
                  alt="youtube"
                  src="/assets/images/youtubeTwo.png "
                />
              </div>
              <div className="col-span-3 space-y-1 flex justify-center items-start flex-col">
                <h2 className="font-bold md:text-[16px] text-start">
                  {t("Speed Violation Detection")}
                </h2>

              </div>
            </div>
            {/* 2nd video */}
            <div className="grid grid-cols-5 gap-4">
              <div
                className="col-span-2 shadow-xl rounded-[10px] flex cursor-pointer"
                style={{ boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.35)" }}
                onClick={(e) => {
                  executeScroll();
                  setVideoUrl("https://www.youtube.com/embed/T2ifEOG5fAg");
                  SetVideoText("Automatic Traffic Count & Classification");
                  setVideoPara(
                    "i2V ATCC is based on analytics on video from the camera. The Solution is for highways & toll plaza's where it will detect and classify the vehicles into different classes such as BIKE, CAR, LCV, HCV and OSV. The data of count and classification can be sent to central location on real time basis."
                  );
                }}
              >
                <img
                  src="/assets/images/count.jpg"
                  alt="count"
                  className="hover:scale-105 duration-500 object-cover hover:bg-black rounded-xl w-full h-24"
                />
                <img
                  className="text-white absolute w-12 h-8 m-2 drop-shadow-2xl bg-white rounded-xl"
                  src="/assets/images/youtubeTwo.png"
                  alt="i2v"
                />
              </div>
              <div className="col-span-3 space-y-1 flex flex-col justify-center items-start">
                <h2 className="font-bold md:text-[16px] text-start">
                  {t("Automatic Traffic Count & Classification")}
                </h2>

              </div>
            </div>
            {/* 3rd video */}
            <div className="grid grid-cols-5 gap-4">
              <div
                className="col-span-2 shadow-xl rounded-[10px] flex cursor-pointer"
                style={{ boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.35)" }}
                onClick={(e) => {
                  executeScroll();
                  setVideoUrl("https://www.youtube.com/embed/FieJ2JvXK_I");
                  SetVideoText("Automatic Number Plate Recognition");
                  setVideoPara(
                    "i2V’s ANPR detects & identify Unique License Plate of Vehicles by recognizing the characters on the License Plates through Optical Character Recognition and stores the complete details of license plates along with an Image in the Database."
                  );
                }}
              >
                <img
                  src="https://img.freepik.com/free-photo/cars-city-traffic-daylight_23-2149092084.jpg?w=996&t=st=1662970244~exp=1662970844~hmac=9c3530dcf00ba656a852c944fa9e56e823d108b76a81d94ab597d87ceb477952"
                  alt="/"
                  className="hover:scale-105 duration-500 hover:bg-black object-cover rounded-xl w-full h-24"
                />
                <img
                  className="text-white absolute w-12 h-8 m-2 drop-shadow-2xl bg-white rounded-xl"
                  src="/assets/images/youtubeTwo.png"
                  alt="i2v"
                />
              </div>
              <div className="col-span-3 space-y-1 flex justify-center items-start flex-col">
                <h2 className="font-bold md:text-[16px] text-start">
                  {t("Automatic Number Plate Recognition")}
                </h2>

              </div>
            </div>
            {/* 4rd video */}
            <div className="grid grid-cols-5 gap-4">
              <div
                className="col-span-2 shadow-xl rounded-[10px] flex  cursor-pointer"
                style={{ boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.35)" }}
                onClick={(e) => {
                  executeScroll();
                  setVideoUrl("https://www.youtube.com/embed/VimYV-zgYo0");
                  SetVideoText("Fire & Smoke Detection");
                  setVideoPara(
                    "i2V has introduced Video Based Fire & Smoke Detection technology to further strengthen the IP Video Surveillance solutions. This analytic has been tested on a wide variety of scenarios ranging from normal indoor mild fire to forest fires. It alerts the user about the intensity and duration of the alert."
                  );
                }}
              >
                <img
                  src="/assets/images/fire.jpg"
                  alt="i2v"
                  className="hover:scale-105 duration-500 object-cover hover:bg-black rounded-xl w-full h-24"
                />
                <img
                  className="text-white absolute w-12 h-8 m-2 drop-shadow-2xl bg-white rounded-xl"
                  src="/assets/images/youtubeTwo.png"
                  alt="i2v"
                />
              </div>
              <div className="col-span-3 space-y-1 flex justify-center items-start flex-col">
                <h2 className="font-bold md:text-[16px]">
                  {t("Fire & Smoke Detection")}
                </h2>

              </div>
            </div>
          </div>
          <div className="flex-1 space-y-4 md:px-0 px-6">
            <iframe
              height={280}
              src={videoUrl}
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen=""
              className="rounded-[10px] shadow-xl w-full"
              style={{ boxShadow: "4px 4px 15px rgba(0, 0, 0, 0.35)" }}
            />
            <div className="fex flex-col justify-start items-start">
              <h2 className="text-xl font-bold text-start pb-2 md:text-[20px] underline underline-offset-8 decoration-primary">
                {t(videoText)}
              </h2>
              <p className="font-normal text-base text-justify md:text-[16px]">
                {t(videoPara)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoService;
