import React, { useRef, useState } from 'react'
import { Link } from "react-router-dom";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
const IntelligentTraffic = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <div>

            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-8 pt-7">
                    <motion.div
                        ref={ref}
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                        className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-start">
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary"> Intelligent </span> Traffic Solutions
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] py-6">
                            With increased urbanization and increased vehicular traffic, cities are battling with an inability to build enough infrastructure. While the government has been emphasizing building roads, bridges & underpasses and creating alternative modes of mass public transport systems, this may not be enough to address the traffic congestion. Traffic management has always posed a challenge in every country and it may continue to become more difficult with every passing day unless we start building some intelligence into the way we look at resolving these issues. The solution lies in leveraging advanced technologies and intelligent solutions.
                            </p>
                            <div className="text-start">
                                <h2 className="text-lg font-semibold bg-black text-white px-1 tracking-wide mr-4">Intelligent Traffic Solutions (ITS) Portfolio includes:</h2>
                                <ul className="list-disc px-4 py-2 space-y-1">
                                    <li className='font-semibold w-full hover:underline underline-offset-4'>
                                        <Link to="/automatic-number-plate-recognition" className='p-2 bg-gradient-to-l block from-primary to-white'>Automatic Number Plate Recognition (ANPR) / LPR</Link>
                                    </li>
                                    <li className='font-semibold w-full hover:underline underline-offset-4'>
                                        <Link to="/automatic-traffic-counting-classification" className='p-2 bg-gradient-to-l block from-primary to-white'>Automatic traffic counting and classification  (ATCC)</Link>
                                    </li>

                                    <li>
                                        <li className='font-semibold w-full hover:underline underline-offset-4'>
                                            <Link to="/video-incident-detection-system" className='p-2 bg-gradient-to-l block from-primary to-white'>Video Incident Detection System (VIDS)</Link>
                                        </li>

                                        <ul className='list-disc px-8' style={{ listStyle: 'circle' }}>
                                            <li className='hover:underline underline-offset-2'>
                                                <Link to="/video-incident-detection-system">Stopped Vehicle Detection</Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/video-incident-detection-system">Wrong Way Detection</Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/video-incident-detection-system">Pedestrian Movement Detection</Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/video-incident-detection-system">Abandoned Object lying on road</Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/video-incident-detection-system">Average Traffic flow based on Congestion </Link>
                                            </li>

                                        </ul>
                                    </li>
                                    <li className=''>
                                        <li className='font-semibold w-full hover:underline underline-offset-4'>
                                            <Link to="/violation" className='p-2 bg-gradient-to-l block from-primary to-white'>Violation</Link>
                                        </li>

                                        <ul className='list-disc px-8 ' style={{ listStyle: 'circle' }}>
                                            <li className='hover:underline underline-offset-2'><Link to="/violation">Red Light Violation Detection  (RLVD) </Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/violation">No Helmet Violation Detection </Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/violation">Speed Violation Detection</Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/violation">Stopped Vehicle /illegal Parking Violation Detection </Link>
                                            </li>
                                            <li className='hover:underline underline-offset-2'><Link to="/violation">Triple Riding Violation Detection </Link>
                                            </li>


                                        </ul>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10"

                        >
                            <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">

                                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                                    OUR MORE PRODUCTS
                                </h1>
                                <div className="h-[0.10rem] w-full bg-white "></div>

                                <Link to="/ip-Video">     <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + IP Video Management Software
                                </div></Link>
                                <Link to="/ai-Based-Video"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs     ">
                                    + AI Based Video Analytics
                                </div></Link>
                                <Link to="/intelligent-traffic">  <div className=" font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Intelligent Traffic Solutions
                                </div></Link>
                                <Link to="/central-monetoring">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Central Monitoring System
                                </div></Link>
                                <Link to="/cloud-surveillance"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Cloud Surveillance
                                </div> </Link>
                                <Link to="/integrated-command"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Integrated Command Center
                                </div> </Link>
                                <Link to="/face-recognition">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Face Recognition
                                </div></Link>


                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className=' lg:px-[4rem] px-5 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Completely based on Artificial Intelligence, Machine Learning, and Deep Learning.</li>
                            <li>Walks on any IP Camera including ONVIF.
                            </li>
                            <li>Compatible with INTEL & NVIDIA.
                            </li>
                            <li>Web-based GUI.
                            </li>
                            <li>Walks on both Windows & Linux.
                            </li>
                            <li>Walks on both Edge & Server scenarios.
                            </li>

                        </motion.ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default IntelligentTraffic