import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import IntelligentTraffic from '../components/ThirdProduct/IntelligentTraffic'

const ThirdProduct = () => {
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/its-banner.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="INTELLIGENT"
                title1="TRAFFIC SOLUTIONS"
            />
            <IntelligentTraffic />

            <ContactFooter />
            <Footer />

        </div>
    )
}

export default ThirdProduct