import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";

import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const DebrisGarbageDetection = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true }) 
    const listItem = [
        "Detects garbage lying on roads or places other than the bin.",
        "Detects the overflow and size of the garbage in the bin.",
        "Based on AI & Machine Learning.",
        "Integrates with Waste Management System for proper alerting.",
        
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/Garbage.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Debris"
                title1="and Garbage Detection"
                pos="bottom"
            />
            <SubPagesIntro
                firstTitle="Debris"
                secondTitle="and Garbage Detection"
                peraOne="The system detects garbage bins of varying sizes and shapes. It creates an alert when the garbage bin overflows or is about to overflow. It monitors other public areas for debris or garbage and localizes the same."
                imgSrc="/assets/images/products/garbage.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]} 
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]} 
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}
            />

            
            
            {/* <div className=' lg:px-[4rem] px-5 pb-12'>
                <div className='bg-black lg:px-8 px-2 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                            <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>

                        </motion.ul>
                    </div>
                </div>
            </div> */}
            {/* <SubPages /> */}
            <Footer />
        </>
    )
}

export default DebrisGarbageDetection