import { useInView } from "framer-motion";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";


const Heading = ({ title, para }) => {

  const ref = useRef(null);
  const isInView = useInView(ref);
  const { t } = useTranslation();
  return (
    <div className="font-bold text-center space-y-4" ref={ref}>
      <h2
        className="text-primary text-2xl uppercase tracking-widest underline underline-offset-[10px] decoration-blue-gray-700 drop-shadow-md shadow-blue-600/50"
        style={{
          transform: isInView ? "none" : "translateX(-400px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {t(title)}
      </h2>
      <p className="text-lg tracking-wide max-w-xl mx-auto md:px-0 px-6">{t(para)}</p>
    </div>
  );
};

export default Heading;
