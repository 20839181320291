import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const AbandonedObjectDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Define parameters for example zone, object type, size (Minimum, Maximum), and abandoned duration.",
        "The system learns and identifies temporary background changes to avoid false alarms.",
        "Well-tuned to handle crowded scenarios like Airports, Metro stations.",
        "Ideal for detecting abandoned baggage in public places and detecting foreign objects on Railway tracks.",
       
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/AbandonedObjectDetection.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Abandoned "
                title1=" Object Detection "
                pos="center"
            />
            <SubPagesIntro
                firstTitle="Abandoned"
                secondTitle="Object Detection"
                peraOne="Round-the-clock monitoring of hundreds or thousands of cameras is practically impossible for human beings in crowded areas for example Airports, Railways, Metro Stations, etc. Abandoned baggage/ object aims at detecting suspicious objects, and unattended baggage in a surveillance camera."
                imgSrc="/assets/images/products/abandonedTwo.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                data={listItem}
            />

            {/* <div className=' lg:px-[4rem] px-5 pb-10'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Very accurate even in low light conditions.</li>
                            <li>Well tuned to handle crowded scenarios like Airports, Metro stations.</li>
                            <li>System can learn and identify temporary background changes to avoid false alarms.</li>
                            <li>Pre Event recording feature is available with this analytic.</li>
                            <li>Ideal for detecting the abandoned bomb baggage in public places.</li>
                            <li>Useful for detecting the Foreign objects on Railway tracks.</li>
                            <li>Well suited to places like Shopping malls, Airports, Metro Stations, etc.</li>
                        </motion.ul>
                    </div>

                </div>

            </div> */}
            {/* <SubPages /> */}
            <Footer />
        </>
    )
}

export default AbandonedObjectDetection