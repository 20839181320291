import React from "react";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import Footer from "../components/common/Footer";

const Troubleshoot = () => {
  return (
    <div>
      <Navbar />
      <div className="mt-36 space-y-12">
        <div className="grid grid-cols-1  px-[30px]  lg:grid-cols-2  md:grid-cols-2 sm:grid-cols-2  items-center lg:px-60 lg:gap-[120px] ">
          <div className="">
            <img src="/assets/images/banner (2).png" alt="i2v"  className="w-[380px] A" />
          </div>

      
          <div className="text-start mt-4">
            <div className=" ">
              <h1 className="text-gray-900 text-start font-bold  text-[19px]">
                ERROR <strong className="text-[#EA1D24]">505</strong>
              </h1>
            </div>
            <h2 className="text-black text-[25px]   font-bold ">
              Uh oh! I think you're lost.
            </h2>
            <div className=" text-[20px]">
              <h3 className="text-black  font-semibold ">
                1 - Check your Internet Connection
              </h3>
              <h4 className="text-black font-semibold">
                2 - Refresh your page.
              </h4>
              <h5 className="text-black  font-semibold">
                3 - Clear your cache and cookies.
              </h5>
              <h6 className="text-black  font-semibold">
                4 - Restart your browser.
              </h6>
            </div>
          </div>
        </div>


    {/* <div className="text-start">
            <div className="lg:py-3 ">
            <h1 className="text-gray-900 text-start font-bold lg:text-2xl text-[15px]">
              ERROR <strong className="text-[#EA1D24]">505</strong>
            </h1>
            </div>
            <h2 className="text-black lg:text-3xl text-[15px]   font-bold ">Uh oh! I think you're lost.</h2>
            <div className="lg:py-3 lg:text-[21px] text-[20px]">
            <h3 className="text-black  font-semibold ">1 - Check your Internet Connection</h3>
            <h3 className="text-black font-semibold">2 - Refresh your page.</h3>
            <h3 className="text-black  font-semibold">3 - Clear your cache and cookies.</h3>
            <h3 className="text-black  font-semibold">4 - Restart your browser.</h3>
            </div>
          </div> */}

        <ContactFooter />
        <Footer />
      </div>
    </div>
  );
};

export default Troubleshoot;
