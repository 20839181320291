import React from 'react'

const Choose = () => {
    return (
        <div className=' lg:px-[4rem] px-5 pt-2 pb-8'>
            <h1 className='bg-primary text-white text-2xl text-center font-semibold py-4 '>Why to choose i2V as a channel Partner ? </h1>

            <div className='bg-black lg:px-8 px-5'>
                <div className=' '>
                    <h1 className='  pt-10 lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md'> Partnership Highlights </span></h1>
                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                    <ul className='list-disc p-3 text-start'>
                        <li className=' w-full lg:px-3 lg:text-lg text-base pt-4 pb-2 text-white'>i2V is designed for utmost scalability and easy usability which enables our Certified Partners to install, manage and support i2V software effectively.</li>
                        <li className=' w-full  lg:px-3 lg:text-lg text-base py-2 text-white'>i2V makes it a priority to provide partners with all of the technical support they need, providing training to get started and continued IT assistance in the field.</li>
                        <li className=' w-full  lg:px-3 lg:text-lg text-base py-2 text-white'>i2V maintains an Online Portal, WhatsApp Groups, and Chat Groups and has a support center available to answer your questions.</li>
                        <li className='w-full  lg:px-3 lg:text-lg text-base py-2 text-white'>i2V regularly engages in co-marketing campaigns; the success of our partners is important to us.</li>

                    </ul>
                </div>

                <div className=' '>
                    <h1 className=' lg:pt-4  pt-10 lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3 py-1 rounded-t-md'> Support Offered </span></h1>
                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[70%] mt-1"></div>
                    <ul className='list-disc p-3 text-start'>
                        <li className=' w-full  lg:px-3 lg:text-lg text-base pt-4 pb-2 text-white'>i2V offers free evaluation version support and training to its partners.</li>
                        
                    </ul>
                </div>

                <div className=' '>
                    <h1 className=' lg:pt-4  pt-10 lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3 py-1 rounded-t-md'> Customised Integration </span></h1>
                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1"></div>
                    <ul className='text-start p-3 list-disc'>
                        <li className=' w-full  lg:px-3 lg:text-lg text-base pt-4 pb-2 text-white'>i2V offers customized developments or integration for partner inquiries.</li>
                        <li className=' w-full  lg:px-3 lg:text-lg text-base pt-2 pb-4 text-white'>i2V offers customized Machine Learning and Deep Learning options based on customer requirements.</li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Choose