import React, { useRef } from "react";


import { motion } from "framer-motion";
import { useInView } from "framer-motion";


const ViolationSection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <>
            <div className="">
                <div className="lg:px-16 px-5 pt-10 pb-16 space-y-8">
                    <motion.div

                        ref={ref}
                        initial={{ y: isInView ? 40 : 0, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 40, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className=" text-justify">
                            <h3 className="text-xl"> Red Light Violation Detection (RLVD) </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="pt-4 ">
                            i2V RLVD is an intelligent and powerful video-based Violation Detection System. Automatically detects and captures the vehicle crossing a defined zone while the traffic light is red. A zone can be a stop line or zebra crossing at the junction.
                            </p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/redLight.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl md:pt-0 pt-2"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Video-based Red Light Monitoring in Real-time, with Evidence Monitoring of Infractions/Violations also in Real-time.</li>
                                    <li>Stores Video & Multiple Images of Violating Vehicles in Different Lanes with a Unique ID, Location/Direction, Device ID, Lane No. or Lane ID.</li>
                                    <li>Evidence Image & ANPR Image along with Date & Time Stamp stored in the database in encrypted form.</li>

                                    <li>Real-time Alerts on Red Light Violations.</li>
                                    <li id="noHelmet">Automatic E-Challan Generation for Violators (Speed Violation & Red Light Violation Detection, Triple Riding Detection, No Helmet Detection).</li>

                                </ul>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div

                        initial={{ y: isInView ? 40 : 0, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 40, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl">No Helmet Violation Detection
                            </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            i2V’s Video-based No Helmet Detection is mainly targeted at Intelligent Traffic Management Solutions (ITES/ITMS) for Safe City, Smart City, and City Surveillance along with ANPR for Full Proof ITES/ITMS Solutions for Curbing Helmet Violations.
                            </p>
                            <p>It works by the Evidence Capturing Mechanism of Commuters traveling on a Two Wheeler without a Helmet in tandem with the ANPR System and based on the Artificial Intelligence Model and Utilizes Deep Learning Methodology. It detects for Rider’s Head with or without a Helmet on a Two Wheeler Vehicle.</p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/nohelmet.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl md:pt-0 pt-2"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Compatible with all ONVIF Standard IP Cameras.</li>
                                    <li>Automates the Violation Process & Regulates the Violation.</li>

                                    <li>Eliminates Manual Processes of Catching Commuters with No Helmets.</li>

                                    <li>Improves Safety & Security of Commuters.</li>
                                    <li>Increases Productivity & Efficiency of Traffic Professionals.</li>
                                    <li id="speed">Help gather evidence & Proof that can be used in Contempt of Court.</li>

                                </ul>
                            </div>
                        </div>
                    </motion.div>
                    <div
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl">Speed Violation Detection</h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            i2V Speed Violation Detection is the state-of-the-art video-based speed violation detection system that determines the vehicular speed by calculating the time between the multiple frames of the vehicle and captures an image of the number plate of the violating vehicle. i2V does not use conventional sensors like radars, inductive loop sensors, lasers, etc. for detecting the speed.
                            </p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/speedVoilation.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl md:pt-0 pt-2"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Speed Violations & ANPR for Speeds up to 200Km/Hr.</li>
                                    <li>Stores Video & Multiple Images of Violating Vehicles in Different Lanes with a Unique ID, Location/Direction, Device ID, Lane No. or Lane ID. </li>
                                    <li>Evidence Image & ANPR Image along with Date & Time Stamp stored in the database in encrypted form.</li>
                                    <li>Real-time Alerts on Speed Violations.</li>
                                    <li id="stopped">Automatic E-Challan Generation for Violators (Speed Violation & Red Light Violation Detection, Triple Riding Detection, No Helmet Detection).</li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div

                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl">Stopped Vehicle / Illegal Parking Violation Detection</h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            Most road accidents are mainly due to stopped vehicles. Our analytics is capable of detecting a stopped vehicle in multiple scenarios. Once the system detects the stopped vehicle, it is capable of taking a zoomed snapshot of the vehicle which includes the License Plate number image of the vehicle.
                            </p>
                            <p>It can detect any stopped vehicle on the roads. Once the user selects any No-Parking area, the system starts detecting vehicles coming close to it. If any vehicle lowers its speed or tries to stop in the No-Parking zone, then the system will start alerting the user for illegal halting. Users can select any number of zones in the video and each zone can have its configurable parameters. Here time duration for which the vehicle is allowed to halt is also configurable.</p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/stoppedFour.png" alt="i2v" />
                            </div>
                            <div className="text-justify col-span-3">
                                <h3 className="text-xl md:pt-0 pt-2"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="list-disc text-white text-start p-6 space-y-2"

                                >
                                    <li>With this system, we can prevent any kind of illegal parking or illegal halting on the roads, premises, etc.</li>
                                    <li>It would be very useful to prevent traffic jams in crowded areas because of the illegal halting.</li>
                                    <li>With this analytic, we can also detect the vehicle which needs assistance.</li>
                                    <li id="Triple">Illegal halting for a long period can be detected & alerted to security for any unusual activity like BOMB or Explosive material in the vehicle.
                                        </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl">Triple Riding Violation Detection</h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            Triple Riding Detection systems work by Evidence Capturing Mechanism of Commuters traveling on a Two Wheeler with 3 Persons on a Single Vehicle (Triple Riding) in tandem with the ANPR System. The Algorithm is based on the Artificial Intelligence model and Utilizes Deep Learning methodology for Triple Riding Violations.
                            </p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/triple.png" alt="i2v" />
                            </div>
                            <div className="text-justify col-span-3">
                                <h3 className="text-xl md:pt-0 pt-2"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="list-disc text-white text-start p-6 space-y-2"

                                >
                                    <li>Compatible with all ONVIF Standard IP Cameras.</li>
                                    <li>Real-time Alerts on Triple Riding Detection.</li>
                                    <li>Automates the Violation Process & Regulates the Violation.</li>
                                    <li>Eliminates Manual Processes of Catching Commuters with Triple Riding.</li>
                                    <li>Improves Safety & Security of Commuters.</li>
                                    <li>Increases Productivity & Efficiency of Traffic Professionals.</li>
                                    <li id="wrongviolation">Help gather evidence & Proof that can be used in Contempt of Court.</li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div

                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl"> Wrong Way Detection </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            Vehicle wrong-way detection analytics provides an Intelligent Solution for traffic management by detecting vehicles going in the wrong way on the road. It sends an alarm and video feed to the central server when any vehicle moves in the wrong direction.
                            </p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/wrongTwo.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl md:pt-0 pt-2">Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Supports all types of virtual lines Vertical, Horizontal, and Slanted.</li>
                                    <li>Supports multiple lines with all types of line combinations.</li>

                                    <li>Supports Direction rule-based Perimeter security.</li>

                                    <li>Supports a combination of Connected and Non Connected virtual perimeter lines.</li>
                                    <li>Allows different Setting for Each Perimeter Line.</li>
                                    <li>Each Perimeter Line is identified with a unique identifier, as a result, a user can get the exact perimeter Line where the breach has occurred.</li>
                                    <li>Supports Global parameter Control over all the Virtual Perimeter Lines.</li>
                                    <li>Automatically enhances the Image under Scenarios like Bad light, Fog, etc.</li>
                                    <li>Automatically zooms the image of the breached object.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default ViolationSection;
