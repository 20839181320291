import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { careerSchema } from '../../schemas';


const initialValues = {
    name: "",
    phone: "",
    email: "",
    applied: "",
    file: ""
}

const CareerForm = ({ showModalIn, setShowModal }) => {
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: careerSchema,
        onSubmit: (values, action) => {
            console.log(values);
            action.resetForm();
        }
    }
    );

    return (
        <>
            <Transition appear show={showModalIn} as={Fragment}>
                <Dialog as="div" className="relative" onClose={() => setShowModal(false)} style={{ zIndex: 100 }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded bg-blue-gray-50 p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex items-center justify-center md:px-12">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-4 mx-2">
                                                <label className="block text-grey-darker text-sm font-bold mb-2">Full Name:</label>
                                                <input className=" border rounded w-full py-2 px-3 text-grey-darker" type="text" name='name'
                                                    placeholder="Enter Your Name"
                                                    autoComplete='off'
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.name && touched.name ? <p className='text-primary'>{errors.name}</p> : null}

                                            </div>


                                            <div className="mb-4 mx-2">
                                                <label className="block text-grey-darker text-sm font-bold mb-2">Phone:</label>
                                                <input className=" border rounded w-full py-2 px-3 text-grey-darker" type="phone" name='phone'
                                                    placeholder="Enter Your Phone no."
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.phone && touched.phone ? <p className='text-primary'>{errors.phone}</p> : null}
                                            </div>

                                            <div className="mb-4 mx-2">
                                                <label className="block text-grey-darker text-sm font-bold mb-2">Email:</label>
                                                <input className=" border rounded w-full py-2 px-3 text-grey-darker" type="email" name='email'
                                                    placeholder="Enter Your Email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.email && touched.email ? <p className='text-primary'>{errors.email}</p> : null}

                                            </div>

                                            <div className="mb-4 mx-2">
                                                <label className="block text-grey-darker text-sm font-bold mb-2">Applied For:</label>
                                                <input className=" border rounded w-full py-2 px-3 text-grey-darker" type="text" name='applied'
                                                    placeholder="Enter Position"
                                                    value={values.applied}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.applied && touched.applied ? <p className='text-primary'>{errors.applied}</p> : null}

                                            </div>


                                            <div className="mb-4 mx-2">
                                                <label className="block text-grey-darker text-sm font-bold mb-2">Upload Resume:</label>
                                                <input type="file" name='file'
                                                    value={values.file}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="block w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-2
                                                        fill:black
                                                        file:text-sm file:font-semibold
                                                        file:bg-violet-50 file:text-violet-700
                                                        hover:file:bg-violet-100
                                                    "/>
                                                {errors.file && touched.file ? <p className='text-primary'>{errors.file}</p> : null}


                                            </div>

                                            <div className='flex'>
                                                <button className='flex gap-3 group bg-black border text-white border-white hover:bg-primary lg:px-20 px-6 py-3 cursor-pointer mx-auto mt-3' onClick={() => setShowModal(false)}>Cancel</button>
                                                <button type='submit' className='flex gap-3 group bg-black border text-white border-white hover:bg-primary lg:px-20 px-6 py-3 cursor-pointer mx-auto mt-3'>Submit</button>

                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default CareerForm