import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const SpeedDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Speed Violations & ANPR for Speeds up to 200 Km/Hr.",
        "Stores Video & Multiple Images of Violating Vehicles in Different Lanes with a Unique ID, Location/Direction, Device ID, Lane No. or Lane ID.",
        "Helps in the Analysis of slow speed, average traffic slow, and congestion in single or multiple lanes.",
        "Evidence Image & ANPR Image along with Date & Time Stamp stored in the database in encrypted form.",
        "Real-time Alerts on Speed Violations.",
        "Improves Safety & Security of Commuters.",
        "Automatic E-Challan/Ticket Generation for Violators (Speed Violation & Red-Light Violation Detection, Triple Riding Detection, No Helmet Detection).",
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/SpeedDetection.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Speed"
                title1="  Detection
                "
                pos="bottom"
            />
            <SubPagesIntro
                firstTitle="Speed"
                secondTitle="Detection"
                peraOne="i2V Speed Violation Detection is the state-of-the-art video-based speed violation detection system that determines the vehicular speed by calculating the time and distance between the multiple frames of the vehicle and captures the image of the number plate of the violating vehicle. i2V does not use conventional sensors like radars, inductive loop sensors, lasers, etc. for detecting the speed. The solution comes with a user-friendly Graphical User interface (GUI) for seamless operation."
                imgSrc="/assets/images/products/speedDetection.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]} 
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]} 
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}
            />
            

            {/* <div className=' lg:px-[4rem] px-3 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                        ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Speed Violations & ANPR for Speeds up to 200Km/Hr.</li>
                            <li>Stores Video & Multiple Images of Violating Vehicles in Different Lanes with a Unique ID, Location/Direction, Device ID, Lane No. or Lane ID. </li>
                            <li>Evidence Image & ANPR Image along with Date & Time Stamp stored in database in encrypted form.</li>
                            <li>Real-time Alerts on Speed Violation.</li>
                            <li>Automatic E-Challan Generation for Violators (Speed Violation & Red Light Violation Detection, Triple Riding Detection, No Helmet Detection).</li>
                        </motion.ul>
                    </div>

                </div>

            </div> */}
        
            <Footer />
        </>
    )
}

export default SpeedDetection