import React, { useRef } from "react";
import { GoDeviceMobile } from "react-icons/go";
import { FiMail } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { FaAddressBook } from "react-icons/fa"; // Using FaAddressBook for Phonebook icon
import { useInView } from "framer-motion";
import { Input, Textarea } from "@material-tailwind/react";
import { useFormik } from "formik";
import { contactFooterSchema } from "../../schemas/index";
import { useTranslation } from "react-i18next";

const initialValues = {
  fname: "",
  email: "",
  phone: "",
  message: ""
};

const ContactFooter = () => {
  const { values, touched, errors, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: contactFooterSchema,
    onSubmit: (values, action) => {
      console.log(values);
    }
  });

  const ref = useRef(null);
  const isInView = useInView(ref);

  const { t } = useTranslation();

  return (
    <div className="bg-black">
      <div className="lg:px-[4rem] md:px-12 px-6 overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 justify-between container-default py-20 items-center">

          {/* Left Column */}
          <div className="flex flex-col space-y-6 z-10">
            {/* Contact Us Section */}
            <div className="mb-6">
              <h3 className="text-2xl text-white uppercase tracking-wider text-start w-full mb-4">
                {t("Contact Us")}
              </h3>
              <div className="h-1 w-36 bg-[#EA1D24]" ref={ref}></div>
            </div>

            {/* Titles and Information */}
            <div className="flex md:flex-row flex-col justify-between md:divide-x-2 divide-x-0 md:divide-y-0 divide-y-2 gap-8 divide-black">
              <div className="space-y-4">
                <div className="flex flex-col">
                  <h3 className="text-2xl text-white tracking-wider text-start w-full">
                    {t("For Career and Jobs")}
                  </h3>
                  <div className="h-1 bg-[#FFFFFF] inline-block w-[75%]"></div>
                  <div className="flex items-center mt-6">
                    <FaAddressBook color="#fff" size={20} className="mr-2" />
                    <p className="text-white">{t("Monika Khetarpal")}</p>
                  </div>
                  <div className="flex items-center mt-3">
                    <GoDeviceMobile color="#fff" size={20} className="mr-2" />
                    <p className="text-white">{t("+91 880-059-7104")}</p>
                  </div>
                  <div className="flex items-center mt-3">
                    <FiMail color="#fff" size={20} className="mr-2" />
                    <p className="text-white">{t("careers@i2vsys.com")}</p>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="flex flex-col">
                  <h3 className="text-2xl text-white tracking-wider text-start w-full">
                    {t("For Business Relations")}
                  </h3>
                  <div className="h-1 bg-[#FFFFFF] inline-block w-[75%]"></div>
                  <div className="flex items-center mt-6">
                    <FaAddressBook color="#fff" size={20} className="mr-2" />
                    <p className="text-white">{t("Lokesh Jain")}</p>
                  </div>
                  <div className="flex items-center mt-3">
                    <GoDeviceMobile color="#fff" size={20} className="mr-2" />
                    <p className="text-white">{t("+91 830-766-5562")}</p>
                  </div>
                  <div className="flex items-center mt-3">
                    <FiMail color="#fff" size={20} className="mr-2" />
                    <p className="text-white">{t("lokesh.jain@i2vsys.com")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col pt-5">
              <h3 className="text-2xl text-white tracking-wider text-start w-full">
                {t("Corporate Office")}
              </h3>
              <div className="h-1 w-44 bg-[#FFFFFF] mb-1"></div>
            </div>
            
            {/* Address Section */}
            <div className="mt-9 flex items-start">
              <MdLocationOn color="#fff" size={25} className="mr-2 mt-[2px]" />
              <div>
                <p className="text-white text-left w-[75%]">{t("i2V Systems Pvt. Ltd.")}</p>
                <p className="text-white text-left w-[75%]">
                  {t("Software Technology Parks of India, 3rd & 4th Floor, Plot No 30, Electronic city, Sector 18, Gurugram-122015, Haryana, India.")}
                </p>
              </div>
            </div>
          </div>

          {/* Right Column (Form Section) */}
          <div className="w-full md:flex md:justify-end z-10 mt-10 lg:mt-0" ref={ref}
            style={{
              transform: isInView ? "none" : "translateX(140px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.9s",
            }}>
            <form onSubmit={handleSubmit} className="lg:w-[80%] w-full rounded-xl border border-gray-500 bg-[#ffffff14] p-8 flex flex-col space-y-5 justify-center items-center">
              <Input color="blue-gray" label="Full Name "
                name="fname"
                id="fname"
                value={values.fname}
                onChange={handleChange}
                onBlur={handleBlur} />
              {errors.fname && touched.fname ? <p className='text-primary'>{errors.fname}</p> : null}

              <Input color="blue-gray" label="Email Address"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur} />
              {errors.email && touched.email ? <p className='text-primary'>{errors.email}</p> : null}

              <Input color="blue-gray" label="Phone No."
                name="phone"
                id="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur} />
              {errors.phone && touched.phone ? <p className='text-primary'>{errors.phone}</p> : null}

              <Textarea color="blue-gray" label="Message"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur} />
              <button type="submit" className="font-bold tracking-wide w-full px-10 py-3 text-white bg-primary hover:bg-white hover:text-black button-animate rounded-xl text-sm">
                {t("Request for Trial")}
              </button>
            </form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ContactFooter;
