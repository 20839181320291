import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const HumanPatternRecognition = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Recognize various objects and attributes in the outdoor environment.",
        "Recognize human body patterns and movements in real-time, not just for a single person but also many targets or groups of people.",
        "Carry out accurate and exact classifications despite the complexity of human behavior.",
        "Alerts can be raised on human body pattern or position for example person fallen, crawling, people fighting or crowd unruly behavior."
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/HumanPatternRecognition.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Human"
                title1="  Pattern Recognition
                "
                pos="center"
            />
            <SubPagesIntro
                firstTitle="Human"
                secondTitle="Pattern Recognition"
                peraOne="It detects and recognizes the behavior patterns of people in the field of vision of the camera. It's designed to swiftly respond by triggering alarms when it detects any potentially critical situations occurring within a predefined timeframe. This proactive approach significantly enhances security and surveillance, ensuring timely responses to any unusual or concerning behaviors."
                peraTwo="For example: Action Recognition like Person walking, Violence, Ganging a Women. Alert on women/person in distress raising his/her hand(s) for help, sitting on the road, etc."
                
                imgSrc="/assets/images/products/human.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}
            />


            {/* <div className=' lg:px-[4rem] px-3 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-6 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>The ability to recognize various objects and attributes in the environment.</li>
                            <li>Recognize human body patterns and movements in real-time, not just for a single person but also for many targets or groups of people.</li>
                            <li>carry out accurate and exact classifications despite the complexity of human behaviour.</li>
                            <li>It is capable of detecting various objects, automatically identifying anomalies on the monitor screen, and notifying the appropriate authorities</li>
                        </motion.ul>
                    </div>

                </div>

            </div> */}
            {/* <SubPages /> */}
            <Footer />
        </>
    )
}

export default HumanPatternRecognition