import React, {  useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import VidsSection from "./VidsSection";
import {BsArrowRightCircleFill} from "react-icons/bs";

const VideoIncidentDetectionSystem = () => {
    
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/VIDS.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Video"
                title1=" Incident Detection  System (VIDS)"
                pos="bottom"
            />
            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-8 pt-7">
                    <motion.div className="grid grid-cols-1 gap-10 lg:grid-cols-2   justify-between container-default pb-12 items-start"
                        ref={ref}
                        
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary"> Video </span> Incident Detection  System (VIDS)
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pt-6">
                            i2V Video Incident Detection System (VIDS) is a multifunctional set of intelligent transportation systems comprised of modules such as <b>:</b>
                            </p>
                            
                            <div className="space-y-2 capitalize ">
                                <a href="#vehicle" className="grid grid-cols-12  items-center pl-2 md:pl-6 bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24}/> <span className="py-2 col-span-10 md:text-start">Stopped Vehicle Detection</span></a>
                                <a href="#wrong" className="grid grid-cols-12  items-center pl-2 md:pl-6 bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24}/> <span className="py-2 col-span-10 md:text-start">wrong way detection</span></a>
                                <a href="#pedestrian" className="grid grid-cols-12  items-center pl-2 md:pl-6 bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24}/> <span className="py-2 col-span-10 md:text-start">pedestrian movement detection</span></a>
                                <a href="#abandoned" className="grid grid-cols-12  items-center pl-2 md:pl-6 bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24}/> <span className="py-2 col-span-10 md:text-start">abandoned object lying on road</span></a>
                                <a href="#average" className="grid grid-cols-12  items-center pl-2 md:pl-6 bg-gradient-to-l  from-primary to-white gap-4  text-lg hover:shadow cursor-pointer"><BsArrowRightCircleFill className="col-span-1" size={24}/> <span className="py-2 col-span-10 md:text-start">average traffic flow based on congestion</span></a>
                            </div>

                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10">
                            <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
                                <img src="/assets/images/products/vids.png" alt="i2v" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
            <VidsSection/>
            <Footer />
        </>
    )
}

export default VideoIncidentDetectionSystem