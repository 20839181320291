import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";

const ImageEnhancement = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/ip-video.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="VIDEO "
                title1="   Intrusion Detection"
                pos="bottom"
            />
            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-12 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-center"
                        ref={ref}
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary"> Video </span>   Intrusion Detection
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pt-6">
                                This is the process of locating a moving object in a prohibited or restricted area. The algorithm analyses the video frames and outputs the location of moving targets within the frame.
                            </p>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pb-6">
                                Video based Intrusion detection (VID) is a way of defining activity in a scene by analyzing image data and differences in a series of images. The functionality is made available with video management software. System allows you to set the activity threshold depending on what you want to monitor. System has the capability to set the sensitivity for indoor or outdoor conditions to avoid false alarms.
                            </p>

                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10">
                            <div className="lg:w-[80%] w-full  border border-gray-500 rounded bg-black lg:p-8 p-4 flex flex-col space-y-5  ">
                                <img src="/assets/images/IntrusionDetection.jfif" alt="i2v" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className=' lg:px-[4rem] px-5 lg:py-6'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-4 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md'> features Highlights </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Supports Multiple Intrusion Zone selection under single Camera view itself.</li>
                            <li>Direction based Intrusion detection with regular shapes.</li>
                            <li>Supports Wide variety of Intrusion Zones shapes starting with Simple Rectangle to Any sized Polygon</li>
                            <li>User can define Directional based Intrusion for Regular Shaped Zones like Square, Rectangle.</li>
                            <li>User can get Zone based Alarms</li>
                            <li>Global Parameter Control Over the System</li>
                            <li>Well tuned to even Low Frame Rate and Low Resolution Input</li>
                            <li>Minimal False Alarm Rates</li>
                            <li>Automatic Image enhancement Controls for Bad light Scenarios </li>
                            <li>Well customized to Indian Requirements</li>
                            <li>Supports the feature of scheduled automatic on/off of this Analytics module.</li>
                        </motion.ul>
                    </div>

                </div>

            </div>
           
            <Footer />
        </>
    )
}

export default ImageEnhancement