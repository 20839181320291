import { Fragment, useState } from "react";
import Banner from "../components/common/Banner";
import Navbar from "../components/common/Navbar";
import { RiArrowDropDownLine } from "react-icons/ri";
import ContactFooter from "../components/Home.js/ContactFooter";
import Footer from "../components/common/Footer";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";

const Faq = () => {
  
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/faqBg.png"
        pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
      has been the industry's standard dummy text ever since the 1500s"
        title1="FREQUENTLY ASKED QUESTIONS ?"
      />




      <div className="md:px-20 md:py-12 px-4 py-8 grid grid-cols-1 md:gap-8 gap-6">
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">Does i2V provide both Video Analytics Software and Video Management Systems?</h2>
                <RiArrowDropDownLine
                
                  className={`${open ? 'rotate-180 transform h-10 w-10' : ''
                    } h-10 w-10 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <b>Yes</b>, i2V is a completely Intelligent and Integrated Video solution that comprises an Advanced Video Management System with Inbuilt Integrated i2V Video Analytics and Open Framework Architecture for third-party Device Integration.
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <h2 className="md:w-auto w-96">How can I choose i2V Edition from Basic, Professional, and Enterprise?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p><b>Basic Edition:</b>If you require only Video Management Solution then select i2V Basic Edition which will provide all basic features along with advanced features like Alarm Management, Event Management. i2V Video Server is also available which allows multiple clients to connect and view the video. 30 days free Trial of i2V Video Analytics comes with this Edition.</p>
                  <p><b>Professional Edition:</b>If you are looking for both a Video Management solution and Video Analytics then select i2V Professional Edition. It provides maximum functionalities to manage all surveillance needs. i2V's Incident-Event-Action framework allows taking utmost actions based on each event which converts conventional passive surveillance solution into a Proactive Video Solution.</p>
                  <p><b>Enterprise Edition:</b>If you are looking for a complete video solution framework that comprises Advanced Video Management Software with Integrated Video Analytics and an Open framework for Third party device integration with Centralized Alarm and Event Management then select i2V Enterprise edition.</p>
                  <p>Please <Link to="/ip-Video" className="text-blue-600 hover:underline">Click Here</Link> to learn more about i2V editions.</p>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
              <h2 className="md:w-auto w-96">What are the Minimum Hardware Requirements for installing i2V Software?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V can be installed on any standard IT hardware. But with respect to hardware limitation, the number of supported Cameras may vary as per different hardware configurations. i2V can support from 8 Cameras to 120+ cameras on a single system as per usage requirements provided the best hardware configuration is available.</p>

                  <p>Please <Link to="/contact-us" className="text-blue-600 hover:underline">Contact Us</Link> to know more about the Basic Hardware requirements.</p>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">What are the overall functionalities available with i2V Solution?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <h2>With i2V Video Solution you can use various combinations of the below features:</h2>

                  <ul className="list-disc px-8 font-semibold">
                    <li>Live View of Camera Video.</li>
                    <li>Apply i2V Video Analytics.</li>
                    <li>Simple Recording.</li>
                    <li>Event Based Recording.</li>
                    <li>Simple Playback.</li>
                    <li>Advanced Multiple Playback.</li>
                    <li>Intelligent Video Search.</li>
                    <li>Event Snapshot based Video search.</li>
                    <li>Multi Server Redundancy and Health Check.</li>
                    <li>Easy Third Party Device Integration.</li>
                    <li>Streaming of the Live Video with i2V Video Server.</li>
                    <li>Video Transcoding Server for Low resolution Recording and High Resolution live View.</li>
                  </ul>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">What are the overall functionalities available with i2V Solution?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>
                  All i2V Video Analytics works on Dynamic Background Learning based algorithms that use advanced Computer Vision and Imaging Technologies. Simple Motion Detection works on pixel difference-based methods which are prone to False Alarms that can be due to Temporary background changes. But i2V Video Analytics are tuned to handle such scenarios as it uses Advanced Object Validation Modules.
                  </p>
                  <p>Hence, i2V Algorithms significantly reduce False alarms and i2V Video Analytics Alert Settings further allow to suppress the False alarms.</p>



                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">How easily I can use i2V Video Analytics?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V Video Analytics can be used with utmost ease than any other Video Solution. You need to do below three simple operations for applying i2V Video Analytics:</p>
                  <ul className="px-8 list-disc">
                    <li>Drag the Camera on to i2V Live View Screen.</li>
                    <li>Right Click on Video and Select Your Video Analytic.</li>
                    <li>Apply your settings of Video Analytic.</li>
                  </ul>
                  <p>i2V Video Analytics are inbuilt integrated with i2V VMS and therefore, it is very easy to use them.</p>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">Do I need to have any Network Video Recorder (NVR) or Digital Video Recorder (DVR) apart from i2V Solution?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p><b>No</b>, the i2V solution is tightly integrated with i2V Video Server which offers both Recording and Streaming capabilities along with i2V Video Analytics and Advanced Alarm Event Management.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">What are the types of Cameras i2V Supports and what brands does i2V be compatible with?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V supports IP Cameras and Analog Cameras provided IP Encoder is available.</p>
                  <p>Below are the IP cameras that i2V supports:</p>
                  <ul className="list-disc px-8">
                    <li>Fixed Box cameras</li>
                    <li>Dome cameras</li>
                    <li>PTZ Cameras</li>
                    <li>Thermal Cameras</li>
                  </ul>
                  <p>i2V is compatible with major camera brands like Axis, D-Link, and Panasonic to name a few.</p>
                  <p>Please <Link to="/camera-brand" className="text-blue-600 hover:underline">Click Here</Link> to find the list of camera brands i2V is compatible with.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">I have an IP camera not mentioned in the list of IP cameras supported by i2V VMS, how can I add it?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V is in the process to make itself compatible with the maximum number of camera brands available in the industry. If your camera model is not listed under i2V-supported models then please send the camera model details along with Camera SDK to i2v@i2vsys.com. We will take a maximum of 2 working days to add your camera model to i2V.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">How many cameras can be added to i2V Solution?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>Virtually unlimited from the software end provided the best hardware configuration is available in terms of Processing Speed and Network bandwidth. i2V's Client-Multi server architecture allows users to manage Multiple Recording Servers and view all camera live videos on a centralized i2V Live View Client. In the case of recording, several supported cameras are dependent on Disk Writing Capacity also.</p>

                  <p>Please <Link to="/camera-brand" className="text-blue-600 hover:underline">Click Here</Link> to read the Basic Hardware Benchmarks in terms of the Number of supported cameras as per i2V test processes.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">What are the Operating Systems supported by i2V Solution?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V supports Windows XP, Windows Vista/7, Windows Server 2003/2008. i2V is compatible with both 32-bit and 64-bit operating systems.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">How efficient is i2V in terms of the number of cameras supported per system and storage space?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V has been designed to support the maximum number of cameras sustaining the best video quality. It supports the latest video encoding techniques like H.264 which are designed for optimal storage space.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">How to download the i2V Trial Version Software?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>Request for Free Trial at <Link to="/" className="text-blue-700 hover:underline">www.i2vsys.com</Link>. We will send you the software setup within 2 working days.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">How to Activate the i2V Software from Trial Version to the Licensed Verison?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>When the i2V setup is installed, from the Product Activation tab you can generate a Machine key. Please email your Machine Key to <a href="mailto:i2v@i2vsys.com" className="text-blue-600">i2v@i2vsys.com</a> along with your requirements in terms of the Number of Cameras and Feature Selection. We will send you License Key as soon as we receive your Machine Key email.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">How can I become a Partner / Dealer with i2V?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>Please <Link to="/channel-partner" className="text-blue-700 hover:underline">Click Here </Link> to apply for i2V Partnership Registration. We will get back to you within 2 working days once we receive your application.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <div className="shadow-xl text-start rounded group hover:shadow-2xl">
              <Disclosure.Button className="flex items-center w-full justify-between rounded-lg bg-black text-white px-4 py-2 text-left  font-medium  group-hover:shadow-xl focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <h2 className="md:w-auto w-96">Where to buy i2V software?</h2>
                <RiArrowDropDownLine
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-8 w-8 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="md:p-6 p-2  text-gray-800">
                <div className="space-y-2">
                  <p>i2V has its presence all over the world in the form of its authorized partners who have adequate experience in executing small to large Video Security and Surveillance Projects. i2V partners are trained to install, configure, operate, and manage IP Video solutions with in-depth knowledge of system features, maintenance, and troubleshooting.</p>
                  <p>If you are the end-user then please fill out the below contact us form. We will get back to you through the nearest i2V distributor <Link to="/contact-us" className="text-blue-700 hover:underline">Contact Us </Link>.</p>

                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>




      </div>



      
      <ContactFooter />
      <Footer />
    </>
  );
};

export default Faq;
