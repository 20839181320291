import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsArrowRight } from "react-icons/bs";
import Heading from "../common/Heading";
import BgVideo from "../../image/bgVideo.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OurProducts = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const data = [
    {
      id: 1,
      img: "/assets/images/product-video.png",
      title: "Video Management Software",
      para: "Managing multiple network cameras on LAN/WAN in an intelligent way provides the real scalability and flexibility for any video surveillance deployment.",
      address: "/ip-video"
    },
    {
      id: 2,
      img: "/assets/images/product-ai.png",
      title: "AI Based Video Analytics",
      para: "i2V Video Analytics are developed using machine learning, deep learning and artificial intelligence in image processing & computer vision technology.",
      address: "/ai-Based-Video"
    },
    {
      id: 3,
      img: "/assets/images/product-intelligance.png",
      title: "Intelligent Traffic Solutions",
      para: "The software can be installed at Local processing unit near the camera or at central server. Detect pedestrian wandering in specified zone.",
      address: "/intelligent-traffic"
    },
    {
      id: 4,
      img: "/assets/images/product-monitoring.png",
      title: "Central Monitoring System",
      para: "Central Management or Monitoring system is a system which can connect all the cameras and third party devices located at multiple locations or sites at a central hub.",
      address: "/central-monetoring"
    },
    {
      id: 5,
      img: "/assets/images/product-cloud.png",
      title: "Cloud Surveillance",
      para: "i2V’s cloud system provides its customers the most convenient and easy way to do the security of their premises with IP cameras.",
      address: "/cloud-surveillance"
    },
    {
      id: 6,
      img: "/assets/images/product-integrated.png",
      title: "Integrated Command Center",
      para: "A user can view, control, receive alerts & notifications from various devices anywhere in the system and take pro active decisions.",
      address: "/integrated-command"
    },
    {
      id: 7,
      img: "/assets/images/product-face.png",
      title: "Face Recognition",
      para: "The face capture process transforms analog information (a face) into a set of digital information (data) based on the person's facial features.",
      address: "/ip-video"
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div
      className="pb-16 pt-12 md:space-y-8  relative bg-cover"
      style={{ backgroundImage: `url(${BgVideo})` }}
    >
      <Heading
        title={t("OUR PRODUCTS")}
        para={t("i2V Complete Proactive Video Products")}
      />
      <div className="homeSlider md:max-w-[68rem] max-w-md 2xl:max-w-6xl md:w-full  mx-auto">

        <Slider {...settings}>
          {data.map((e, obj) => (
            <div key={obj} className="relative rounded m-4 pt-4 hover:-translate-y-2 duration-500 hover:scale-105 transition-all hover:drop-shadow-xl mx-auto justify-center cardFlex">
              <img src={e.img} className="shadow mx-auto md:h-96 w-80 rounded" alt="" />
              <div className="absolute rounded bottom-0 z-10 px-4 pb-5 pt-8 text-white text-left space-y-1 w-80 bg-gradient-to-b from-[#00000000] via-[#000000] to-black ">
                <h2 className="text-lg font-semibold" style={{ textShadow: '2px 2px 2px black' }}>{t(e.title)}</h2>
                <p className="text-sm pb-2 text-justify tracking-tight lg:h-[15vh]">{t(e.para)}</p>
                <div
                  onClick={() => navigate(e.address)}
                  className="flex gap-3 hover:bg-primary duration-200 group border-[1px] rounded-full w-40 px-6 items-center py-1 cursor-pointer">
                  <p className="text-white text-sm">{t("Know More")}</p>
                  <BsArrowRight
                    size={24}
                    className="text-white group-hover:translate-x-2 duration-150"
                  />{" "}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default OurProducts;
