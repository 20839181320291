import React from 'react'

const Partner = () => {


    const data = [
        {
            id: 1,
            img: "/assets/images/logo1 (1).png",
        },
        {
            id: 2,
            img: "/assets/images/logo1 (2).png",
        },
        {
            id: 3,
            img: "/assets/images/logo1 (3).png",
        },
        {
            id: 4,
            img: "/assets/images/logo1 (4).png",
        },
        {
            id: 5,
            img: "/assets/images/logo1 (5).png",
        },
        {
            id: 6,
            img: "/assets/images/logo1 (6).png",
        },
        {
            id: 7,
            img: "/assets/images/logo1 (7).png",
        },
        {
            id: 8,
            img: "/assets/images/logo1 (8).png",
        },
        {
            id: 9,
            img: "/assets/images/logo1 (9).png",
        },
        {
            id: 10,
            img: "/assets/images/logo1 (10).png",
        },
        {
            id: 11,
            img: "/assets/images/logo1 (11).png",
        },
        {
            id: 12,
            img: "/assets/images/logo1 (12).png",
        },
        {
            id: 13,
            img: "/assets/images/logo1 (13).png",
        },
        {
            id: 14,
            img: "/assets/images/logo1 (14).png",
        },
        {
            id: 15,
            img: "/assets/images/logo1 (15).png",
        },
        {
            id: 16,
            img: "/assets/images/logo1 (16).png",
        },
        {
            id: 17,
            img: "/assets/images/logo1 (17).png",
        },
        {
            id: 18,
            img: "/assets/images/logo1 (18).png",
        },
        {
            id: 19,
            img: "/assets/images/logo1 (19).png",
        },
        {
            id: 20,
            img: "/assets/images/logo1 (20).png",
        },
        {
            id: 21,
            img: "/assets/images/logo1 (21).png",
        },
        {
            id: 22,
            img: "/assets/images/logo1 (22).png",
        },
        {
            id: 23,
            img: "/assets/images/logo1 (23).png",
        },
        {
            id: 24,
            img: "/assets/images/logo1 (24).png",
        },
        {
            id: 25,
            img: "/assets/images/logo1 (25).png",
        },
        {
            id: 26,
            img: "/assets/images/logo1 (26).png",
        },
        {
            id: 27,
            img: "/assets/images/logo1 (27).png",
        },
        {
            id: 28,
            img: "/assets/images/logo1 (28).png",
        },
        {
            id: 29,
            img: "/assets/images/logo1 (29).png",
        },
        {
            id: 30,
            img: "/assets/images/logo1 (30).png",
        },
        {
            id: 31,
            img: "/assets/images/logo1 (31).png",
        },
        {
            id: 32,
            img: "/assets/images/logo1 (32).png",
        },
        {
            id: 33,
            img: "/assets/images/logo1 (33).png",
        },
        {
            id: 34,
            img: "/assets/images/logo1 (34).png",
        },
        {
            id: 35,
            img: "/assets/images/logo1 (35).png",
        },
        {
            id: 36,
            img: "/assets/images/logo1 (36).png",
        },
        {
            id: 37,
            img: "/assets/images/logo1 (37).png",
        },
        {
            id: 38,
            img: "/assets/images/logo1 (38).png",
        },
        {
            id: 39,
            img: "/assets/images/logo1 (39).png",
        },
        {
            id: 40,
            img: "/assets/images/logo1 (40).png",
        },
        {
            id: 41,
            img: "/assets/images/logo1 (41).png",
        },
        {
            id: 42,
            img: "/assets/images/logo1 (42).png",
        },
        {
            id: 43,
            img: "/assets/images/logo1 (46).png",
        },
        {
            id: 44,
            img: "/assets/images/logo1 (44).png",
        },
        {
            id: 45,
            img: "/assets/images/logo1 (45).png",
        },
        {
            id: 46,
            img: "/assets/images/Dell.png",
        },
        {
            id: 47,
            img: "/assets/images/HP.png",
        },
        {
            id: 48,
            img: "/assets/images/NVIDIA.png",
        },
        {
            id: 49,
            img: "/assets/images/AMD.png",
        },
        {
            id: 50,
            img: "/assets/images/intel.png",
        },


    ];

    return (
        <div>
            <div className='  lg:px-[4rem] px-5'>
                <h1 className=' lg:pt-4  pt-10 lg:text-3xl text-xl text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3 py-1 rounded-t-md'> OUR TECHNOLOGY PARTNERS </span></h1>
                <div className="h-[0.10rem] lg:w-[90%] bg-primary  w-[90%] mt-1"></div>
                <p className=' w-full py-2 text-black  text-justify'>i2V has built relationships with leading solution providers for video security & surveillance projects. Network IP Cameras manufacturers and other security device providers worldwide. We offer a wide range of choices for our partners and end customers while choosing various devices or applications.</p>
            </div>

            <div className='grid lg:grid-cols-6 grid-cols-2  md:gap-7 lg:px-20 px-5 md:pb-20 pb-8 md:pt-10'>
                {data.map((item,e) => (
                    <div key={e} className="relative pt-4">
                        <div className=''>
                            <img src={item.img} className=" mx-auto hover:scale-110 duration-500" alt='i2v' />
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Partner