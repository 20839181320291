import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../components/common/Banner'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'
import ContactFooter from '../../components/Home.js/ContactFooter'
import CareerForm from './CareerForm'


const  QualityAssuranceProfessional = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/singlejob.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title="I2V"
                title1="PROVIDES MOST EXICTING TITLES  FOR JOB "
            />
            {/* <DetailPage/> */}



            <div className='lg:px-[4rem] px-5  lg:py-8 py-10 '>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        JOB ID :
                    </p>
                    <p className='text-black font-semibold'>i2V/BSE-S/07</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Title :
                    </p>
                    <p className='text-black font-semibold'>  Quality Assurance Professional</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Job Type :
                    </p>
                    <p className='text-black font-semibold'> Full Time</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Job Location :
                    </p>
                    <p className='text-black font-semibold'> Gurgaon </p>
                </div>

                

                {/* <div className='lg:flex gap-4 bg-black  lg:w-[60%] p-4 mt-8 md:divide-x-2'>
                    <div className='flex '>
                        <p className='text-white text-base font-semibold'>Job Posted: </p>
                        <p className='text-white text-base font-semibold'>  6 days ago</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Applicants: </p>
                        <p className='text-white text-base font-semibold'>  469</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Views: </p>
                        <p className='text-white text-base font-semibold'>  902</p>
                    </div>
                </div> */}


                {/* <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Description / Summary:
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                        We are looking for highly skilled computer vision engineers who are comfortable with development and optimization of computer vision systems. You will be responsible for solving customer problems using computer vision, you will have to train models, optimize them and create inference solutions which are able to run those models/solutions on scale and edge hardware.</p>


                </div> */}
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                    Description / Summary
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">

                    We are looking for skilled software test engineers to join our QA team. As a Quality Assurance Professional, you will be responsible for evaluating software functions and designing test protocols to ensure that products work as intended. You will also be responsible for completing all testing procedures, suggesting performance changes, and preparing performance reports for the development team to improve on.
                    </p>
                </div>
               
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Roles & Responsibilities:
                    </h3>
                    <ul className='list-disc p-4 text-start'>

                        <li>Enable support analysts to diagnose and resolve customer problems and educate users on problem resolutions in a prompt, courteous, and professional manner.</li>
                        <li>Ensure customer requirements are met with consistently high quality of service.</li>
                        <li>Identify the need for new software products or enhancements to existing products.</li>
                        <li>Engage appropriate internal resources to resolve technical issues and update our customers on progress toward resolution.</li>
                        <li>Ensure that each support issue and resolution is adequately documented.</li>
                        <li>Periodically review software support log(s); track performance and identify trends, anomalies, and opportunities for improvement.</li>
                        <li>Assist in the QA process of new iterations of the software.</li>
                        <li>Determine which issues require software support and redirect those that do not to the appropriate resolution team..</li>
  
                    </ul>
                </div>





                <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Requirements and Skills:
                    </h3>
                    <ul className='list-disc text-start p-4'>

                        <li>Completed specialized education, including a bachelor's degree in information technologies or a similar field.</li>
                        <li>More than 1 years of experience in the field.Additionally a person from the same domain is more preferable.</li>
                        <li>Comprehensive knowledge of Software testing tools & creation of test plans/test beds.</li>
                        <li>Basic knowledge of any programming languages like C++, C#, JAVA etc preferable.</li>
                        <li>Knowledge of database design structures & queries execution for MySQL & Postgresql Databases.</li>
                        <li>Ability to operate command line interfaces for deep level debugging for both Windows & Linux based operating systems.</li>
                        <li>Must have a good command of Linux based operating systems.</li>
                        <li>Good & active communication/bug reporting skills.</li>
                        <li>Ability to test Web based as well as Desktop based applications.</li>
                        <li>Must have worked on different APIs like REST API/Mosquito Broker/Modbus etc.</li>
                        <li>Command on API testing tools like Yarc, Postman etc.</li>
                        <li>Understanding of Chrome Devtools for tracking & debugging errors of web-based application client/server.</li>
                        <li>Knowledge of IP networks like Classes of IPs, Subnetting, IPV4, IPV6 schemes & network tools like Wireshark etc. and network tracing commands.</li>
                        <li>Ability to design logical data flow diagrams using advanced tools.</li>
                        <li>Knowledge of MS PowerPoint, Excel & word.</li>
                    </ul>
                </div>


                {/* <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Methods to Apply
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        1- Lorem Ipsum is simply dummy text                </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        2- Lorem Ipsum is simply dummy text               </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        3- Lorem Ipsum is simply dummy text              </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        4- Lorem Ipsum is simply dummy text              </p>
                </div> */}


                {/* <div className='lg:flex gap-4 pt-4 '>
                    <p className='text-primary text-base font-semibold'>
                        Last Date to Apply :
                    </p>
                    <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
                </div> */}

                <div className='flex space-x-4 pt-6 whitespace-nowrap'>
                    <Link to="/career">  <button className=' bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Go Back
                    </button>
                    </Link>
                    <button onClick={()=>setShowModal(true)} className='bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Apply Now
                    </button>
                </div>

            </div>

            {
               showModal && (
                <CareerForm showModalIn={showModal} setShowModal={setShowModal} />
               )
            }


            <ContactFooter />
            <Footer />

        </div>
    )
}

export default  QualityAssuranceProfessional