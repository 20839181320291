import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import { motion, useInView } from "framer-motion";
import Banner from "../components/common/Banner";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const News = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref);
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/newsAndEvents.jpg"
        pageTitle="The core of trust lies in communication that leads the way towards empowerment of individuals and partners together, and we stand for our values, beliefs, and products."
        title1="NEWS & EVENTS" />

      <div className="lg:px-16 py-4">

        <h2 className="text-start font-semibold text-3xl pt-4">
          {t("OUR NEWS")}
        </h2>
        <div
          className="h-1 w-36 bg-[#EA1D24]"
          ref={ref}
          style={{
            transform: isInView ? "none" : "translateX(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        ></div>
        
        <div className="grid md:grid-cols-3 gap-8 py-6">
        <div className="col-span-1 space-y-4 text-start border-2 p-4 rounded  drop-shadow-lg shadow-lg">
            <h2 className="font-semibold p-2 bg-gradient-to-l block from-primary to-white">
            Shines at Intersec 2024: A Resounding Success
            </h2>
            <div className="">
            <img src="/assets/images/Intersec1.jpg" alt="i2v" className="w-full md:h-80 2xl:h-96 rounded" />
            </div>


            <div className="pb-4">
              <p className="text-sm text-justify pb-2">We are thrilled to announce our exceptional participation in Intersec 2024, where we showcased our cutting-edge security software solutions, including i2V VMS, i2V ICCC, i2V AI-Based Video Analytics, i2V ANPR/LPR & i2V ITMS. The overwhelming response from Security Integrators, Distributors, and OEMs underscores the industry's keen interest in our innovative technologies. This successful engagement solidifies our commitment to pushing the boundaries of security technology.</p>

            </div>


          </div>
          <div className="col-span-1 space-y-4 text-start  border-2 p-4 rounded  drop-shadow-lg shadow-lg">
            <li className="font-semibold p-2 bg-gradient-to-l block from-primary to-white">
              Hanwha Techwin Announces Integration with i2V
            </li>
            <div className="">
              <img src="/assets/images/Hanwha.jpg" alt="i2v" className="w-full md:h-80 2xl:h-96 rounded" />

            </div>

            <p className="text-sm text-justify pb-4">
              Hanwha Techwin, a global leader in world class security cameras due to its high performance, quality and value. WISENET network cameras are integrated with i2V’s platform using i2V‘s camera adding module in VMS by auto-discovery mechanism as well as via. Onvif profile S, G, T & M.

              With advanced features and accurate results, i2V has derived the enormous market growth over the years.
            </p>
            <a href="https://www.hanwha-security.com/en-sa/news-center/1298893/" className=" px-4 py-1 border-black text-blue-800 text-sm border rounded-full mt-4 hover:bg-primary hover:text-white hover:border-none" >Read more</a>



          </div>
          <div className="col-span-1 space-y-4 text-start border-2 p-4 rounded  drop-shadow-lg shadow-lg">
            <h2 className="font-semibold p-2 bg-gradient-to-l block from-primary to-white">
              Collaboration with Intel Corporation
            </h2>
            <div className="">
              <video src='/assets/video/newsTwo.mp4' className="w-full md:h-80 2xl:h-96 rounded" controls="controls" autoplay muted />
            </div>


            <div className="pb-4">
              <p className="text-sm text-justify pb-2">Intel is a multinational technology corporation that has
                released several product types over the years including CPUs, microprocessors,
                chipsets and motherboards. In 2022, Intel's available products fall into the
                categories of processors, server products, Intel® NUC, wireless network chips,
                ethernet products, chipsets, memory and storage, Intel® FPGAs, and Intel®
                eASIC™. Intel has become one of the top producers of semiconductors worldwide,
                based on revenue..</p>

            </div>


          </div>
         
          {/* <div className="space-y-4 text-start">
    <div className="">
      <img src="/assets/images/Hanwha.png" alt="i2v" />


    </div>
    <h2 className="font-semibold">
      i2V has CERT-In Certification.


    </h2>
    <p className="text-xs text-justify pb-4">
      in VMS which means it is highly secure and have zero risk towards vulnerabilities.
    </p>


  </div> */}
        </div>
      </div>
      <ContactFooter />
      <Footer />
    </>
  );
};

export default News;
