import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const CentralMonetoring = () => {


    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })


    return (

        <>

            <div className="">
                <div className="lg:px-[4rem] px-5 md:pt-8 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default items-start"
                        ref={ref}
                        // style={{
                        //     transform: isInView ? "none" : "translateX(-200px)",
                        //     opacity: isInView ? 1 : 0,
                        //     transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        //   }}
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary">Central </span> Monitoring System
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"
                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                            Central Management or Monitoring system is a system that can connect all the cameras and third-party devices located at multiple locations or sites at a central hub. Through CMS, you can view, control, and receive alerts & notifications from any camera or third-party device, located anywhere in the system and take proactive decisions.
                            </p>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                            i2V CMS acts as specialist software to be implemented at places like Railway stations, City Surveillance, ATMs present in different locations, etc. for central monitoring.
                            </p>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                            i2V CMS provides advanced video analytics which helps the user to keep track of thousands of cameras simultaneously from a single location. These video analytics helps in detecting the breach, missed object, left object, fire, or any kind of threat and thus help in enhancing the security level along with reducing time and effort.
                            </p>
                            <div className='bg-black lg:px-8 px-2 rounded'>
                                <div className='py-8 '>
                                    <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights :</span></h1>
                                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                                    <ul className="list-disc text-white text-justify md:p-6 py-6 pl-6 space-y-2"
                                    >
                                        <li>Centralized Video monitoring of Unlimited cameras spread across multiple branches and cities.</li>
                                        <li>Local recording at the branch with High-Quality Video and Low bandwidth streaming to the Central station.</li>
                                        <li>Groupings based on Branch, City, State, Country, Sensitive, and Important branches.</li>
                                        <li>Alerts in central station according to rules - Email, SMS, Sound alert, and Video Pop-up.</li>
                                        <li>Option for Video Analytics alerts Camera Tampering, Intrusion in the branch, Missing object, Perimeter Violation, and Image Enhancement.</li>
                                        <li>Situation Management by defining appropriate actions and acknowledgment for alerts.</li>
                                        <li>E-map showing the status of all cameras and alerts - camera alarm, connect and disconnect Status.</li>
                                        <li>Alarm and Device status report on an hourly, daily weekly, or monthly basis.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10"

                        >
                            <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">

                                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                                    OUR MORE PRODUCTS
                                </h1>
                                <div className="h-[0.10rem] w-full bg-white "></div>

                                <Link to="/ip-Video"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + IP Video Management Software
                                </div></Link>
                                <Link to="/ai-Based-Video"><div className=" font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + AI Based Video Analytics
                                </div></Link>
                                <Link to="/intelligent-traffic"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Intelligent Traffic Solutions
                                </div></Link>
                                <Link to="/central-monetoring"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs    ">
                                    + Central Monitoring System
                                </div> </Link>
                                <Link to="/cloud-surveillance"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Cloud Surveillance
                                </div></Link>
                                <Link to="/integrated-command">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Integrated Command Center
                                </div></Link>
                                <Link to="/face-recognition">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Face Recognition
                                </div></Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className="grid md:grid-cols-1 gap-8 lg:px-[4rem] md:px-0 px-5 py-8">
                <div className="relative pt-4">
                    <div className="shadow-lg rounded-md shadow-[#00000086]">
                        <div className=" rounded-md  shadowbox ">
                            <div className="bg-black px-6 py-3 rounded-t-md">
                                <h2 className="text-lg text-left text-white font-semibold">
                                    Advantages Of Central Monitoring System :
                                </h2>
                            </div>
                            <div className="bg-white px-10 py-8 rounded-b-md text-left">
                                <ul className="list-disc space-y-2">
                                    <li>i2V CMS possesses the capacity to monitor an unlimited number of live cameras simultaneously situated at any location.</li>
                                    <li>i2V CMS provides the facility to group the cameras based on station, location, area, or any other basis. This facility helps the user to search, view or monitor a particular camera with much efficiency and in less time.</li>
                                    <li>i2V CMS provides the facility of sending alerts to detect any breach or misfortunate event. The alert sent by i2V VMS can be in form of a pop-up on the screen, SMS, email to authority, sound alarm, etc.</li>
                                    <li>i2V CMS can also define a proactive response mechanism as per predefined rules, in case of any breach.</li>
                                    <li>i2V CMS also possesses E-maps which show the status of all cameras, devices, and alerts. It shows the health of cameras, their connect or disconnect status, etc.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            {/* <div>
                <div className=' lg:px-0 px-5'>
                    <h1 className='  pt-10 text-3xl text-black lg:text-center text-justify tracking-tighter font-bold'> Features Of Central Monitoring System </h1>
                    <p className=' lg:w-[45%] w-full text-sm py-2 text-black lg:text-center text-justify tracking-tighter mx-auto'>On each Video Analytic Event detection, i2V VMS can send alerts to appropriate security
                        personnel in the form of Sound alarm, Email, SMS, Phone call, Camera output relay Alarm.</p>
                </div>

                <div className='grid lg:grid-cols-3 grid-cols-1 lg:gap-16  gap-10 lg:px-[4rem] px-5 pb-20 pt-10'>
                    {data.map((item) => (
                        <ProductCard
                            title={item.title}
                            para={item.para}
                        />
                    ))}
                </div>

            </div> */}
        </>
    )
}

export default CentralMonetoring