import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const PerimeterIntrusionDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = ["Supports Multiple Intrusion zone or virtual perimeter lines under a single camera view.",
        "Supports a Wide variety of Intrusion Zones shapes starting with Simple Rectangles to any sized Polygon.",
        "Direction-based detection within or out of the virtual line.",
        "Select (Include or exclude) the type of objects for example human, vehicle, or animal.",
        "Users can get Zone based Alarms. Set priority and sensitivity of each zone. Different types and priority levels of alerts for different zones.",
        "Well-tuned to even Low Frame Rates and Low-Resolution Input.",
        "Supports the feature of scheduled automatically on/off of the analytics.",
    ]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/INTRUSION.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Perimeter "
                title1="  / Intrusion Detection"
                pos="center"
            />
            <SubPagesIntro
                firstTitle="Perimeter"
                secondTitle="/ Intrusion Detection"
                peraOne="This is the process of locating a moving object in a prohibited or restricted area. The algorithm analyses the video frames and outputs the location of moving targets within the frame."
               peraTwo="Video-based Intrusion detection (VID) is a way of defining activity in a scene by analyzing image data and differences in a series of images. The system can set the sensitivity for indoor or outdoor conditions to avoid false alarms."
                imgSrc="/assets/images/products/Intrusion.jpg"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}
            />


            {/* <div className=' lg:px-[4rem] px-5 pb-12'>

                <div className='bg-black lg:px-8 px-2 rounded'>
                    <div className='py-4 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Supports Multiple Intrusion Zone selection under single Camera view itself.</li>
                            <li>Direction based Intrusion detection with regular shapes.</li>
                            <li>Supports Wide variety of Intrusion Zones shapes starting with Simple Rectangle to any sized Polygon.</li>
                            <li>User can define Directional based Intrusion for Regular Shaped Zones like Square, Rectangle.</li>
                            <li>User can get Zone based Alarms.</li>
                            <li>Global Parameter Control Over the System.</li>
                            <li>Well tuned to even Low Frame Rate and Low Resolution Input.</li>
                            <li>Minimal False Alarm Rates.</li>
                            <li>Automatic Image enhancement Controls for Bad light Scenarios.</li>
                            <li>Well customized to Indian Requirements.</li>
                            <li>Supports the feature of scheduled automatic on/off of this Analytics module.</li>
                        </motion.ul>
                    </div>

                </div>

            </div> */}
            <Footer />
        </>
    )
}

export default PerimeterIntrusionDetection