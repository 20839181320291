import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import IntegratedCommand from '../components/SixPorduct/IntegratedCommand'

const SixProduct = () => {
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/intelligenttraffic.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title=" INTEGRATED "
                title1="COMMAND & Control CENTER /"
                title2=" Physical "
                title3=" Security Information Management"
            />
            <IntegratedCommand />
            <ContactFooter />
            <Footer />

        </div>
    )
}

export default SixProduct