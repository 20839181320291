import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../components/common/Banner'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'
import ContactFooter from '../../components/Home.js/ContactFooter'
import CareerForm from './CareerForm'


const TechnicalSalesEngineer = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/singlejob.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title="I2V"
                title1="PROVIDES MOST EXICTING TITLES  FOR JOB "
            />
            {/* <DetailPage/> */}



            <div className='lg:px-[4rem] px-5  lg:py-8 py-10 '>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        JOB ID :
                    </p>
                    <p className='text-black font-semibold'>i2V/TSE-S/05</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Title :
                    </p>
                    <p className='text-black font-semibold'> Technical Sales Engineer - South India</p>
                </div>

                

                {/* <div className='lg:flex gap-4 bg-black  lg:w-[60%] p-4 mt-8 md:divide-x-2'>
                    <div className='flex '>
                        <p className='text-white text-base font-semibold'>Job Posted: </p>
                        <p className='text-white text-base font-semibold'>  6 days ago</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Applicants: </p>
                        <p className='text-white text-base font-semibold'>  469</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Views: </p>
                        <p className='text-white text-base font-semibold'>  902</p>
                    </div>
                </div> */}


                {/* <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Description / Summary:
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                        We are looking for highly skilled computer vision engineers who are comfortable with development and optimization of computer vision systems. You will be responsible for solving customer problems using computer vision, you will have to train models, optimize them and create inference solutions which are able to run those models/solutions on scale and edge hardware.</p>


                </div> */}
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Essential Functions:
                    </h3>
                    <ul className='list-disc p-4 text-start'>

                        <li>New Business Development & existing Client relationship management.</li>
                        <li>Prepare technical solutions & proposals.</li>
                        <li>Delivering product demos and presentations.</li>
                        <li>Project coordination & order execution.</li>
                        <li>Billing & Collections.</li>
                    </ul>
                </div>

                <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Required Skills:
                    </h3>
                    <ul className='list-disc text-start p-4'>

                        <li>1 to 10 years of experience in technical sales.</li>
                        <li>Experience in IP/CCTV Video Security domain is preferred.</li>
                        <li>Should be open for travel to any part of India or Abroad.</li>
                        <li>Ability to lead a small team.</li>
                    </ul>
                </div>


                {/* <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Methods to Apply
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        1- Lorem Ipsum is simply dummy text                </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        2- Lorem Ipsum is simply dummy text               </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        3- Lorem Ipsum is simply dummy text              </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        4- Lorem Ipsum is simply dummy text              </p>
                </div> */}


                {/* <div className='lg:flex gap-4 pt-4 '>
                    <p className='text-primary text-base font-semibold'>
                        Last Date to Apply :
                    </p>
                    <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
                </div> */}

                <div className='flex space-x-4 pt-6 whitespace-nowrap'>
                    <Link to="/career">  <button className=' bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Go Back
                    </button>
                    </Link>
                    <button onClick={()=>setShowModal(true)} className='bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Apply Now
                    </button>
                </div>

            </div>

            {
               showModal && (
                <CareerForm showModalIn={showModal} setShowModal={setShowModal} />
               )
            }


            <ContactFooter />
            <Footer />

        </div>
    )
}

export default TechnicalSalesEngineer