import React, { useRef } from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import Banner from "../components/common/Banner";
import { useTranslation } from "react-i18next";

const WhyI2v = () => {

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/aboutUs.jpg"
        pageTitle="i2V stands for Intelligent Integrated Video. i2V is a technology R&D company focusing on design, development and production of Intelligent Surveillance products."
        title1="WHY i2V ?" />

      <div className="pt-4 pb-12">
        <div className="lg:px-16 px-5">


          <div className="mt-8 grid lg:grid-cols-3 gap-3"

          >
            <motion.div
              ref={ref}
              initial={{ y: isInView ? 80 : 0, opacity: isInView ? 0 : 1 }}
              animate={{ y: isInView ? 0 : 80, opacity: isInView ? 1 : 0 }}
              transition={{ duration: isInView ? 2 : 0 }}
              className="p-4 bg-black text-white space-y-4 rounded-md flex flex-col justify-between">
              <div className="text-start">

                <p className="py-2 text-justify">
                  <b>1. </b>
                  Perfect combination of VMS, Video Analytics and Command & Control (PSIM) software. Provides end to end solution with smoothness
                  & better control to the customer.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
              <div className="">
                <img src="/assets/images/why-i2v.jpg" alt="i2v" />
              </div>
            </motion.div>
            <motion.div
              ref={ref}
              initial={{ y: isInView ? 100 : 0, opacity: isInView ? 0 : 1 }}
              animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
              transition={{ duration: isInView ? 2 : 0 }}
              className="p-4 bg-black text-white space-y-4 rounded-md flex flex-col justify-between">
              <div className="text-start">


                <p className="py-2 text-justify">
                  <b>2. </b>
                  Hardware performance in VMS and VA is very
                  optimal due to internal technology used.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
              <div className="">
                <img src="/assets/images/servo.jpg" alt="i2v" />
              </div>
            </motion.div>
            <motion.div
              ref={ref}
              initial={{ y: isInView ? 100 : 0, opacity: isInView ? 0 : 1 }}
              animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
              transition={{ duration: isInView ? 2 : 0 }}
              className="p-4 bg-black text-white space-y-4 rounded-md flex flex-col justify-between">
              <div className="text-start">

                <p className="py-2 text-justify">
                  <b>3. </b>
                  Simplicity & User-Friendly Interface for
                  any operator to understand and operate.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
              <div className="">
                <img src="/assets/images/Security.jpg" alt="i2v" />
              </div>
            </motion.div>


          </div>
          {/* <div className="py-8">

            <p className="text-justify">
              {" "}
              {t("i2V is a renowned surveillance software technology company in India that believes in providing intelligent (logical and reasonable) and integrated (complete) solutions. We provide not only a video management system but also advanced video analytics and integration with different devices to our clients. Over the years, due to high investment in R&D and a strong team, we have developed one of the best video management systems and analytics among all the competitors.")}
            </p>
          </div> */}
        </div>
      </div>
      <ContactFooter />
      <Footer />
    </>
  );
};

export default WhyI2v;
