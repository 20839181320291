import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const ObjectRemovalDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Select object(s) to be detected.",
        "Users can select any number of objects to be watched by the Analytic.",
        "Define parameters for example zone and missing duration.",
        "The system learns and identifies temporary background changes to avoid false alarms.",
        "Very useful for 24x7 round-the-clock monitoring of valuables in Museums.",
        "Can be used of protecting sensitive objects put for a showcase like gold, ornaments, etc.",
        "Can be used for Equipment monitoring of computers, servers, and fire extinguishers in sensitive areas.",
        "Can be used for the protection of assets in banks and other financial locations.",
        "Useful for eye watch of items put on display during product exhibitions.",
    ]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/ObjectRemovalDetectionBanner.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Object"
                title1="Removal Detection"
                pos="center"
            />
            <SubPagesIntro
                firstTitle="Object"
                secondTitle="Removal Detection"
                peraOne="This is a way of protecting valuables without any human monitoring. Users can select the objects which need to be watched by the camera. Whenever an object is found missing, the system will generate alarms."
                peraTwo="Your camera will act as a virtual guard for the valuable objects. With this analytic 24x7 monitoring of the valuable objects is possible. Users can also configure the time after which the alarm should be raised once the system detects a missing object."
                imgSrc="/assets/images/products/object.jpg"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}

            />

            {/* <div className=' lg:px-[4rem] px-5 pb-10'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Very simple & user friendly graphical interface with minimal mouse clicks.</li>
                            <li>Effective for 24x7 round the clock monitoring with even minimum illumination.</li>
                            <li>User can select any number of objects to be watched by the Analytic.</li>
                            <li>Very good performance even for watching multiple objects in the single video.</li>
                            <li>Well suited to Indian Requirements.</li>
                            <li>Very useful for round the clock monitoring of valuables in Museums.</li>
                            <li>Can be used of protecting the sensitive objects put for show case like gold, ornaments, etc.</li>
                            <li>Can be used for Equipment monitoring of computers, servers in sensitive server rooms.</li>
                            <li>Can be used for protection of assets in banks and other financial locations.</li>
                            <li>Useful for eye watch of items put for display during product exhibitions.</li>
                        </motion.ul>
                    </div>

                </div>

            </div> */}
            <Footer />
        </>
    )
}

export default ObjectRemovalDetection