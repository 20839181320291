import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const Anpr = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <Navbar />

      <Banner
        img="/assets/images/banner/ANPR-Two.jpg"
        pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
        title="Automatic"
        title1=" Number Plate Recognition /"
        title2="License"
        title3="Plate Recognition"
        pos="bottom"
      />

      <div className="lg:px-[4rem] px-5 md:pt-8 pt-4">
        <motion.div
          className="grid md:grid-cols-12 md:gap-8 container-default pb-12 items-start"
          ref={ref}
          initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
          animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
          transition={{ duration: isInView ? 1 : 0 }}
        >
          <div className="col-span-6 flex flex-col space-y-3 z-10">
            <h3 className="text-[26px] text-black font-bold text-start w-full">
              <span className="text-primary"> Automatic </span> Number Plate
              Recognition /
            </h3>
            <h3 className="text-[26px] text-black font-bold text-start w-full">
              <span className="text-primary"> License </span> Plate Recognition
            </h3>

            <div className="h-1 w-full bg-primary"></div>
            <p className="text-black text-base leading-relaxed text-justify w-[95%]">
            i2V’s Automatic Number Plate Recognition (ANPR) / License Plate Recognition (LPR) is targeted at the masses for full proof ANPR Solution for Smart/Safe Cities, Vehicle Management Systems in Malls, Parking Lots Entry &amp; Exit Points &amp; Residential Entry/Exit Gates for Additional Layer of Security.
            </p>

            {/* image mobile start */}
            <div className="md:hidden block">
              <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
                <img src="/assets/images/products/anpr.png" alt="i2v" />
              </div>
            </div>
            {/* image mobile end */}

            <div className="bg-black lg:px-8 px-2 rounded">
              <div className="py-8 ">
                <h1 className="lg:text-xl text-base text-white text-left tracking-tighter font-bold">
                  <span className="bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide">
                    {" "}
                    Highlights :{" "}
                  </span>
                </h1>
                <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                <ul className="list-disc text-white text-justify p-6 space-y-2">
                  <li>
                  Vehicle Plate detection based on real-time 24x7 video processing.
                  </li>

                  <li>
                  Detect single-row, double-row, multi-row, square and rectangular plates.
                  </li>
                  <li>
                  Compatible with all ONVIF Standard IP Cameras or with cameras with RTSP support.
                  </li>
                  <li>
                  OCR Module: Advanced OCR (Optical Character Recognition) Capabilities to detect and recognize the License Plate Characters (English alpha-numeric characters in standard fonts).
                  </li>
                  <li>
                  Video Recording and snapshot of each vehicle or violated vehicle. 
                  </li>
                  <li>
                  Vehicle Make/logo detection Module: Able to identify the make of the vehicle coming into the field of view of the camera with good accuracy.
                  </li>
                  <li>
                  Vehicle Classification module: ITMS/ITS module can classify the vehicle into HCV, LCV, Cars and Two Wheelers, etc.
                  </li>
                  <li>
                  It can be integrated with multiple cameras or sensors for different use cases, for example, driver image capture, red-light or speed violation, radar, or boom-barrier system.
                  </li>
                  <li>
                  Generate suitable MIS reports that will provide meaningful data to concerned authorities. These reports should include:
                    <ul className="ml-4 list-disc mt-2">
                      <li className="">
                      Report of vehicle flow at each of the installed locations for the Last Day, Last Week, and Last Month.
                      </li>
                      <li>
                      Report of vehicles in the detected categories at each of the installed locations for the Last Day, Last Week, and Last Month.
                      </li>
                      
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-span-6 w-full md:flex flex-col hidden md:justify-end z-10 md:pt-0 pt-4">
            <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
              <img src="/assets/images/products/anpr.png" alt="i2v" />
            </div>
          </div>
        </motion.div>
      </div>

      <Footer />
    </>
  );
};

export default Anpr;
