import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const IndoorPeopleCounting = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = ["Counts entry and exit of people inside premises.",
        "The use of people counting systems can prove very useful in the retail environment to calculate the conversion rate.",
        "Can be used in estimating the better ways to evacuate any building by knowing the total people count in advance.",
        "Helps in getting the routing information as the cameras not only count people but also sense the direction of movement to determine the route people take inside the store.",
        "Useful in knowing the Average dwell time of people inside the building.",
        "Helps in better Staff planning by having people flow statistics."
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/IndoorPeopleCounting.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="People "
                title1="    Counting "
                pos="top"
            />
            <SubPagesIntro
                firstTitle="People"
                secondTitle="Counting"
                peraOne="People Counting Video Analytic modules help in Statistical analysis of Human flow inside a building or premises. The system gives a real-time indication of the number of people/visitors inside a store or building."
                peraTwo="It creates a Virtual line or Virtual zone for counting operations. Once the analytic is applied system starts counting in both directions the Human Heads pass over the virtual line. Users can adjust the position of the line over the entire image."
                imgSrc="/assets/images/products/people.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                data={listItem}
            />


            {/* <div className=' lg:px-[4rem] px-5 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wider'>  Highlights :</span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>The use of people counting systems can prove very useful in the retail environment to calculate the conversion rate.</li>
                            <li>It is useful in counting the people in exhibitions so that to avoid overcrowding.</li>
                            <li>Can be used in estimating the better ways to evacuate any building by knowing the total people count in advance.</li>
                            <li>Helps in getting the routing information as the cameras not only count people but they also sense the direction of movement to determine the route people take inside the store.</li>
                            <li>Useful in knowing the Average dwell time of people inside the building.</li>
                            <li>Helps in better Staff planning by having people flow statistics.</li>

                        </motion.ul>
                    </div>

                </div>

            </div> */}
            <Footer />
        </>
    )
}

export default IndoorPeopleCounting