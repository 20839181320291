import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import VMS from '../components/FirstProduct/VMS'

const FirstProduct = () => {
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/ip-video.png"
                pageTitle="Managing multiple network cameras on LAN/WAN in an intelligent way provides the real
        scalability and flexibility for any video surveillance deployment."
                title="IP"
                title1= " VIDEO MANAGEMENT SOFTWARE ( VMS )"
            />
            <VMS/>
            <ContactFooter />
            <Footer />
        </>
    )
}

export default FirstProduct