import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import Banner from "../components/common/Banner";

const TermsOfUse = () => {
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/termsofuse.jpg"
        pageTitle="Terms of Use"
        title1="i2V Terms of Use"
      />
      <div className="pt-4 pb-12">
        <div className="lg:px-16 px-5">
          <div className="mt-8 grid lg:grid-cols-1 gap-3">
            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Terms And Conditions</h2>
                <p className="py-2 text-justify">
                  Please read the following terms and conditions before using our website. These terms and conditions apply to all users of our website and visitors to our website. In these terms, 'we,' 'us,' and "i2V" refers to <a href="http://www.i2vsys.com/" className="underline text-blue-500">http://www.i2vsys.com/</a>, and 'you' refers to any individual using our services or website.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Copyright Statement</h2>
                <p className="py-2 text-justify">
                  ● The content of this website is copyrighted materials exclusively owned by i2V. However, you may get the print of this content for non-commercial use.<br />
                  ● No permission is granted to anyone to use the content of this Site for commercial purpose or to modify it for any other purpose.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Limitation on Use</h2>
                <p className="py-2 text-justify">
                  Individuals and organizations can use our website (<a href="http://www.i2vsys.com/" className="underline text-blue-500">http://www.i2vsys.com/</a>) only to learn about our products and services. The users can contact us through the website and subscribe to our newsletters.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Severability</h2>
                <p className="py-2 text-justify">
                  If any of these terms becomes unenforceable as a whole or partly, the validity of other contract terms will remain unaffected.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Amendments</h2>
                <p className="py-2 text-justify">
                  We reserve the sole right to amend terms and conditions without serving notice to you. The latest version of terms and conditions will supersede previous versions.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Entire Agreement</h2>
                <p className="py-2 text-justify">
                  Terms of Service made in the latest agreement between the users and i2V will supersede and terminate all the prior agreements.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Subscription</h2>
                <p className="py-2 text-justify">
                  By registering or subscribing to our services, the user accepts receiving the subscription email about <a href="http://www.i2vsys.com/" className="underline text-blue-500">http://www.i2vsys.com/</a> service updates or other promotional offers.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ContactFooter />
      <Footer />
    </>
  );
};

export default TermsOfUse;
