import React, { useRef } from 'react'
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const Detail = () => {

    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (

        <>

            <div className="">
                <div className="lg:px-[4rem] px-5  pt-8 pb-4">
                    <div className="grid grid-cols-1  gap-10  lg:gap-10 lg:grid-cols-2 justify-between container-default items-start">
                        <div
                            className="flex flex-col space-y-3 z-10 w-full h-full  bg-no-repeat bg-center bg-cover rounded-sm "
                            style={{ backgroundImage: `url(/assets/images/channel-part.jpg)` }}
                        >
                            <div className='backdrop-blur-xs bg-black/30 h-full p-4'>
                                <div>
                                    <h3 className="text-xl font-semibold text-white">
                                        TECHNOLOGY PARTNERS
                                    </h3>
                                    <div className="h-1 w-full bg-primary" ></div>
                                    <p className="text-white text-base leading-relaxed text-justify w-[95%] py-6">
                                    i2V believes in the philosophy of togetherness. Together, we can build great solutions. We support different types of Technology Integrations. Below is the minimum list of technology partners:

                                    </p>
                                </div>

                                <div className='text-start'>
                                    <p className='text-white text-base font-semibold'> Hardware devices </p>
                                    <div
                                        className="h-[0.15rem] w-36 bg-primary my-2"

                                    ></div>
                                    <ul className='list-disc marker:text-primary pl-3'>
                                        <li className='text-white text-sm py-1'>IP video devices (Network Cameras, Video Servers, PTZ Cameras, Thermal Cameras, Encoders).
                                        </li>
                                        <li className='text-white text-sm py-1'> NVR, DVR.</li>
                                        <li className='text-white text-sm py-1'>Access control devices, Fire sensors, Audio/PA Systems, Fencing Systems, Magnetic sensors, Underground sensors, LiDAR, RADAR, PIR sensors & Optical Fiber sensors.
                                        </li>
                                        <li className='text-white text-sm py-1'>Edge devices for AI.
                                        </li>
                                        <li className='text-white text-sm py-1'>Cloud service providers.</li>

                                    </ul>
                                </div>


                            </div>




                        </div>




                        {/* <div
                            className="w-full   bg-no-repeat   bg-center bg-cover rounded-sm "
                            style={{ backgroundImage: `url(/assets/images/channel-part.jpg)` }}
                        >
                            <div className='backdrop-blur-xs bg-black/30 '>
                                <div className='px-4 py-4 space-y-3'>
                                    <div>
                                        <h2 className="text-xl font-semibold text-white ">APPLY FOR PARTNERSHIP</h2>
                                        <div className="h-1 w-full bg-primary" ></div>
                                    </div>
                                    <p className="text-white text-base leading-relaxed text-justify w-[95%] py-4 ">i2V sells its products exclusively through our Certified Channel Partners. To become a Certified Channel Partner, submit your details below.</p>
                                    <p className="text-base text-white text-justify "> Partnership opportunities are evaluated on an individual basis. i2V will respond to your request and guide you through the steps of becoming a partner</p>
                                    <Link to="/contact-us"> <button className="flex gap-3 group bg-black border border-white hover:bg-white lg:px-16 px-6 py-1 cursor-pointer mx-auto mt-2">
                                        <p className="text-white group-hover:text-black text-base ">CLICK HERE TO APPLY</p>
                                    </button></Link>

                                </div>

                            </div>


                        </div> */}
                        <div
                            className="w-full h-full  bg-no-repeat text-white  bg-center bg-cover rounded-sm "
                            style={{ backgroundImage: `url(/assets/images/tach-bg.jpg)` }}
                        >
                            <div className='backdrop-blur-xs'>
                                <div className=' py-8  flex flex-col justify-between space-y-12'>
                                    <h2 className='text-3xl font-semibold  rounded tracking-wide'>CONTACT US FOR TECHNOLOGICAL PARTNERSHIP</h2>
                                    <Link to="/contact-us"> <button className="flex gap-3 group bg-black  border border-white hover:bg-primary lg:px-16 px-6 py-1 cursor-pointer mx-auto mt-2">
                                        <p className="text-white text-base ">CLICK HERE TO APPLY</p>
                                    </button></Link>
                                </div>

                            </div>


                        </div>
                        {/* <div className='px-5 md:py-20'>
                            <h2 className='text-3xl font-semibold  rounded tracking-wide'>CONTACT US FOR TECHNOLOGICAL PARTNERSHIP</h2>
                            <Link to="/contact-us"> <button className="flex gap-3 group bg-black  border border-white hover:bg-primary lg:px-16 px-6 py-1 cursor-pointer mx-auto mt-2">
                                <p className="text-white text-base ">CLICK HERE TO APPLY</p>
                            </button></Link>
                        </div> */}
                    </div>

                </div>
            </div>






        </>

    )
}

export default Detail