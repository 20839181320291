import React from 'react'
import Footer from '../components/common/Footer'
import Navbar from '../components/common/Navbar'
import BannerHome from '../components/Home.js/BannerHome'
import ContactFooter from '../components/Home.js/ContactFooter'
import Innovate from '../components/Home.js/Innovate'
import OurProducts from '../components/Home.js/OurProducts'
import VideoService from '../components/Home.js/VideoService'


const Home = () => {
  return (
    <>
    <Navbar bgcolor />
    <BannerHome/>
    <OurProducts/>
    <VideoService/>
    <Innovate/>
    <ContactFooter/>
    <Footer/>
    
    </>
  )
}

export default Home