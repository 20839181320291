import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const CameraTamperingDetection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const listItem = [
    "Works smoothly in all-weather situations including day and night.",
    "The system can recognize brief background changes and learn from them to prevent false alarms.",
    "Compatible with both IP and analog video surveillance cameras. Works in real-time.",
    "i2V's tampering solutions & alert types:",
  ];
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/banner/CameraTamperingDetection.jpg"
        pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
        title="Camera "
        title1="  Tampering Detection"
        pos="center"
      />
      {/* <SubPagesIntro
                firstTitle="Camera"
                secondTitle="Tampering Detection"
                peraOne="This analytics generates an alarm whenever camera tampered in scenarios for example moved,
                fully/partially covered, severely defocused, paint sprayed."
                imgSrc="/assets/images/products/camera.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // underOne={listItem[6]}
                // underTwo={listItem[7]}
                // underThree={listItem[8]}
                // underFour={listItem[9]}
                // underFive={listItem[10]}
                data={listItem}

            /> */}

      <div className="lg:px-[4rem] px-5 md:pt-8 pt-4">
        <motion.div
          className="grid md:grid-cols-12 md:gap-8 container-default pb-12 items-start"
          ref={ref}
          initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
          animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
          transition={{ duration: isInView ? 1 : 0 }}
        >
          <div className="col-span-6 flex flex-col space-y-3 z-10">
            <h3 className="text-[26px] text-black font-bold text-start w-full">
              <span className="text-primary"> Camera </span> Tampering Detection
            </h3>

            <div className="h-1 w-full bg-primary"></div>
            <p className="text-black text-base leading-relaxed text-justify w-[95%]">
            This analytics generates an alarm whenever the camera has tampered in scenarios for example moved, fully/partially covered, severely defocused, or paint sprayed.
            </p>

            {/* image mobile start */}
            <div className="md:hidden block">
              <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
                <img src="/assets/images/products/camera.png" alt="i2v" />
              </div>
            </div>
            {/* image mobile end */}

            <div className="bg-black lg:px-8 px-2 rounded">
              <div className="py-8 ">
                <h1 className="lg:text-xl text-base text-white text-left tracking-tighter font-bold">
                  <span className="bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide">
                    {" "}
                    Highlights :{" "}
                  </span>
                </h1>
                <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                <ul className="list-disc text-white text-justify p-6 space-y-2">
                  <li>
                    Works smoothly in all-weather situations including day and
                    night.
                  </li>

                  <li>
                    System is able to recognize brief background changes and
                    learn from them to prevent false alarms.
                  </li>
                  <li>
                    Compatible with both IP and analog video surveillance
                    cameras. Works in real time.
                  </li>
                  <li>
                    i2V&#39;s tampering solutions &amp; alert types:
                    <ul className="ml-4 list-disc mt-2">
                      <li className="">If someone cuts the wire of the camera → Camera signal lost</li>
                      <li>If someone damages the camera → Camera signal lost</li>
                      <li>If someone sprays on the camera or tries to cover the camera lens → Camera lens covered</li>
                   <li>If someone turns the camera lens to point in a different direction → Camera scene changed</li>
                    <li>If someone flashed the torch light on the camera lens → camera too dark and too bright</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-span-6 w-full md:flex flex-col hidden md:justify-end z-10 md:pt-0 pt-4">
            <div className="w-full  border border-gray-500 rounded bg-black p-2 flex flex-col space-y-5  ">
              <img src="/assets/images/products/camera.png" alt="i2v" />
            </div>
          </div>
        </motion.div>

        {/* <ul>
                    {
                        data.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))
                    }
                </ul> */}
      </div>

      <Footer />
    </>
  );
};

export default CameraTamperingDetection;
