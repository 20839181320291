import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../components/common/Banner'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'
import ContactFooter from '../../components/Home.js/ContactFooter'
import CareerForm from './CareerForm'


const SoftwareSupportProfessional = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/singlejob.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title="I2V"
                title1="PROVIDES MOST EXICTING TITLES  FOR JOB "
            />
            {/* <DetailPage/> */}



            <div className='lg:px-[4rem] px-5  lg:py-8 py-10 '>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        JOB ID :
                    </p>
                    <p className='text-black font-semibold'>i2V/ASE-S/06</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Title :
                    </p>
                    <p className='text-black font-semibold'> Software Support Professional</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Job Type :
                    </p>
                    <p className='text-black font-semibold'> Full Time</p>
                </div>

                <div className='flex gap-4 pt-3 whitespace-nowrap text-xl'>
                    <p className='text-primary font-semibold'>
                        Job Location :
                    </p>
                    <p className='text-black font-semibold'> Gurgaon </p>
                </div>

                

                {/* <div className='lg:flex gap-4 bg-black  lg:w-[60%] p-4 mt-8 md:divide-x-2'>
                    <div className='flex '>
                        <p className='text-white text-base font-semibold'>Job Posted: </p>
                        <p className='text-white text-base font-semibold'>  6 days ago</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Applicants: </p>
                        <p className='text-white text-base font-semibold'>  469</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Views: </p>
                        <p className='text-white text-base font-semibold'>  902</p>
                    </div>
                </div> */}


                {/* <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Description / Summary:
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                        We are looking for highly skilled computer vision engineers who are comfortable with development and optimization of computer vision systems. You will be responsible for solving customer problems using computer vision, you will have to train models, optimize them and create inference solutions which are able to run those models/solutions on scale and edge hardware.</p>


                </div> */}
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                    Description / Summary
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">

                        We are looking for a Technical Support Engineer to provide enterprise-level assistance to our customers. You will diagnose and troubleshoot software and hardware problems and help our customers install applications and programs.
                        
                    </p>
                </div>
               
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Roles & Responsibilities:
                    </h3>
                    <ul className='list-disc p-4 text-start'>

                        <li>Providing software application support under the supervision of the Senior Engineer.</li>
                        <li>Identify the need for new software products or enhancements to existing products.</li>
                        <li>Engage appropriate internal resources to resolve technical issues and update our customers on progress toward resolution.</li>
                        <li>Periodically review software support logs; track performance and identify trends, anomalies, and opportunities for improvement.</li>
                        <li>Provide Product knowledge & front-end support to clients and colleagues in other departments.</li>
                        <li>Establishing the root causes of application errors, and escalating serious concerns to the Senior Engineers.</li>
                        <li>Following standard procedures for proper escalation of unresolved issues to the appropriate internal teams.</li>
                        <li>Talk clients through a series of actions, either via phone, email or chat, until they’ve solved a technical issue.</li>
                        <li>Research and identify solutions to software and hardware issues.</li>
                        <li>Diagnose and troubleshoot technical issues, including account setup and network configuration.</li>
                        <li>Ask customers targeted questions to quickly understand the root of the problem.</li>
                        <li>Track computer system or software issues through to resolution, within agreed time limits.</li>
                        <li>Refer to internal database or external resources to provide accurate tech solutions.</li>
                        <li>Ensure all issues are properly logged.</li>
                        <li>Prioritize and manage several open issues at one time.</li>
                        <li>Follow up with clients to ensure their IT systems are fully functional after troubleshooting.</li>
                        <li>Maintain jovial relationships with clients.</li>
                        <li>Site Visit for Demo, POC, Installation, presentation and training purposes.</li>
                    
                    
                    </ul>
                </div>





                <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Requirements and Skills:
                    </h3>
                    <ul className='list-disc text-start p-4'>

                        <li>Basic or advanced hands-on of Windows Server and Client Operating Systems.</li>
                        <li>Knowledge of #Linux Operating Systems.</li>
                        <li>Knowledge of Storage Systems & Protocols.</li>
                        <li>Knowledge of Networking and Devices like #Switch, #Router, #Hub, #Modem, #WirelessDevices.</li>
                        <li>Knowledge of #TCP/ #IP, #OSIlayer & networking terminologies.</li>
                        <li>Basic knowledge of Networking ports and Services #DHCP, #HTTP, #HTTPS, #SSL, #TLS, #RTSP, #TCP, #UDP, #RDP, #LACP, #TELNET, #TRACERT, #NETSTAT, #ARP.</li>
                        <li>Basic Knowledge Wireshark, Cisco Packet tracer, Putty etc.</li>
                        <li>Excellent problem-solving and communication skills.</li>
                        <li>Ability to provide step-by-step technical help, both written and verbal.</li>
                        <li>Certification in Microsoft, Linux, or Cisco is advantageous.</li>
                        <li>Ability to test Web based as well as Desktop based applications.</li>
                        <li>Ability to design logical data flow diagrams using advanced tools.</li>
                        <li>Experience in Surveillance domain will be advantageous.</li>
                         
                    </ul>
                </div>


                {/* <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Methods to Apply
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        1- Lorem Ipsum is simply dummy text                </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        2- Lorem Ipsum is simply dummy text               </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        3- Lorem Ipsum is simply dummy text              </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        4- Lorem Ipsum is simply dummy text              </p>
                </div> */}


                {/* <div className='lg:flex gap-4 pt-4 '>
                    <p className='text-primary text-base font-semibold'>
                        Last Date to Apply :
                    </p>
                    <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
                </div> */}

                <div className='flex space-x-4 pt-6 whitespace-nowrap'>
                    <Link to="/career">  <button className=' bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Go Back
                    </button>
                    </Link>
                    <button onClick={()=>setShowModal(true)} className='bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Apply Now
                    </button>
                </div>

            </div>

            {
               showModal && (
                <CareerForm showModalIn={showModal} setShowModal={setShowModal} />
               )
            }


            <ContactFooter />
            <Footer />

        </div>
    )
}

export default SoftwareSupportProfessional