import React from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const Banner = ({ img, pageTitle, title, des, title1, pos, boldText,title2,title3 }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    const { t } = useTranslation();
    return (
        <div className="pt-[70px]">
            <div
                className="w-full lg:h-[60vh] h-[40vh] bg-no-repeat bg-center bg-cover rounded-sm"
                style={{ backgroundImage: `url(${img})`, backgroundPosition: `${pos}` }}
            >
                <div className="w-full lg:h-[60vh] h-[40vh] bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="lg:space-y-6">
                        <motion.div
                            ref={ref}
                            initial={{ x: isInView ? 0 : -50, opacity: isInView ? 0 : 1 }}
                            animate={{ x: isInView ? 0 : -50, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                            className=" w-full">
                            <h1 className="space-y-2 fontfamily text-center uppercase textshadow  text-xl p-3  lg:text-5xl text-white   font-semibold textshadow" style={{ textShadow: "2px 2px 5px black" }}>
                                <span className="text-white"> {t(title)} </span> {t(title1)}
                                {
                                    title2?
                                    <p><span className="text-white"> {t(title2)} </span> {t(title3)}</p>
                                    :null
                                }
                            </h1>

                        </motion.div>

                    </div>
                </div>
            </div>

        </div>

    );
};

export default Banner;
