import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import AiBasedVideo from '../components/SecondProduct/AiBasedVideo'
const SecondProduct = () => {
    
    return (
        <>

            <Navbar />
            <Banner
                img="/assets/images/Aibasedvideos.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="AI "
                title1="  BASED VIDEO ANALYTICS "
                pos="bottom"
            />

            <AiBasedVideo />
            <ContactFooter />
            <Footer />

        </>
    )
}

export default SecondProduct