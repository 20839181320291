import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const VMS = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <div className="">
        <div className="lg:px-[4rem] px-5 lg:pt-8 pt-7">
          <motion.div
            className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-start"
            ref={ref}
            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
            transition={{ duration: isInView ? 1 : 0 }}
          >
            <div className="flex flex-col space-y-3 z-10">
              <h3 className="text-[26px] text-black text-start font-bold w-full">
                <span className="text-primary">IP</span> VIDEO MANAGEMENT
                SOFTWARE (VMS)
              </h3>
              <div className="h-1 w-full bg-primary"></div>
              <div className="space-y-4 py-4">
                <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                  
Our Video Management System is designed for large-scale, mission-critical enterprise applications. It can support unlimited cameras, servers, users, and video analytics (based on license). The uniqueness of our VMS is the advanced client-server architecture and integrated i2V video analytics.{" "}
                </p>
                <p className="text-black text-base leading-relaxed text-justify w-[95%]">
                It is built for analog and IP devices with a scalable hybrid video surveillance solution allowing easy 3rd party integration with all the cameras. VMS also offers an event-action-rules framework that can be triggered during various alerts or alarms.{" "}
                </p>
              </div>

              <div className="">
                <div className="bg-black lg:px-8 rounded">
                  <div className="py-4 ">
                    <div className="md:px-0 px-2">
                      <h1 className="lg:text-xl text-base text-white text-left tracking-tighter font-bold">
                        <span className="bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide">
                          Highlights :{" "}
                        </span>
                      </h1>
                      <div className="h-[0.10rem] lg:w-[95%] bg-primary  w-[90%] mt-1"></div>
                    </div>
                    <motion.ul
                      className="list-disc text-white text-justify md:p-4 py-6 pl-6 space-y-1"
                      initial={{
                        y: isInView ? 0 : 200,
                        opacity: isInView ? 0 : 1,
                      }}
                      animate={{
                        y: isInView ? 0 : 100,
                        opacity: isInView ? 1 : 0,
                      }}
                      transition={{ duration: isInView ? 1 : 0 }}
                    >
                      <li>
                      Support for all industry-standard cameras (integrated with 50+ camera make and 5000 models including ONVIF). 
                      </li>
                      <li>Limited NVR and DVR integration is available.</li>
                      <li>
                      Can scale from 100 to 100000 cameras across multiple servers, multiple locations & multiple clients.
                      </li>
                      <li>
                      Hardware Efficiency: Record more no. of cameras with fewer server resources.
                      </li>
                      <li>
                      Hardware/GPU acceleration for displaying high-quality videos with reduced CPU.
                      </li>
                      <li>
                      Redundancy/Failover options for more reliable solutions.
                      </li>
                      <li>Support for android and iOS apps.</li>
                      <li>Option to integrate with cloud storage.</li>
                    </motion.ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:flex md:justify-end z-10">
              <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">
                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                  OUR MORE PRODUCTS
                </h1>
                <div className="h-[0.10rem] w-full bg-white "></div>

                <Link to="/ip-Video">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + IP Video Management Software
                  </div>{" "}
                </Link>
                <Link to="/ai-Based-Video">
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + AI Based Video Analytics
                  </div>
                </Link>
                <Link to="/intelligent-traffic">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Intelligent Traffic Solutions
                  </div>{" "}
                </Link>
                <Link to="/central-monetoring">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Central Monitoring System
                  </div>{" "}
                </Link>
                <Link to="/cloud-surveillance">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Cloud Surveillance
                  </div>{" "}
                </Link>
                <Link to="/integrated-command">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Integrated Command Center
                  </div>
                </Link>
                <Link to="/face-recognition">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Face Recognition
                  </div>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div>
        <div className=" lg:px-0 px-5">
          <h1 className="pt-2 text-3xl text-black lg:text-center text-justify tracking-tighter font-bold">
            {" "}
            <span className="text-primary">i2V </span>VMS is available in the
            following Editions{" "}
          </h1>
          <p className=" lg:w-[45%] w-full text-sm py-2 text-black lg:text-center text-justify tracking-tighter mx-auto">
            Cameras added in i2V Video Management Software can be viewed on
            latest mobile phones and devices
            <span className="text-primary">
              {" "}
              (Android and iPhone/iPad/iOS){" "}
            </span>{" "}
            using i2V mobile app.
          </p>
        </div>

        <div className="py-8">
          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-8  gap-4 lg:px-[4rem] px-5 ">
            <div className="group relative overflow-hidden border-2 shadow rounded min-h-[400px]">
              <div className="absolute top-0 w-full group-hover:hidden block bg-blue-500 text-white">
                <h2 className="font-semibold text-xl p-4">BASIC EDITION</h2>
              </div>
              <img
                src="assets/images/ip-hover-one.jpg"
                alt="BASIC-EDITION"
                className="w-full h-full object-cover rounded"
              />
              <div className=" group translate-y-96 opacity-0 group-hover:opacity-90 group-hover:translate-y-0 group-hover:duration-700  overflow-auto   absolute left-0 top-0 w-full h-full bg-white text-black shadow-lg  shadow-[#00000086]">
                <div className="bg-[#ea1d24] text-white">
                  <h2 className="font-semibold text-xl p-4">BASIC EDITION</h2>
                </div>

                <div className="bg-black text-white text-base px-4 py-6 tracking-wider space-y-6 text-start">
                  <p>
                  i2V VMS Basic Edition is suitable for small and medium installations, ranging from 1-32 cameras. Unique Price and feature combination results in saving time, reducing cost, and optimizing resources.
                  </p>

                  <p>
                    
Open Architecture and Interface virtually allow any camera to be added by specifying its URL. Cameras having standard H264, H265, MJPEG, and MPEG4 codecs can be added using network protocols ONVIF/ RTSP/ HTTP/RTP/ UDP/RTSPS.

                  </p>
                </div>
                <div className="bg-black text-white text-base p-4 text-justify space-y-4">
                  <h2 className="text-lg underline underline-offset-8 decoration-red-600 font-semibold tracking-wide">
                    Highlights
                  </h2>
                  <ul className="list-disc p-4 tracking-wider space-y-6 text-start">
                    <li>
                    Single server support for both live-view and recording. Both View and record up to 32 cameras in a single system.
                    </li>
                    <li>Remote Viewing over WAN using Mobile apps and the web.</li>
                    <li>
                    Triplex mode. Live View, Recording, and Playback simultaneously.
                    </li>

                    <li>Better performance for HD and Megapixel cameras.</li>
                    <li>
                    Supports RTSPS for secured streaming from camera to server and server to clients.
                    </li>
                    <li>Add any camera having MJPEG / MPEG4 / H264/ H265. Protocol ONVIF/ RTSP/ HTTP/RTP/ UDP/RTSPS.</li>
                    <li>
                    Defining settings at individual camera levels.
                    </li>
                    <li>
                    Different settings for Live View and Recordings e.g., Resolution, and frame rate.{" "}
                    </li>
                    <li>
                    Health Check: System health status including recording status, server status & devices status.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="group relative overflow-hidden border-2 shadow rounded min-h-[400px]">
              <div className="absolute top-0 w-full group-hover:hidden block bg-blue-500 text-white">
                <h2 className="font-semibold text-xl p-4 uppercase">
                  Professional Edition
                </h2>
              </div>
              <img
                src="assets/images/ip-hover-two.jpeg"
                alt="Professional-Edition"
                className="w-full h-full object-cover rounded"
              />
              <div className=" group translate-y-96 opacity-0 group-hover:opacity-90 group-hover:translate-y-0 group-hover:duration-700  overflow-auto   absolute left-0 top-0 w-full h-full bg-white text-black shadow-lg  shadow-[#00000086]">
                <div className="bg-[#ea1d24] text-white">
                  <h2 className="font-semibold text-xl p-4 uppercase">
                    Professional Edition
                  </h2>
                </div>

                <div className="bg-black text-white text-base px-4 py-6 tracking-wider space-y-6 text-start">
                  <p>
                  i2V VMS Professional Edition is suitable for small, medium, and large installations ranging from a few to 128 cameras. Built-in Video Analytics framework makes it intelligent, automated, and proactive compared to passive traditional surveillance.
                  </p>
                  <p>
                    i2V&#39;s Real-time video analysis generates alarms that help to reduce operator fatigue and constant monitoring of the surveillance system.
                  </p>
                  <p>
                  Features For example GPU decoding, 4K UHD Camera, and HEVC support make this solution more efficient and robust with optimal hardware resources.
                  </p>
                </div>
                <div className="bg-black text-white text-base p-4 text-justify space-y-4">
                  <h2 className="text-lg underline underline-offset-8 decoration-red-600 font-semibold tracking-wide">
                    Highlights
                  </h2>
                  <ul className="list-disc p-4 tracking-wider space-y-4 text-start">
                    <li>Multiple Server support. Expandable to 128 cameras.</li>
                    <li>
                    Built-in Video Analytics Framework. A separate license is required for analytics.
                    </li>
                    <li>
                    Fourplex mode: Live View, Recording, Video Analytics, and Playback simultaneously.
                    </li>

                    <li>
                    AI-based Video Analytics Supported: Perimeter Trip Wire, Missing Object, Abandoned Object, Tracking, Camera Tampering, Object Counting, Zone Intrusion, Stopped Vehicle, Vehicle Tracking, and many more. 
                    Please check with i2V for the latest video analytics.
                    </li>
                    <li>Video analytics-based recording.</li>
                    <li>
                    E-Maps showing real-time device alarms with an option to view live or recorded video.
                    </li>
                    <li>Cloud storage archiving.</li>
                    <li>
                    Advance actions and rules engine for handling alarms/alerts effectively.
                    </li>
                    <li>All features of the Basic edition.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="group relative overflow-hidden border-2 shadow rounded min-h-[400px]">
              <div className="absolute top-0 w-full group-hover:hidden block bg-blue-500 text-white">
                <h2 className="font-semibold text-xl p-4 uppercase">
                  Enterprise Edition
                </h2>
              </div>
              <img
                src="assets/images/ENTERPRISE-EDITION.jpg"
                alt="Enterprise-Edition"
                className="w-full h-full object-cover rounded"
              />

              <div className=" group translate-y-96 opacity-0 group-hover:opacity-90 group-hover:translate-y-0 group-hover:duration-700  overflow-auto   absolute left-0 top-0 w-full h-full bg-white text-black shadow-lg  shadow-[#00000086]">
                <div className="bg-[#ea1d24] text-white">
                  <h2 className="font-semibold text-xl p-4 uppercase">
                    Enterprise Edition
                  </h2>
                </div>

                <div className="bg-black text-white text-base px-4 py-6 tracking-wider space-y-6 text-start">
                  <p>
                  i2V VMS Enterprise Edition is suitable for medium and large installations. i2V Multi Server Redundancy Architecture can be expanded from medium installation in more than one system to large installations in multiple servers with Management servers and offsite storage archiving.
                  </p>
                  <p>
                  Built-in Video Analytics framework makes this edition intelligent, automated, and proactive compared to passive traditional surveillance.
                  </p>
                  <p>
                  i2V Enterprise Edition acts as a ground framework for next-generation security needs which facilitates the usage of different security  &amp; surveillance devices under one roof.
                   
                  </p>
                </div>
                <div className="bg-black text-white text-base p-4 text-justify space-y-4">
                  <h2 className="text-lg underline underline-offset-8 decoration-red-600 font-semibold tracking-wide">
                    Highlights
                  </h2>
                  <ul className="list-disc p-4 tracking-wider space-y-4 text-start">
                    <li>
                    Multiple Server support. Expandable to an Unlimited number of cameras.
                    </li>
                    <li>
                    Redundancy Failover: Master, Slave for both management and recording servers.
                    </li>
                    <li>
                    Third-Party device/applications integration for example Access control, Fire, Radars, Joysticks, Sensors, IO devices, and Modbus.
                    </li>

                    <li>
                    AI-based Video Analytics Supported: Perimeter Trip Wire, Missing Object, Abandoned Object, Tracking, Camera Tampering, Object Counting, Zone Intrusion, Stopped Vehicle, Vehicle Tracking, and many more.
                     Please check with i2V for the latest video analytics.
                    </li>
                    <li>Encrypted Recordings.</li>
                    <li>All features of Professional Edition.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VMS;
