import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import Contact from '../components/contact/Contact'

const ContactUs = () => {
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/contactUs.png"
                title1="CONTACT US"
                pageTitle="“We welcome all the willing minds eager to ask and initiate a new beginning with innovative video-based surveillance software.”"
            />
            <Contact />
            <ContactFooter />
            <Footer />


        </div>
    )
}

export default ContactUs