import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../components/common/Banner'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'
import ContactFooter from '../../components/Home.js/ContactFooter'
import CareerForm from './CareerForm'


const SoftwareDeveloper = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/singlejob.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title="I2V"
                title1="PROVIDES MOST EXICTING TITLES  FOR JOB "
            />
            {/* <DetailPage/> */}



            <div className='lg:px-[4rem] px-5  lg:py-8 py-10 '>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        JOB ID :
                    </p>
                    <p className='text-black font-semibold'> i2V/SE/03</p>
                </div>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        Title :
                    </p>
                    <p className='text-black font-semibold lg:pl-0 pl-3'>Software Developer</p>
                </div>

                

                {/* <div className='lg:flex gap-4 bg-black  lg:w-[60%] p-4 mt-8 md:divide-x-2'>
                    <div className='flex '>
                        <p className='text-white text-base font-semibold'>Job Posted: </p>
                        <p className='text-white text-base font-semibold'>  6 days ago</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Applicants: </p>
                        <p className='text-white text-base font-semibold'>  469</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Views: </p>
                        <p className='text-white text-base font-semibold'>  902</p>
                    </div>
                </div> */}


                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Description / Summary
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                    We are looking for passionate Software Engineers to design, and develop software solutions. You will be responsible for developing and designing architecture, ensuring the responsiveness and scaling of applications. You will be required to see out a project from conception to the final product, requiring good organizational skills and attention to detail. What you bring to the table is more than just your technical skills - your unique perspective, ideas, and cultures will help us create better products and services.
                    </p>


                </div>
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Roles & Responsibilities:
                    </h3>
                    <ul className='list-disc p-4 text-start'>

                        <li>Work with development teams and product managers to ideate software solutions.</li>
                        <li>Design and Develop well-functioning software architecture and database design.</li>
                        <li>Write effective APIs.</li>
                        <li>Test software to ensure responsiveness and efficiency.</li>
                        <li>Troubleshoot, debug, and upgrade software.</li>
                        <li>Create security and data protection settings.</li>
                        <li>Build features and applications with responsive design.</li>
                        <li>Improving, adding new features, and maintaining existing software products.</li>
                    </ul>
                </div>

                <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Required Skills:
                    </h3>
                    <ul className='list-disc text-start p-4'>

                        <li>Development experience in C#/Java/C++/Python/Javascript.</li>
                        <li>Good knowledge of basic data structures and algorithms.</li>
                        <li>Good debugging skills.</li>
                        <li>Development experience in .Net framework and Wpf is preferable.</li>
                        <li>Experience in database design.</li>
                        <li>Ability to learn new technologies, frameworks, and programming languages.</li>
                    </ul>
                </div>


                {/* <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Methods to Apply
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        1- Lorem Ipsum is simply dummy text                </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        2- Lorem Ipsum is simply dummy text               </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        3- Lorem Ipsum is simply dummy text              </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        4- Lorem Ipsum is simply dummy text              </p>
                </div> */}


                {/* <div className='lg:flex gap-4 pt-4 '>
                    <p className='text-primary text-base font-semibold'>
                        Last Date to Apply :
                    </p>
                    <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
                </div> */}

                <div className='flex space-x-4 pt-6 whitespace-nowrap'>
                    <Link to="/career">  <button className=' bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Go Back
                    </button>
                    </Link>
                    <button onClick={()=>setShowModal(true)} className='bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Apply Now
                    </button>
                </div>

            </div>

            {
               showModal && (
                <CareerForm showModalIn={showModal} setShowModal={setShowModal} />
               )
            }


            <ContactFooter />
            <Footer />

        </div>
    )
}

export default SoftwareDeveloper