import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import Details from '../components/channelPartner/Details'
import Choose from '../components/channelPartner/Choose'
import GlobalPartner from '../components/channelPartner/GlobalPartner'

const ChannelPartner = () => {
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/ChannelPartner.png"
                pageTitle="“We believe in partnering with people of the same broad interests to take video surveillance to another level and ease human monitoring with AI and Machine Learning.”"
                title1="CHANNEL PARTNER" />
            <Details />
            <Choose />
            {/* <GlobalPartner /> */}
            <ContactFooter />

            
            <Footer />
        </>

    )
}

export default ChannelPartner