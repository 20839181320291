import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SubPages = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    const data = [
        {
            id: 1,
            img: "assets/images/products/crowd.png",
            path: "/crowd-counting-detection",
            title: "Crowd & Counting Detection",
            para: "The Crowd Detection video analytic immediately detects crowd formations and triggers alarms when a specified number of people (capacity) or a specified percentage of people (occupancy) is reached in the selected area.",
        },
        {
            id: 2,
            img: "assets/images/products/queue.png",
            path: "/queue-analysis-length-detection",
            title: "Queue Analysis and Length Detection",
            para: "This video analytics is made for the purpose of effectively managing crowd. It is used to detect Queue length in terms of number of people and heads in Queue or a Crowd.",
        },
        {
            id: 3,
            img: "assets/images/products/noppe.png",
            path: "/no-ppe-kit-detection",
            title: "No PPE Kit Detection",
            para: "Personal Protective Equipment&#39;s (PPEs) are protective gears designed to safeguard the health of professional or employees.",
        },
        {
            id: 4,
            img: "assets/images/products/Intrusion.jpg",
            path: "/perimeter-intrusion-detection",
            title: "Perimeter / Intrusion Detection",
            para: "This is the process of locating a moving object in a prohibited or restricted area. The algorithm analyses the video frames and outputs the location of moving targets within the frame.",
        },
        {
            id: 5,
            img: "assets/images/products/loitering.png",
            path: "/boundary-loitering-detection",
            title: "Boundary Loitering Detection",
            para: "This is a Trespassing Detection Analytic which can detect and tracks the trespassing of humans or other objects in restricted areas.",
        },
        {
            id: 6,
            img: "assets/images/products/people.png",
            path: "/people-counting",
            title: "People Counting",
            para: "People Counting Video Analytic modules helps in Statistical analysis of Human flow inside a building or premises.",
        },
        {
            id: 7,
            img: "assets/images/products/abandonedTwo.png",
            path: "/abandoned-object-detection",
            title: "Abandoned Object Detection",
            para: "Round the clock monitoring of hundreds or thousands of cameras is practically impossible for human beings in the crowded areas for example Airports, Railways, Metro Stations, etc.",
        },
        {
            id: 8,
            img: "assets/images/products/object.jpg",
            path: "/object-removal-detection",
            title: "Object Removal Detection",
            para: "This is the way of protecting valuables without any human monitoring. User can select the objects which need to be watched by the camera.",
        },
        {
            id: 9,
            img: "assets/images/products/camera.png",
            path: "/camera-tampring-detection",
            title: "Camera Tampering Detection",
            para: "This analytics generates an alarm whenever camera tampered in scenarios for example moved,fully/partially covered, severely defocused, paint sprayed.",
        },
        {
            id: 10,
            img: "assets/images/products/armed.png",
            path: "/armed-person-detection",
            title: "Armed Person Detection",
            para: "It Detects weapons from a certain distance using AI and Generates alert when an armed person is detected in the camera field of view.",
        },
        {
            id: 11,
            img: "assets/images/products/fire.png",
            path: "/video-based-firedetection",
            title: "Video Based Fire Detection",
            para: "Video based Fire detection detects fire and smoke in the defined video zone of a surveillance camera.",
        },
        {
            id: 12,
            img: "assets/images/products/human.png",
            path: "/human-pattern-recognition",
            title: "Human Pattern Recognition",
            para: "It detects and recognizes the behavior patterns of people in the field of vision of the camera and raises an alarm if there is any critical situation within the predefined period of time. For example: Action Recognition like Person walking, Violence, Ganging a Women. Alert on women/person in distress raising his/her hand(s) for help, sitting on the road, etc.",
        },
        {
            id: 13,
            img: "assets/images/products/anpr.png",
            path: "/anpr",
            title: "Automatic Number Plate Recognition",
            para: "i2V’s Automatic Number Plate Recognition (ANPR) / License Plate Recognition (LPR) is targeted at the masses for full proof ANPR Solution for Smart/Safe Cities. ",
        },
        {
            id: 14,
            img: "assets/images/products/stopped.png",
            path: "/stopped-vehicle-detection",
            title: "Stopped Vehicle Detection",
            para: "Most of the road accidents are mainly due to stopped or illegally parked vehicles. This analytics is capable of detecting a stopped vehicle in multiple scenarios.",
        },
        {
            id: 15,
            img: "assets/images/products/wrong.png",
            path: "/wrong-way-detection",
            title: "Wrong Way Detection",
            para: "Wrong Way Detection analytic provides an Intelligent Solution for Large Indoor and Outdoor Protection applications.",
        },
        {
            id: 16,
            img: "assets/images/products/speedDetection.png",
            path: "/speed-detection",
            title: "Speed Detection",
            para: "i2V Speed Violation Detection is the state-of-the-art video-based speed violation detection system which determines the vehicular speed by calculating the time and distance between the multiple frames of the vehicle and captures image of the number plate of the violating vehicle.",
        },
        {
            id: 17,
            img: "assets/images/products/nohelmet.png",
            path: "/no-helmet-detection",
            title: "No Helmet Detection",
            para: "Video based No Helmet Detection is mainly targeted for the Intelligent Traffic Management Solutions (ITES/ITMS) for Safe City, Smart City and City Surveillance along with ANPR for Full Proof ITES/ITMS Solution for Curbing No Helmet Violations.",
        },
        {
            id: 18,
            img: "assets/images/products/garbage.png",
            path: "/debris-garbage-detection",
            title: "Debris and Garbage Detection",
            para: "The system detects garbage bins of varying sizes and shapes. It creates an alert when the garbage bin overflows or about to overflow.",
        },


    ];
    return (
        <div className="md:space-y-4"
            ref={ref}
        >
            <div className=' lg:px-0 px-5'>
                <h1 className='  md:pt-10 text-3xl text-black lg:text-center text-justify tracking-tighter font-bold'> <span className='text-primary'>i2V </span> Provides Different Analysis </h1>
                <p className=' lg:w-[45%] w-full text-sm py-2 text-black lg:text-center text-justify tracking-tighter mx-auto'>On each Video Analytic Event detection, i2V VMS can send alerts to appropriate security
                    personnel in the form of Sound alarm, Email, SMS, Phone call, Camera output relay Alarm.</p>
            </div>
            <hr />
            <div className="bg-white dark:bg-gray-900 space-y-6">
                <div className="container md-px-0 pt-10 pb-16 mx-auto md:px-0 px-5">
                    <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                        {
                            data.map((obj, e) => (
                                <motion.div className="lg:flex drop-shadow-2xl hover:bg-gray-200 hover:duration-200"
                                    initial={{ y: isInView ? -40 : 0, opacity: isInView ? 0 : 0 }}
                                    animate={{ y: isInView ? 0 : -40, opacity: isInView ? 1 : 0 }}
                                    transition={{ duration: 1 }}
                                >
                                    <img className="object-cover w-full rounded-lg lg:w-64" src={obj.img} alt="" />
                                    <div className="flex flex-col justify-between md:py-2 py-4 lg:mx-6 text-start space-y-2">
                                        <h2 className="text-xl font-semibold text-gray-800  dark:text-white ">
                                            {obj.title}
                                        </h2>
                                        <p className="text-sm">{obj.para}</p>
                                        <Link to={obj.path} className="font-bold tracking-wide text-center px-10 py-2 rounded  text-white bg-black hover:bg-primary duration-150 k button-animate  text-sm">Know more</Link>

                                        {/* <span className="text-sm text-gray-500 dark:text-gray-300">On: 20 October 2019</span> */}
                                    </div>
                                </motion.div>
                            ))
                        }
                    </div>


                </div>


            </div>

        </div>
    )
}

export default SubPages