import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../components/common/Banner'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'
import ContactFooter from '../../components/Home.js/ContactFooter'
import CareerForm from './CareerForm';

const ComputerVisonEngineer = () => {
    const [showModal, setShowModal] = useState(false);
    
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/singlejob.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title="I2V"
                title1="PROVIDES MOST EXICTING TITLES  FOR JOB "
            />
            {/* <DetailPage/> */}



            <div className='lg:px-[4rem] px-5  lg:py-8 py-10 '>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary font-semibold'>
                        JOB ID :
                    </p>
                    <p className='text-black font-semibold'>i2V/CVE/01</p>
                </div>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary font-semibold'>
                        Title :
                    </p>
                    <p className='text-black font-semibold lg:pl-0 pl-3'>Computer Vison Engineer</p>
                </div>

                {/* <div className='flex gap-4 pt-4 items-center'>
                    <div className='bg-black px-4 py-2 '>
                        <img src='/assets/images/icon1.png' alt='img' />
                    </div>
                    <p className='text-primary text-sm font-semibold'>Rs. 50,000 - 2,00,000 PA.</p>
                </div>
                <div className='flex gap-4 pt-3 items-center'>
                    <div className='bg-black lg:px-5  px-7 lg:py-2 py-2 '>
                        <img src='/assets/images/icon2.png' alt='img' />
                    </div>
                    <p className='text-primary text-sm text-justify tracking-tighter font-semibold'>Pune, Ahmedabad, Gurgaon/Gurugram, Chennai, Bangalore/Bengaluru, Mumbai (All Areas)</p>
                </div> */}

                {/* <div className='lg:flex gap-4 bg-black  lg:w-[60%] p-4 mt-8 md:divide-x-2'>
                    <div className='flex '>
                        <p className='text-white text-base font-semibold'>Job Posted: </p>
                        <p className='text-white text-base font-semibold'>  6 days ago</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Applicants: </p>
                        <p className='text-white text-base font-semibold'>  469</p>
                    </div>
                    <div className='flex lg:pt-0 pt-2 md:px-4'>
                        <p className='text-white text-base font-semibold'> Job Views: </p>
                        <p className='text-white text-base font-semibold'>  902</p>
                    </div>
                </div> */}


                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Description / Summary
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                        We are looking for highly skilled computer vision engineers who are comfortable with development and optimization of computer vision systems. You will be responsible for solving customer problems using computer vision, you will have to train models, optimize them and create inference solutions which are able to run those models/solutions on scale and edge hardware.</p>


                </div>
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Roles & Responsibilities:
                    </h3>
                    <ul className='list-disc p-4 text-start'>
                        <li>You will be responsible for development and optimization of various computer vision solutions which solve customer problems.</li>
                        <li>This includes, but is not limited to Development of object detection, classNameification, tracking, counting, Re-Identification, and other algorithms.</li>
                        <li>Porting of already existing algorithms/pipelines to GPU, FPGA, and other hardware.</li>
                        <li>Optimization of already existing CPU/GPU algorithms/pipelines.</li>
                        <li>Deploying computer vision solutions on the scale.</li>
                    </ul>
                </div>

                <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Required Skills:
                    </h3>
                    <ul className='list-disc text-start p-4'>
                        <li>High proficiency in C, and C++.</li>
                        <li>Strong understanding and competency in image processing, computer vision algorithms, and machine learning (especially deep learning).</li>
                        <li>High competency in OpenCV, and PyTorch.</li>
                        <li>Experience with rapid prototyping with e.g. Python, PyTorch, and OpenCV.</li>
                        <li>Knowledge of OpenVino, and Deepstream.</li>
                        <li>Knowledge of CMake, MLflow, DVC, Docker, Dlib, and GStreamer is preferable.</li>
                        <li>Previous work in video surveillance is a big plus.</li>
                    </ul>
                </div>


                {/* <div className='pt-5'>
                    <h3 className="text-[26px] text-black font-bold text-start w-full">
                        Methods to Apply
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        1- Lorem Ipsum is simply dummy text                </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        2- Lorem Ipsum is simply dummy text               </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        3- Lorem Ipsum is simply dummy text              </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        4- Lorem Ipsum is simply dummy text              </p>
                </div> */}


                {/* <div className='lg:flex gap-4 pt-4 '>
                    <p className='text-primary text-base font-semibold'>
                        Last Date to Apply :
                    </p>
                    <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
                </div> */}

                <div className='flex space-x-4 pt-6 whitespace-nowrap'>
                    <Link to="/career">  <button className=' bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Go Back
                    </button>
                    </Link>
                    <button onClick={()=>setShowModal(true)} className='bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Apply Now
                    </button>
                </div>
            </div>
            {
               showModal && (
                <CareerForm showModalIn={showModal} setShowModal={setShowModal} />
               )
            }

            <ContactFooter />
            <Footer />
        </div>
    )
}

export default ComputerVisonEngineer