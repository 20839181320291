import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const BoundaryLoitheringDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = ["Supports Multiple Intrusion zone under a single camera view.",
        "Supports a Wide variety of Intrusion Zones shapes starting with Simple Rectangles to any sized Polygon.",
        "Define the time limit and path of tracking and subsequent alert.",
        "Direction-based detection with entry or exit of a zone.",
        "Select (Include or exclude) the type of objects, for example, human, vehicle, or animal.",
        "Users can get Zone based Alarms. Set priority and sensitivity of each zone. Different types and levels of alerts for different zones.",
        "Supports the feature of scheduled automatically on/off of the analytics.",
       
    ]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/BoundaryLoiteringDetection.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Boundary "
                title1="    Loitering Detection "
                pos="center"
            />
            <SubPagesIntro
                firstTitle="Boundary"
                secondTitle="Loitering Detection"
                peraOne="This is a Trespassing Detection Analytic that can detect and tracks the trespassing of humans or other objects in restricted areas. This is different from conventional Intrusion detection systems in terms of tracking over the selected zones with minimum or maximum time limit."
                imgSrc="/assets/images/products/loitering.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                data={listItem}
            />


            {/* <div className=' lg:px-[4rem] px-5 md:pt-4 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wider'>  Highlights :</span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Support a combination of Multiple Zones.</li>
                            <li>Allows different Parameter Settings for Each Zone.</li>
                            <li>Zone highlighting when it detects the trespasser.</li>
                            <li>As each Zone is identified with a unique identifier, user can get to know exact zone where trespassing occurred.</li>
                            <li>Global parameter Control over all selected Zones.</li>
                            <li>Automatic Image enhancement Controls for Bad light Scenarios.</li>
                            <li>Supports the feature of scheduled automatic on/off of this analytics module.</li>
                        </motion.ul>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default BoundaryLoitheringDetection