import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const IntegratedCommand = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (

        <>

            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-8 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-start"
                        ref={ref}
                        // style={{
                        //     transform: isInView ? "none" : "translateX(-200px)",
                        //     opacity: isInView ? 1 : 0,
                        //     transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        //   }}
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-2 z-10">
                            <h3 className="text-[25px] text-black font-bold text-start w-full">
                                <span className="text-primary">Integrated </span>  Command & Control Center (ICCC) /
                                <p><span className="text-primary">Physical </span>   Security Information Management (PSIM)</p>
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pt-2">
                            i2V Integrated Command & Control Center / PSIM Platform is an open architecture software that can integrate with most IP & Analog cameras, DVRs, NVRs, encoders, third-party devices, Applications (Dial -100), ANPR, sensors, PIDS, Fence systems, Radars, along with latest security technologies available in the market.
                            </p>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pb-2">Through this software, a user can view, control, and receive alerts & notifications from various devices anywhere in the system and take proactive decisions. The systems can connect an unlimited number of surveillance systems and are designed to regulate hundreds or thousands of security cameras and third-party devices or applications.</p>
                            <div className='bg-black lg:px-6 px-3 rounded'>
                                <div className='py-6 '>
                                    <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                                    <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                                    <ul className="list-disc text-white md:p-6 py-6 pl-6 space-y-3 tracking-wide text-justify"

                                    >

                                        <li><b>Integration with GIS and Google Maps:</b> Devices, incidents, and Status can be monitored on customized (customer-provided) GIS maps, Google Maps, or imported files and images.</li>
                                        <li><b>Define SOPs (Standard Operating Procedures):</b> Users can define SOPs for manual and automatic actions. Actions and SOPs can be defined with logical if-then-else conditions.</li>
                                        <li><b>Variety of Actions on events and incidents:</b> Actions For example SMS, PHONE CALL, EMAIL, SOUND Alarm, PTZ movement, SNMP, Video PoP up, Mobile Phone Push Notifications, etc.</li>
                                        <li><b>Incident Management and Escalation Matrix:</b> All events and alarms can be defined with an escalation matrix. For example- In case of no response from the operator, immediate SMS to higher Authorities.</li>
                                        {/* <li><b>Social Media Integration:</b> Software has the capability to extract automatic or user defined text information from Social Media (Twitter, Facebook, etc.) relevant for Command and Control.</li> */}
                                        <li><b>Immediate Video Verification of Alarm:</b> An operator can have a better video view of the breach on each alarm or event and choose a proper response mechanism by integrating Alarm System, sensors, and CCTV system.</li>
                                        <li><b>Highly scalable module:</b> It has an architecture that can be expandable for monitoring up to thousands of sites having lakhs of cameras.</li>
                                        <li><b>Hardware Performance:</b> i2V, due to its advanced algorithm, consumes less hardware compared to other software.</li>
                                        <li><b>Open Software:</b> i2V Software is one of the most open software among all of its competitors. It supports most camera brands. The software is also compatible with ONVIF protocol, DVRs, NVRs, encoders and third-party devices like different makes intrusion detection systems, PIDS, fire alarms, or access control.</li>
                                        <li><b>Full CMS functionality with Quick views based on the Grouping of branches, and important cameras:</b> An operator can easily view a set of camera(s), and alarms on different monitors by clicking quick launch combo boxes or selecting keys from the keyboard. User-based Authentication and Authorization.</li>
                                        <li><b>Customization and integration with third-party devices:</b> i2V software platform is fully customizable, it can be integrated with any kind of third-party devices and applications like Dial-100, ANPR, Vehicle databases, fire alarms, access control, motion sensor, perimeter intrusion detection system (PIDS) and many more.</li>
                                        <li><b>Reporting and Other Important System Functionalities:</b>
                                            <ul className="py-1 px-4" style={{ listStyle: "circle" }}>
                                                <li>Automatic and Manual reports generation of system status, incidents, and health status.</li>
                                                <li>Alerts on branch connection failure - Either CCTV, alarm system, or sensor failure.</li>
                                                <li>Automatic logging of operator actions or response mechanism.</li>
                                                <li>GPS Tracking based on coordinates.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10"

                        >
                            <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">

                                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                                    OUR MORE PRODUCTS
                                </h1>
                                <div className="h-[0.10rem] w-full bg-white "></div>

                                <Link to="/ip-Video"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + IP Video Management Software
                                </div></Link>
                                <Link to="/ai-Based-Video"> <div className=" font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + AI Based Video Analytics
                                </div></Link>
                                <Link to="/intelligent-traffic">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Intelligent Traffic Solutions
                                </div></Link>
                                <Link to="/central-monetoring"> <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Central Monitoring System
                                </div></Link>
                                <Link to="/cloud-surveillance"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Cloud Surveillance
                                </div></Link>
                                <Link to="/integrated-command"><div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs    ">
                                    + Integrated Command Center
                                </div></Link>
                                <Link to="/face-recognition">  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                                    + Face Recognition
                                </div></Link>


                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* <div className=' lg:px-[4rem] px-5 pb-12'>
                <div className='bg-black lg:px-8 px-3 rounded'>
                    <div className='py-6 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-3 tracking-wide"
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >

                            <li><b>Integration with GIS and Google Maps:</b> Devices, incidents and Status can be monitored on customized (customer provided) GIS maps, Google Maps or imported files and images.</li>
                            <li><b>Define SOPs (Standard Operating Procedures):</b> User can define SOPs for manual and automatic actions. Actions and SOPs can be defined with logical if-then-else conditions.</li>
                            <li><b>Variety of Actions on events and incident:</b> Actions For example SMS, PHONE CALL, EMAIL, SOUND Alarrm, PTZ movement, SNMP, Video PoP up, Mobile Phone Push Notifications, etc.</li>
                            <li><b>Incident Management and Escalation Matrix:</b> All events and alarms can be defined with escalation matrix. For example- In case of no response from operator, immediate SMS to higher Authorities.</li>
                            <li><b>Social Media Integration:</b> Software has the capability to extract automatic or user defined text information from Social Media (Twitter, Facebook, etc.) relevant for Command and Control.</li>
                            <li><b>Immediate Video Verification of Alarm:</b> An operator can have better video view of the breach on each alarm or event and choose proper response mechanism by integrating Alarm System, sensors and CCTV system.</li>
                            <li><b>Highly scalable module:</b> It has architecture that can be expandable for monitoring up to thousands of sites having lakhs of cameras.</li>
                            <li><b>Hardware Performance:</b> i2V, due to its advanced algorithm, consumes less hardware compare to other software.</li>
                            <li><b>Open Software:</b> i2V Software is one of the most open software among all of its competitors. It supports most of camera brands. The software also compatible with ONVIF protocol, DVRs, NVRs, encoders and third party devices like different makes intrusion detection system, PIDS, fire alarm or access control.</li>
                            <li><b>Full CMS functionality with Quick views based on Grouping of branches, important cameras:</b> An operator can easily view a set of camera(s), alarms on different monitors by clicking quick launch combo boxes or selecting keys from keyboard. User based Authentication and Authorization.</li>
                            <li><b>Customization and integration with third party devices:</b> i2V software platform is fully customizable, it can be integrated with any kind of third party devices and applications like Dial-100, ANPR, Vehicle databases, fire alarm, access control, motion sensor, perimeter intrusion detection system (PIDS) and many more.</li>
                            <li><b>Reporting and Other Important System Functionalities:</b>
                                <ul className="py-1 px-4" style={{ listStyle: "circle" }}>
                                    <li>Automatic and Manual reports generation of system status, incidents and health status.</li>
                                    <li>Alerts on branch connection failure - Either CCTV, alarm system or sensor failure.</li>
                                    <li>Automatic logging of operator actions or response mechanism.</li>
                                    <li>GPS Tracking based on coordinates.</li>
                                </ul>
                            </li>
                        </motion.ul>
                    </div>
                </div>
            </div> */}



        </>
    )
}

export default IntegratedCommand