import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const ArmedPersonDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Detects handguns, pistols, rifles, knives, and swords. New models are being developed to detect more objects with improved accuracy.",
        "It recognizes firearms as soon as they appear in a security camera's field of view, giving first responders time to intervene before shots are discharged.",
        "Prevents gun violence and mass shootings at workplaces, military posts, business campuses, hospitals, churches, stadiums, and shopping centers. Immediately alert the relevant security officers and first responders.",
        "No Hardware required - works with already installed cameras and video management systems to integrate, transforming passive infrastructure into a proactive layer of security.",
        "Eliminate False Alarms - The state-of-the-art AI technology gets rid of false alarms by operating flawlessly in various lighting and environmental conditions.",
        "It can assist organizations in increasing situational awareness and automating their reaction to a shooter situation.",
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/ArmedPersonDetection.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Armed"
                title1=" Person Detection"
                pos="top"
            />
            <SubPagesIntro
                firstTitle="Armed"
                secondTitle="Person Detection"
                peraOne="It Detects weapons from a certain distance using AI and Generates an alert when an armed person is detected in the camera's field of view. Also, Email, sound, and SMS on Alert can be sent to authorized persons."
                imgSrc="/assets/images/products/armed.png"
                // one={listItem[0]}
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]}
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]}
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}
                data={listItem}
            />

            {/* <div className=' lg:px-[4rem] px-5 pb-12'>
                <div className='bg-black lg:px-8 px-3 rounded'>
                    <div className='py-6'>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Prevents gun violence and mass shootings at workplaces, military posts, business campuses, hospitals, churches, stadiums and shopping centers.</li>
                            <li>Preserve rights and privacy while saving lives.</li>
                            <li>It recognizes firearms as soon as they appear in a security camera's field of view, giving first responders time to intervene before shots are discharged.</li>
                            <li>Immediately alert the relevant security officers and first responders.</li>
                            <li>Continuously monitoring cameras day and night to check for the threat of handgun, pistol, or rifle.</li>
                            <li>No Hardware required - works with already installed cameras and video management systems to integrate, transforming passive infrastructure into a proactive layer of security.</li>
                            <li>Eliminate False Alarms - The state-of-the-art AI technology gets rid of false alarms by operating flawlessly in various lighting and environment conditions.</li>
                            <li>It can assist organizations in increasing situational awareness and automating their reaction to a shooter situation.</li>

                        </motion.ul>
                    </div>

                </div>

            </div> */}

            <Footer />
        </>
    )
}

export default ArmedPersonDetection