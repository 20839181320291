import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const NoPPEDetection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const listItem = [
    "Detects & Classifies a Human/Person with PPE within the Field of View of the Cameras within the defined area of interest.",
    "Minimum pixels required for proper detection of PPE.",
    "Detects different types of PPE in different colors. Option available to train new models for new types of PPE.",
  ];
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/banner/noppe.jpg"
        pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
        title="No PPE"
        title1="   Kit Detection"
        pos="top"
      />
      <SubPagesIntro
        firstTitle="No PPE"
        secondTitle="Kit Detection"
        peraOne="Personal Protective Equipment (PPE) is protective gear designed to safeguard the health of professionals or employees. 
        i2V&#39;s AI &amp; Machine Learning based PPE Detection Analytics detects 
                &amp; checks if a professional is wearing Personal Protective Equipment for example Helmet, Face Shield, Goggles, Face Masks, Gloves, Coverall/Gowns, etc."
        peraTwo=" It detects &amp; classifies a Human/Person with PPE within the Field of View of the Cameras within the defined area of Interest. Generates an alert if anybody is found to be violating the PPE requirements."
        imgSrc="/assets/images/products/noppe.png"
        // one={listItem[0]}
        // two={listItem[1]}
        // three={listItem[2]}
        // four={listItem[3]}
        data={listItem}
      />

      {/* <div className=' lg:px-[4rem] px-5 md:pt-4 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Detects & Classifies a Human/Person within the Field of
                                View of the Cameras within the defined area of
                                interest.
                            </li>
                            <li>Option to define a user configurable Safe Distance
                                Threshold Limit.</li>
                            <li>Live Analytics Stream gives, Real-time distance between
                                Moving & Idle Person.</li>
                            <li>Generate Alerts if Set Distance Limit is Violated.</li>

                        </motion.ul>
                    </div>
                </div>
            </div> */}
      <Footer />
    </>
  );
};

export default NoPPEDetection;
