import React from "react";

const BannerHome = () => {

  return (
    <div>
      <video
        autoPlay
        muted
        loop
        className="md:h-screen h-72 w-full md:object-cover object-fill md:mt-0 mt-20"
        src="/assets/video/videoBg.mp4"
      ></video>
    </div>
  );
};

export default BannerHome;
