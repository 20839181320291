import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const VideoBasedFiredetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Detect Fire or smoke in the defined area.",
        "Works on indoor or outdoor cameras.",
        "The accuracy of detection depends on the video feed and the extent (no of pixels) of fire visibility in the video.",
        "Quickly respond to fires and smoke, and prevent fires from starting or spreading.",
        "It can find exact locations of fire or smoke by analyzing brief background changes.",
        "Immediate notifications can be sent via. emails & msg.",
        "Fire and Smoke Detection System work in real time by analyzing the video feed recorded by any surveillance camera."
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/VideoBasedFireDetection.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="VIDEO "
                title1="   BASED FIRE DETECTION "
                pos="bottom"
            />
            <SubPagesIntro
                firstTitle="Video"
                secondTitle="Based Fire Detection"
                peraOne="Video-based Fire detection detects fire and smoke in the defined video zone of a surveillance camera."
                imgSrc="/assets/images/products/fire.png"
                // one={listItem[0]} 
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]} 
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]} 
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}  
                data={listItem}
            />

            {/* <div className=' lg:px-[4rem] px-5 pb-12'>

                <div className='bg-black lg:px-8 px-3 rounded'>
                    <div className='py-6 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'>Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Quickly respond to fires and smoke, and prevent fires from starting or spreading.</li>
                            <li>Promptly inform people in the danger zone so they can leave right away.</li>
                            <li>It can find exact locations of fire or smoke by analyzing brief background changes.</li>
                            <li>Immediate notifications can be sent via. emails & msg.</li>
                            <li>It can contact the closest fire department if required.</li>
                            <li>One advantage of employing video for fire detection is its capacity for service expansive areas.</li>
                            <li>Fire and Smoke Detection System work in real-time by analyzing the video feed recorded by any surveillance camera.</li>


                        </motion.ul>
                    </div>
                </div>
            </div> */}

            <Footer />
        </>
    )
}

export default VideoBasedFiredetection