import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import { motion, useInView } from "framer-motion";
import Banner from "../components/common/Banner";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const Certification = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/certification.jpg"
                pageTitle="The core of trust lies in communication that leads the way towards empowerment of individuals and partners together, and we stand for our values, beliefs, and products."
                title1="Certifications" />

            <div className="lg:px-16 py-4">

                <h2 className="text-start font-semibold text-3xl pt-4">
                    {t("Certifications")}
                </h2>
                <div
                    className="h-1 w-36 bg-[#EA1D24]"
                    ref={ref}
                    style={{
                        transform: isInView ? "none" : "translateX(200px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                    }}
                ></div>
                
                <div className="grid md:grid-cols-3 gap-8 py-6">
                    <div className="space-y-4 text-start">
                        <h2 className="font-semibold">
                        i2V has achieved STQC certification for its Video Management System & Video Analytics
                        </h2>
                        <div className="">
                            <img src="/assets/images/stqc5.png" alt="i2v" />


                        </div>

                        <p className="text-xs text-justify">
                        STQC Certification ensures that systems meet rigorous security standards, protecting against cyber threats and hacking by conducting thorough vulnerability assessments and penetration testing.
                        </p>
                       


                    </div>
                    <div className="space-y-4 text-start">
                        <h2 className="font-semibold">
                         

i2V has successfully achieved CMMI<sup>®</sup> Maturity Level 3 Assessment
                        </h2>
                        <div className="">
                            <img src="/assets/images/cmmi.jpg" alt="i2v" />


                        </div>

                        <p className="text-xs text-justify">
                        i2V's attainment of CMMI Maturity Level 3 signifies its dedication to implementing rigorous, well-defined processes throughout the software development lifecycle. This achievement ensures greater project consistency, effective risk management, and elevated product quality from inception to delivery.
                        </p>
                       


                    </div>
                    
                    <div className="space-y-4 text-start">
                        <h2 className="font-semibold">
                         i2V Video Management System has received <span className="font-extrabold">SIRA</span> - Dubai Certification
                        </h2>
                        <div className="">
                            <img src="/assets/images/sira5.png" alt="i2v" />


                        </div>

                        <p className="text-xs text-justify">
                       Security Industry Regulatory Agency (SIRA) is a government agency that regulate the security industry in the Emirate of Dubai to provide the highest levels of safety and security in Dubai through the implementation of international best practices in security systems.
                        </p>
                       


                    </div>
                    <div className="space-y-4 text-start">
                        <h2 className="font-semibold">
                         i2V Video Management System has been CERT-In certified by government agencies.
                        </h2>
                        <div className="">
                            <img src="/assets/images/business-corporate.jpg" alt="i2v" />


                        </div>

                        <p className="text-xs text-justify">
                        CERT-In is a government organization that reports to the Ministry of Electronics and Information Technology. CERT (Computer Emergency Response Team) is a group of information security experts who are in charge of providing cyber security protection. Furthermore, the team of experts is tasked with detecting and responding to cyber-attacks. Each country has its own CERT. CERT-In is the name of the Indian CERT.
                        </p>
                       


                    </div>

                    <div className="space-y-4 text-start">
                        <h2 className="font-semibold">
                         i2V Video Management System is compliant with ONVIF profiles S, G, T, and M.
                        </h2>
                        <div className="">
                            <img src="/assets/images/onvif1.jpg" alt="i2v" />


                        </div>

                        <p className="text-xs text-justify">
                        i2V Video Management System (VMS) is fully compliant with ONVIF Profiles S, G, T, and M. This ensures seamless integration with other ONVIF-compliant devices, allowing i2V VMS to handle video, audio, metadata, and configuration data efficiently. 
                        </p>
                       


                    </div>
                    <div className="space-y-4 text-start">
                        <h2 className="font-semibold">
                         i2V Face Recognition Software has achieved NIST Certification.
                        </h2>
                        <div className="">
                            <img src="/assets/images/fNIST.jpg" alt="i2v" />


                        </div>

                        <p className="text-xs text-justify">
                       This certification signifies a significant achievement, affirming that i2V's face recognition technology meets rigorous standards for accuracy and reliability. It underscores our commitment to advancing robust and dependable face recognition technologies capable of excelling across diverse datasets and challenging conditions.
                        </p>
                       


                    </div>
                </div>
            </div>
            <ContactFooter />
            <Footer />
        </>
    );
};

export default Certification;
