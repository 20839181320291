import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import Banner from '../../components/common/Banner'
import Footer from '../../components/common/Footer'
import Navbar from '../../components/common/Navbar'
import ContactFooter from '../../components/Home.js/ContactFooter'
import CareerForm from './CareerForm'


const FullStackDeveloper = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/singlejob.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title="I2V"
                title1="PROVIDES MOST EXICTING TITLES  FOR JOB "
            />
            {/* <DetailPage/> */}



            <div className='lg:px-[4rem] px-5  lg:py-8 py-10 '>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        JOB ID :
                    </p>
                    <p className='text-black  font-semibold'>i2V/FSE/02</p>
                </div>

                <div className='flex gap-4 pt-3 text-xl'>
                    <p className='text-primary  font-semibold'>
                        Title :
                    </p>
                    <p className='text-black  font-semibold lg:pl-0 pl-3'>Full Stack Developer</p>
                </div>

                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Description / Summary
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-3">
                    We are looking for highly skilled computer programmers who are comfortable with both front and back-end programming. You are responsible for developing and designing frontend and backend architecture, ensuring the responsiveness and scaling of applications. You will be required to see out a project from conception to the final product, requiring good organizational skills and attention to detail.
                    </p>
                </div>
                <div className='pt-10'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Roles & Responsibilities:
                    </h3>
                    <ul className='list-disc p-4 text-start'>

                        <li>Work with development teams and product managers to ideate software solutions.</li>
                        <li>Design client-side and server-side architecture.</li>
                        <li>Build the front end of applications through appealing visual design.</li>
                        <li>Develop and manage well-functioning backend architecture and database design.</li>
                        <li>Write effective APIs.</li>
                        <li>Test software to ensure responsiveness and efficiency.</li>
                        <li>Troubleshoot, debug, and upgrade software.</li>
                        <li>Create security and data protection settings.</li>
                        <li>Build features and applications with responsive design.</li>
                        <li>Improving, adding new features, and maintaining existing software products.</li>
                    </ul>
                </div>

                <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Required Skills:
                    </h3>
                    <ul className='list-disc text-start p-4'>

                        <li>C#.</li>
                        <li>Asp.NET Core, Entity Framework.</li>
                        <li>PostgreSQL/ MySQL.</li>
                        <li>Angular 5+.</li>
                        <li>Typescript, JavaScript, jQuery.</li>
                        <li>Knowledge of Restful API.</li>
                        <li>Good knowledge of basic data structures and algorithms.</li>
                        <li>Good debugging skills.</li>
                        <li>Experience in database design.</li>
                        <li>Exposure to Micro Frontend, the Micro services-based architecture, and Docker.</li>
                        <li>Knowledge of Message Queuing Systems, Publish/Subscribe models, etc.</li>
                        <li>Knowledge of Optimizing queries and Scaling Database performance.</li>
                        <li>Ability to learn new technologies, frameworks, and programming languages.</li>
                        <li>Should have self-exploration capabilities and an analytical mindset to find solutions to complex problems.</li>
                    </ul>
                </div>


                {/* <div className='pt-5'>
                    <h3 className="text-xl text-black font-bold text-start w-full">
                        Methods to Apply
                    </h3>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        1- Lorem Ipsum is simply dummy text                </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        2- Lorem Ipsum is simply dummy text               </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        3- Lorem Ipsum is simply dummy text              </p>
                    <p className="text-black text-base leading-relaxed text-justify w-[95%] py-2">
                        4- Lorem Ipsum is simply dummy text              </p>
                </div> */}


                {/* <div className='lg:flex gap-4 pt-4 '>
                    <p className='text-primary text-base font-semibold'>
                        Last Date to Apply :
                    </p>
                    <p className='text-primary text-base font-semibold'>DD/MM/YYYY to DD/MM/YYYY</p>
                </div> */}

                <div className='flex space-x-4 pt-6 whitespace-nowrap'>
                    <Link to="/career">  <button className=' bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Go Back
                    </button>
                    </Link>
                    <button onClick={()=>setShowModal(true)} className='bg-black text-white hover:bg-primary md:px-20 px-4 py-2 text-lg rounded-sm'>
                        Apply Now
                    </button>
                </div>

            </div>

            {
               showModal && (
                <CareerForm showModalIn={showModal} setShowModal={setShowModal} />
               )
            }


            <ContactFooter />
            <Footer />

        </div>
    )
}

export default FullStackDeveloper