import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import Banner from "../components/common/Banner";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/policy.jpg"
        pageTitle="Privacy Policy"
        title1="i2V Privacy Policy"
      />
      <div className="pt-4 pb-12">
        <div className="lg:px-16 px-5">
          <div className="mt-8 grid lg:grid-cols-1 gap-3">
            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Overview</h2>
                <p className="py-2 text-justify">
                  Your privacy is important and this Privacy Policy sets out the procedures and policies of i2V (from now on referred to as "we," "our," or "us") regarding the collection, use, and disclosure of Personal Information we receive about you or from you on <a href="http://www.i2vsys.com/" className="underline text-blue-500">http://www.i2vsys.com/</a>.
                </p>
                <p className="py-2 text-justify">
                  By using our website, you agree to be bound by the terms and conditions of this Privacy Policy. By accepting the Privacy Policy, you expressly consent to our collection, use, and disclosure of your personal Information following this Privacy Policy.
                </p>
                <p className="py-2 text-justify">
                  If you disagree, kindly do not access <a href="http://www.i2vsys.com/" className="underline text-blue-500">http://www.i2vsys.com/</a>. This Privacy Policy is incorporated into and subject to the terms of Use.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Collection of Information</h2>
                <p className="py-2 text-justify">
                We are committed to delivering an exceptional customer experience, and to achieve this, we collect information from or about you. If you browse our Site without submitting any personal information, we will still collect certain data related to your visit. This includes your IP address, browser type, operating system, geographic location, date and time of access, pages visited, and if you arrived via another website, the referring website’s address. This information does not personally identify you and is not linked to you individually.
                </p>
                <p className="py-2 text-justify">
                If you create an account, make a transaction, fill out our contact form, or send personal correspondence (such as emails or letters), or if other users or third parties contact us about your activities on our Site, we may collect personally identifiable information about you. This information may include your name, address, email address, phone number, and debit or credit card details. We retain this information as necessary to resolve disputes, provide customer support, and troubleshoot issues, as permitted by law.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Use and Sharing of Information</h2>
                <p className="py-2 text-justify">
                  We know that you care about how your personal Information is used and shared. You agree that any information we receive about you or from you may be used by us (for marketing, and we can send mail to clients directly) or shared by us with our affiliates and other third parties to help process your request; to comply with any law; to help improve our website or the products or services we offer; for research; to better understand our customers' needs; to develop new offerings, and to alert you to new products and services of us in which you may be interested. We may also combine Information you provide us with Information about you that is available to us internally or from other sources to serve you better.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Cookies</h2>
                <p className="py-2 text-justify">
                  "Cookies" are small identifiers sent from a web server and stored on your computer's hard drive, which helps us to recognize you if you revisit our website. Our Site uses cookies to track how you found our Site. To protect your privacy, we do not use cookies to store or transmit personal Information about you on the Internet. You can accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you refuse cookies, certain site features may not function correctly. We do not control the Use of cookies by third parties.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Governing Law and Jurisdiction</h2>
                <p className="py-2 text-justify">
                  The provisions of this Privacy Policy are governed by and construed following the laws of India. You agree to submit to the exclusive jurisdiction of the courts in Delhi, India.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Changes to This Privacy Policy</h2>
                <p className="py-2 text-justify">
                  This Privacy Policy is effective as of February 2023 and will remain in effect except concerning any changes in its provisions in the future. The changes to the conditions of this Privacy Policy will come into effect immediately after being posted on this Site. We may change our Privacy Policy from time to time. If we do, we will update this Privacy Policy on our website, which will bind to you as and when updated.
                </p>
                <p className="py-2 text-justify">
                  Your continued Use of the Service after we post any modifications to the Privacy Policy on the Site will constitute your acknowledgement of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                </p>
                <p className="py-2 text-justify">
                If we make any material changes to this Privacy Policy, we will notify you either via your email address or by posting a prominent notice on the Site.
                </p>
                <p className="h-[1px] bg-gray-900"></p>
              </div>
            </div>

            <div className="p-4 bg-black text-white space-y-4 rounded-md">
              <div className="text-start">
                <h2 className="text-2xl font-bold underline">Grievance Officer</h2>
                <p className="py-2 text-justify">
                Following the Information Technology Act, 2000 (as amended) and rules made thereunder, you may contact the Grievance Officer via email at i2v@i2vsys.com.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ContactFooter />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
