import React from "react";
import { Link } from "react-router-dom";
import Banner from "../components/common/Banner";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import { FcOpenedFolder } from "react-icons/fc";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillFileZip } from "react-icons/ai";

const Resources = () => {
  return (
    <>
      <Navbar />
      <Banner
        img="/assets/images/banner/resources.jpg"
        pageTitle="“We are building advanced solutions to streamline the video-based monitoring with accuracy and less dependence on human observance.”"
        title="Resources"
        
        
      />

      <div className=" lg:px-16 px-5 py-8 md:grid grid-cols-2 gap-8">
        <div className="text-start col-span-1 space-y-4">
          <div>
            <h2 className="text-lg font-semibold bg-black text-white px-1 tracking-wide mr-4">
              Products Datasheet :
            </h2>
            <ul className=" px-4 py-2 space-y-1">
              {/* <li className='font-semibold w-full hover:underline underline-offset-4'>
                            <a href="/assets/files/i2V_Integrated Command_Center.pdf" target="_blank" className='flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white'><FcOpenedFolder size={24} />i2V Integrated Command & Control Center / PSIM</a>

                        </li> */}

              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="/assets/files/i2V_Enterprise VMS(E-VMS)_2023_1.0.pdf"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <FcOpenedFolder size={24} />
                  i2V Video Management Software (VMS)
                </a>
              </li>
              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="/assets/files/i2V_Enterprise VAS_(E-VAS).pdf"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <FcOpenedFolder size={24} />
                  i2V AI Based Video Analytics
                </a>
              </li>
              {/* <li className='font-semibold w-full hover:underline underline-offset-4'>
                            <a href="/assets/files/i2v_FRS_Datasheet_V2.0.pdf" target="_blank" className='flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white'><FcOpenedFolder size={24} />i2v Face Recognition</a>

                        </li> */}
              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="/assets/files/i2V_ITMS_2022.pdf"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <FcOpenedFolder size={24} />
                  i2V Intelligent Traffic Solutions (ITS)
                </a>
              </li>
              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="/assets/files/i2V_CMS.pdf"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <FcOpenedFolder size={24} />
                  i2V Central Monitoring System (CMS)
                </a>
              </li>
              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="/assets/files/i2V_Enterprise FRS Software(E-FRS).pdf"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <FcOpenedFolder size={24} />
                  i2V AI Based Face Recognition
                </a>
              </li>
              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="/assets/files/i2V_Enterprise ANPR Software.pdf"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <FcOpenedFolder size={24} />
                  i2V AI Based ANPR Software
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg font-semibold bg-black text-white px-1 tracking-wide mr-4">
              i2V Video's Link :
            </h2>
            <ul className=" px-4 py-2 space-y-1">
              {/* <li className='font-semibold w-full hover:underline underline-offset-4'>
                            <a href="/assets/files/i2V_Integrated Command_Center.pdf" target="_blank" className='flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white'><FcOpenedFolder size={24} />i2V Integrated Command & Control Center / PSIM</a>

                        </li> */}

              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="https://www.youtube.com/@i2vsystems120"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                  <AiFillYoutube fill="red" size={24} />
                  https://www.youtube.com/@i2vsystems120
                </a>
              </li>
            </ul>
          </div>
 
          <div>
 <h2 className="text-lg font-semibold bg-black text-white px-1 tracking-wide mr-4">
              i2V VMS Pre-Installer Download Link :
            </h2>
            <ul className=" px-4 py-2 space-y-1">
             

              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="https://rb.gy/8r2lxq"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                    <AiFillFileZip size={24} />
                    https://rb.gy/8r2lxq
                </a>
                
              </li>
              
            </ul>
          </div>
          <div>
 <h2 className="text-lg font-semibold bg-black text-white px-1 tracking-wide mr-4">
              i2V VMS Software Download Link :
            </h2>
            <ul className=" px-4 py-2 space-y-1">
             

              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="https://acesse.dev/vPP5r"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                    <AiFillFileZip size={24} />
                    https://acesse.dev/vPP5r
                </a>
                
              </li>
              
            </ul>
          </div>

          <div>
 <h2 className="text-lg font-semibold bg-black text-white px-1 tracking-wide mr-4">
              i2V Video Analytics Software Download Link :
            </h2>
            <ul className=" px-4 py-2 space-y-1">
             

              <li className="font-semibold w-full hover:underline underline-offset-4">
                <a
                  href="https://rb.gy/soessq"
                  target="_blank"
                  className="flex gap-4 p-2 bg-gradient-to-l items-center from-primary to-white"
                >
                    <AiFillFileZip size={24} />
                    https://rb.gy/soessq
                </a>
                
              </li>
              
            </ul>
          </div>

        </div>
        <div className="col-span-1">
          <img
            className="w-full h-72 object-cover rounded"
            src="/assets/images/resource.jpg"
          />
        </div>
      </div>

      <ContactFooter />
      <Footer />
    </>
  );
};

export default Resources;
