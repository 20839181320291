import React, { useRef } from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import Banner from "../components/common/Banner";

const WhyToChoose = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/aboutUs.jpg"
                pageTitle="i2V stands for Intelligent Integrated Video. i2V is a technology R&D company focusing on design, development and production of Intelligent Surveillance products."
                title1="WHY TO CHOOSE" />

            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-12 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-center"
                        ref={ref}

                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 1 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary"> Why </span>  to choose i2V
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <div>
                                <h2 className="font-semibold text-lg text-start py-4">Partnership Highlights:</h2>
                                <ul className="px-4 list-disc space-y-2 text-start">
                                    <li>i2V is designed for utmost scalability and easy usability which makes our Certified Channel Partners easy to install, to manage and to support.</li>

                                    <li>i2V makes it a priority to provide partners with all of the technical support they need, providing training to get started and continued IT assistance in the field.</li>

                                    <li>i2V maintains an online Partner Portal full of valuable resources and has a support center available to answer your questions.</li>

                                    <li>i2V regularly engages in co-marketing campaigns; the success of our partners is important to us.</li>
                                </ul>
                            </div>

                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10">
                            <div className="lg:w-[80%] w-full  border border-gray-500 rounded shadow p-4 flex flex-col space-y-5  ">
                                <img src="/assets/images/whyChooseUs.webp" alt="i2v" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className="space-y-8 md:pt-4 md:pb-20 pb-16">


                <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-16  gap-10 lg:px-[6rem] px-5'>
                    <div className="shadow-lg rounded-md shadow-[#00000086]">
                        <div className=" rounded-md  shadowbox ">
                            <div className="bg-black px-6 py-3 rounded-t-md h-20 flex items-center">
                                <h2 className="text-lg text-left text-white font-semibold">
                                    Support Offered
                                </h2>
                            </div>
                            <div className="bg-white px-6 py-8 md:h-64 h-auto rounded-b-md flex flex-col justify-between">
                                <ul className="list-disc space-y-2 text-start divide-y-2">
                                    <li>i2V offers opportunities for continued business success and account growth in purchases of additional features, system upgrades, advanced integration with other existing systems.</li>
                                    <li className="pt-2">i2V offers free support even during evaluation period of the software for its partners.</li>
                                </ul>

                            </div>

                        </div>
                    </div>
                    <div className="shadow-lg rounded-md shadow-[#00000086]">
                        <div className=" rounded-md  shadowbox ">
                            <div className="bg-black px-6 py-3 rounded-t-md h-20 flex items-center">
                                <h2 className="text-lg text-left text-white font-semibold">
                                    Customised Integration
                                </h2>
                            </div>
                            <div className="bg-white px-6 py-8 md:h-64 h-auto rounded-b-md flex flex-col justify-between">
                                <ul className="list-disc space-y-2 text-start divide-y-2">
                                    <li>i2V is open to integrate new camera models as per your client requirements. We may need camera SDK for tight integration to use any of the camera specific features through i2V VMS.</li>
                                    <li className="pt-2">i2V has open framework for integrating other security and surveillance devices like Access Control Devies, Fire Alarams and similar devices.</li>
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>




            </div>
            <ContactFooter />
            <Footer />
        </>
    );
};

export default WhyToChoose;
