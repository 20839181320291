import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import SubPages from "./SubPages";

const AiBasedVideo = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <div className="">
        <div className="lg:px-[4rem] px-5  pt-7">
          <motion.div
            className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-4 items-center"
            ref={ref}
            // style={{
            //     transform: isInView ? "none" : "translateX(-200px)",
            //     opacity: isInView ? 1 : 0,
            //     transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
            //   }}
            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
            transition={{ duration: isInView ? 1 : 0 }}
          >
            <div className="flex flex-col space-y-3 z-10">
              <h3 className="text-[26px] text-black font-bold text-start w-full">
                <span className="text-primary"> AI </span>BASED VIDEO ANALYTICS
              </h3>
              <div className="h-1 w-full bg-primary"></div>
              <p className="text-black text-base leading-relaxed text-justify w-[95%] pt-6">
              i2V is a renowned Video Surveillance Software Technology Company focusing on the Design and Development of Intelligent Surveillance solutions. Our AI-Based Video Analytics helps the customer do surveillance with minimum human observance.{" "}
              </p>
              <p className="text-black text-base leading-relaxed text-justify w-[95%] pb-6">
              Video Analytics software will provide various alarms & triggers and display alerts on the software platform along with a video stream. With AI, the software can execute many use cases and delivers more accurate results.{" "}
              </p>

              <div className="bg-black lg:px-8 px-2 rounded">
                <div className="py-8 ">
                  <h1 className="lg:text-xl text-base text-white text-left tracking-tighter font-bold">
                    <span className="bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide">
                      Highlights :{" "}
                    </span>
                  </h1>
                  <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                  <motion.ul
                    className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                    initial={{
                      y: isInView ? 0 : 200,
                      opacity: isInView ? 0 : 1,
                    }}
                    animate={{
                      y: isInView ? 0 : 100,
                      opacity: isInView ? 1 : 0,
                    }}
                    transition={{ duration: isInView ? 1 : 0 }}
                  >
                    <li>
                    Analytics is based on AI (Artificial Intelligence), Machine &amp; Deep Learning.
                    </li>
                    <li>
                    Works on any video surveillance cameras IP or analog with DVR.
                    </li>
                    <li>Option to run on Windows &amp; Linux.</li>
                    <li>GPU+CPU or only CPU (limited) options are available.</li>
                    <li>Both Edge-based and Server-based options are available.</li>
                    <li>
                    i2V AI-based Video Analytics are scalable client-server based distributed network architecture, user-friendly interface, based on Open-source platform.
                    </li>
                    <li>
                    Highly localized and customized models for better accuracy.
                    </li>
                    <li>
                    Option to develop customized use cases and data training based on customer requirements.
                    </li>
                    <li>
                    Option to use with i2V VMS or to run VA independently. Easy-to-use API/SDK provided to integrate VA with third-party applications.
                    </li>
                    <li>
                    Fully customizable to cater to future requirements...
                    </li>
                  </motion.ul>
                </div>
              </div>
            </div>
            <div className="w-full md:flex md:justify-end z-10">
              <div className="lg:w-[80%] w-full  border border-gray-500  bg-black lg:p-8 p-4 flex flex-col space-y-5  ">
                <h1 className="  space-y-2 fontfamily  lg:text-xl text-base pt-3   text-white text-left  font-semibold textshadow capitalize">
                  OUR MORE PRODUCTS
                </h1>
                <div className="h-[0.10rem] w-full bg-white "></div>

                <Link to="/ip-Video">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + IP Video Management Software
                  </div>
                </Link>
                <Link to="/ai-Based-Video">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-white bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + AI Based Video Analytics
                  </div>
                </Link>
                <Link to="/intelligent-traffic">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Intelligent Traffic Solutions
                  </div>
                </Link>
                <Link to="/central-monetoring">
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Central Monitoring System
                  </div>
                </Link>
                <Link to="/cloud-surveillance">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Cloud Surveillance
                  </div>{" "}
                </Link>
                <Link to="/integrated-command">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Integrated Command Center
                  </div>{" "}
                </Link>
                <Link to="/face-recognition">
                  {" "}
                  <div className="font-bold tracking-wide w-full lg:px-10 px-3 py-3  text-black hover:text-white bg-white hover:bg-primary text-left button-animate rounded-sm lg:text-sm text-xs">
                    + Face Recognition
                  </div>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <SubPages />
    </>
  );
};

export default AiBasedVideo;
