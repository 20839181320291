import React, { useRef } from "react";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";

const VidsSection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <>
            <div className="">
                <div className="lg:px-16 px-5 py-10 space-y-8">
                    <motion.div
                        id="vehicle"
                        ref={ref}
                        initial={{ y: isInView ? 40 : 0, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 40, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className=" text-justify">
                            <h3 className="text-xl"> Stopped Vehicle Detection </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4 ">
                            Most road accidents occur due to stopped vehicles. The stopped Vehicle Detection analytic is capable of detecting a stopped vehicle in multiple scenarios. Once the system detects the stopped vehicle, it is capable of taking a zoomed snapshot of the vehicle including the vehicle’s License Plate number image.
                            </p>
                            <p >Once the user selects any No-Parking area, the system starts detecting vehicles coming close to it. If any vehicle lowers its speed or tries to stop in the No-Parking zone, then the system will alert the user for illegal halting. Users can also select any number of zones in the video, each zone can have its configurable parameters. It also configures the Time duration for which the vehicle is allowed to halt.</p>
                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/stopped.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Prevents any kind of illegal parking or illegal halting of vehicles on the roads, premises, etc.</li>
                                    <li>Prevents traffic jams in crowded areas because of the illegal halting.</li>

                                    <li>Detects the vehicle which needs assistance.</li>

                                    <li>Illegal halting for a long period can be detected & can alert security for any unusual activity like Bomb or Explosive material inside the vehicle.</li>



                                </ul>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div
                        id="wrong"
                        initial={{ y: isInView ? 40 : 0, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 40, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl"> Wrong Way Detection </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            Vehicle wrong-way detection analytics provides an Intelligent Solution for traffic management by detecting vehicles going in the wrong way on the road. It sends an alarm and video feed to the central server when any vehicle moves in the wrong direction.
                            </p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/wrongTwo.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Supports all types of virtual lines Vertical, Horizontal, and Slanted.</li>
                                    <li>Supports multiple lines with all types of line combinations.</li>

                                    <li>Supports Direction rule-based Perimeter security.</li>

                                    <li>Supports a combination of Connected and Non Connected virtual perimeter lines.</li>
                                    <li>Allows different Setting for Each Perimeter Line.</li>
                                    <li>Each Perimeter Line is identified with a unique identifier, as a result, a user can get the exact perimeter Line where the breach has occurred.</li>
                                    <li>Supports Global parameter Control over all the Virtual Perimeter Lines.</li>
                                    <li>Automatically enhances the Image under Scenarios like Bad light, Fog, etc.</li>
                                    <li>Automatically zooms the image of the breached object.</li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>

                    <div
                        id="pedestrian"
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl">Pedestrian Movement Detection </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            Pedestrian Movement Detection locates a person in a prohibited or restricted area. The algorithm analyses the video frames and outputs the location of moving persons within the frame. With the help of advanced neural network-based architecture system is capable to detect persons roaming on the highway with key precision values.
                            </p>
                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/pedestrian.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li> Supports Multiple Pedestrian monitoring Zone selections under a single Camera view itself.</li>
                                    <li> Direction-based Pedestrian Movement detection with regular shapes.</li>
                                    <li> Supports a Wide variety of Pedestrian Zone shapes starting with Simple Rectangles to any sized Polygon.</li>
                                    <li> Users can define Directional-based Pedestrian movement for Regular Shaped Zones like Square, and Rectangles.</li>
                                    <li> Users can get Zone based Alarms.</li>
                                    <li> Global Parameter Control over the System.</li>
                                    <li> Well-tuned to even Low Frame Rates and Low-Resolution Input.</li>
                                    <li> Minimal False Alarm Rates.</li>
                                    <li> Automatic Image enhancement Controls for Bad light Scenarios.</li>
                                    <li> Well customized to Indian Requirements.</li>
                                    <li> Supports the feature of scheduled automatically on/off.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div
                        id="abandoned"
                        className="p-4 bg-black text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl"> Abandoned Object lying on road </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="py-4">
                            Abandoned Object Detection has a very crucial Video Analytic Algorithm which can fulfill many of the current security requirements. It is practically impossible for humans to manually monitor crowded places, especially Highways, tunnels, Airports, Railway Stations, City Metro Stations, etc. The Algorithm used by this analytic is specially tuned to handle crowded scenes and it aims at detecting suspicious objects, fallen objects & unattended baggage. It adds maximum intelligence to surveillance applications.
                            </p>

                        </div>
                        <div className="md:grid grid-cols-5 gap-4">
                            <div className="col-span-2">
                                <img src="/assets/images/products/abandoned.png" alt="i2v" />
                            </div>
                            <div className="text-justify col-span-3">
                                <h3 className="text-xl"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Very accurate even in low light conditions.</li>
                                    <li>Well-tuned to handle crowded scenarios like Highways, Tunnels, Shopping malls, Airports, Metro Stations, etc.</li>

                                    <li>The system can learn and identify temporary background changes to avoid false alarms.</li>

                                    <li>Pre Event recording feature is available.</li>
                                    <li>Ideal for detecting abandoned baggage in public places.</li>
                                    <li>Useful in detecting Foreign objects on Railway tracks.</li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div
                        id="average"
                        className="p-4 my-8 bg-black  text-white space-y-10 rounded-md flex flex-col justify-between">
                        <div className="text-justify">
                            <h3 className="text-xl"> Average Traffic flow based on Congestion </h3>
                            <p className="h-[2px] bg-primary mt-2"></p>
                            <p className="pt-4 ">
                            When the traffic volume exceeds a limit on the road at a particular time then it is called traffic congestion. Traffic congestion may be classified based on the intensity of traffic volume. Traffic congestion is classified into three categories i.e., Low traffic volume, Medium traffic congestion, and Heavy traffic congestion. Traffic congestion is calculated based on the number of vehicles passing through and the average speed of vehicles at a particular location in a particular interval of time.
                            </p>
                            <p>The average speed of vehicles less than 10km/hr can be considered congested. 500 vehicles passing the road per minute on a two-lane road is considered congested. In case, the data exceeds a particular limit an alarm in form of SMS, E-mail, and a pop-up will be sent to the VID server and respected authority. Traffic congestion limit can be set manually by the user operating from a remote location.
                            </p>
                        </div>
                        <div className="md:grid grid-cols-5 gap-4 items-center">
                            <div className="col-span-2">
                                <img src="/assets/images/products/averageTraffic.png" alt="i2v" />
                            </div>
                            <div className="text-start col-span-3">
                                <h3 className="text-xl"> Highlights : </h3>
                                <p className="h-[2px] bg-primary mt-2"></p>
                                <ul className="p-4 list-disc ">
                                    <li>Counts and classify vehicles in each lane of video view. Lanes can be defined as multiple virtual zones in the video.</li>
                                    <li>Average speed and density calculation.</li>
                                    <li>Peak hours are calculated and necessary steps are taken for smooth traffic flow.</li>
                                    <li>The effectiveness of local traffic congestion forecast can be increased with the use of the traffic congestion verification process.</li>
                                    <li>Works in real-time with both IP and analog video surveillance cameras.</li>
                                    <li>Very accurate even in low light conditions.</li>
                                    <li>Prevention of traffic circumstances that can generate accidents.</li>
                                    <li>It also let us know the maximum amount of traffic that can be handled by a particular highway section.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default VidsSection;
