import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import Detail from '../components/TechnologyPartner/Detail'
import Partner from '../components/TechnologyPartner/Partner'
const TechnologyPartner = () => {
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/TechnologyPartner.png"
                pageTitle="i2V stands for Intelligent Integrated Video. i2V is a technology R&D company focusing on design, development and production of Intelligent Surveillance products."
                title1="TECHNOLOGY PARTNER" />

            <Detail />
            <Partner />
            <ContactFooter />
            <Footer />

        </>
    )
}

export default TechnologyPartner