import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const WrongWayDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Supports all types of zones and virtual lines: Vertical, Horizontal, and Slanted.",
        "Supports Direction rule-based security. Supports a combination of Connected and Non-Connected virtual perimeter lines.",
        "Allows different Setting for Each Perimeter Line.",
        "Each Perimeter Line is identified with a unique identifier, as a result, a user can get the exact perimeter Line where the breach has occurred.",
       
    ]
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/WrongWayDetectionBanner.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="Wrong "
                title1="    Way Detection"
                pos="bottom"
            />
            <SubPagesIntro
                firstTitle="Wrong"
                secondTitle="Way Detection"
                peraOne="Wrong Way Detection Analytics provides an Intelligent Solution for Large Indoor and Outdoor Protection applications. It detects moving objects using intelligent object validation techniques."
                
                imgSrc="/assets/images/products/wrong.png"
                // one={listItem[0]} 
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]} 
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]} 
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}  
                data={listItem}
            />


            {/* <div className=' lg:px-[4rem] px-3 pb-12'>
                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wider'>  Highlights :</span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                            ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Supports all types of virtual lines Vertical,
                                Horizontal and Slanted.</li>
                            <li>Supports multiple lines with all types of line
                                combinations.</li>
                            <li>Supports Direction rule based Perimeter
                                security. Supports a combination of Connected
                                and Non Connected virtual perimeterlines.</li>
                            <li>Allows different Setting for Each Perimeter Line.</li>
                            <li>Each Perimeter Line is identified with an unique
                                identifier, as a result a user can get the exact
                                perimeter Line where the breach has occurred.</li>
                            <li>Supports Global parameter Control over all the
                                Virtual Perimeter Lines.</li>
                            <li>Automatically enhances the Image under
                                Scenarios like Bad light, Fog, etc.</li>
                            <li>Automatically zoomsthe image of the breached
                                object.</li>
                        </motion.ul>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default WrongWayDetection