import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import CentralMonetoring from '../components/FourthProduct/CentralMonetoring'

const FourthProduct = () => {
    return (
        <div>
             <Navbar />
            <Banner
                img="/assets/images/control-management-system.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title=" CENTRAL"
                title1=" MONITORING SYSTEM"
            />
            <CentralMonetoring/>

            <ContactFooter />
            <Footer />
            </div>
    )
}

export default FourthProduct