import React, { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";
import SubPagesIntro from "../../components/SecondProduct/SubPagesIntro";

const StoppedVehicleDetection = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const listItem = [
        "Define zones and the type of vehicles to be detected.",
        "Define illegal or stopped duration to generate an alarm.",
        "Through this system, we can prevent any kind of illegal parking or illegal halting on the roads, premises, etc.",
        "Prevent traffic jams in crowded areas due to illegal halting.",
        "One can detect vehicles on highways that need assistance.",
        "Illegal halting for a long period can be detected & alerted to security for any unusual activity like BOMB or Explosive material in the vehicle."
    ]

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/STOPPEDVEHICLEDETECTION.jpg"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="STOPPED "
                title1=" VEHICLE DETECTION"
                pos="bottom"
            />
            <SubPagesIntro
                firstTitle="Stopped"
                secondTitle="Vehicle Detection"
                peraOne="Most road accidents are mainly due to stopped or illegally parked vehicles. This analytics is capable of detecting a stopped vehicle in multiple scenarios. Once the system detects the stopped vehicle, it is capable of taking a snapshot of the vehicle with the License Plate number."
                peraTwo="It can detect any stopped vehicle on the roads. Once the user selects any No-Parking area, the system starts detecting vehicles coming close to it. If any vehicle lowers its speed or tries to stop in the No-Parking zone, then the system will start alerting the user for illegal halting. Users can select any number of zones in the video and each zone can have its configurable parameters. The time duration for which the vehicle is allowed to halt is also configurable."
                imgSrc="/assets/images/products/stoppedTwo.png"
                // one={listItem[0]} 
                // two={listItem[1]}
                // three={listItem[2]}
                // four={listItem[3]} 
                // five={listItem[4]}
                // six={listItem[5]}
                // seven={listItem[6]} 
                // eight={listItem[7]}
                // nine={listItem[8]}
                // ten={listItem[9]}
                // elevan={listItem[10]}  
                data={listItem}
            />
            

            {/* <div className=' lg:px-[4rem] px-3 pb-12'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md tracking-wide'> Highlights : </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start p-6 space-y-2"
                        ref={ref}
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>By this system, we can prevent any kind of illegal parking or illegal halting on the roads, premises, etc.</li>
                            <li>It would be very useful to prevent traffic jams in crowded areas because of the illegal halting.</li>
                            <li>With this analytic we can also detect the vehicle which needs assistance.</li>
                            <li>Illegal halting for long period of time can be detected & alerted to security for any unusual activity like BOMB or Explosive material in the vehicle.</li>

                        </motion.ul>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default StoppedVehicleDetection