import React from 'react'
import Banner from '../components/common/Banner'
import Navbar from '../components/common/Navbar'
import ContactFooter from '../components/Home.js/ContactFooter'
import Footer from '../components/common/Footer'
import CloudSurveillance from '../components/FifthProduct/CloudSurveillance'

const FifthProduct = () => {
    return (
        <div>

            <Navbar />
            <Banner
                img="/assets/images/IpVideoManagementsysem.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
        intelligence in image processing & computer vision technology."
                title=" CLOUD"
                title1="SURVEILLANCE"
            />
            <CloudSurveillance />
            <ContactFooter />
            <Footer />

        </div>
    )
}

export default FifthProduct