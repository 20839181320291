import React from "react";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import { motion, useInView } from "framer-motion";
import Banner from "../components/common/Banner";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const SuccessStories = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/banner/success-story.jpg"
                pageTitle="The core of trust lies in communication that leads the way towards empowerment of individuals and partners together, and we stand for our values, beliefs, and products."
                title="Success"
                title1="Stories" />

            <div className="my-4 px-5 lg:px-0">
                <div className="lg:px-16">
                    <h2 className="text-start font-semibold text-3xl pt-4">
                        {t("Success Stories")}
                    </h2>
                    <div
                        className="h-1 w-36 bg-[#EA1D24]"
                        ref={ref}
                        style={{
                            transform: isInView ? "none" : "translateX(200px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        }}
                    ></div>
                    <p className="text-black text-[15px] leading-relaxed text-justify py-3">
                        i2v has done more than 1000 installations across hundreds of customers in different domains<b>: </b>
                        Oil & Gas, Smart/Safe Cities, Police & Law Enforcement, Defence, Power & Energy, Industries like - Coal, Cement, Pharma, Chemical, Steel, Auto,
                        Educational, Finance, Hospitality and Retail.
                    </p>
                    <b className="text-start block">Few of Our References:-</b>
                    <div className="grid lg:grid-cols-3 gap-8 py-6">
                        <div className="space-y-4 text-start">
                            <h2 className="font-semibold h-16">
                                i2V ITMS & VMS successfully installed in Himachal Pradesh, Mandi.
                            </h2>
                            <div className="">

                                {/* <div>
                  <iframe src='/assets/video/newsOne.mp4'
                    frameborder='0'
                    controls="controls" type="video/mp4" autoplay="false" preload="none"
                    title='video'
                    className="w-full h-52 rounded"
                  />
                 
                </div> */}
                                <video src='/assets/video/sucessOne.mp4' className="w-full h-52" controls="controls" autoplay muted />
                            </div>


                            <p className="text-xs text-justify pb-4">
                                {t("In order to tackle the Challenges of Managing Traffic & Security in Mandi City, also known to be the 2nd largest economic city and the cultural capital of Himachal Pradesh, Mandi police have taken a few necessary steps to strengthen the traffic laws. Mandi police and i2V Systems Pvt. Ltd. have jointly worked together with the goal of preparing mandi future-ready in terms of surveillance & Security.")}
                            </p>
                            <a href="https://www.linkedin.com/posts/i2v-systems_surveillance-security-nic-activity-7003251127374737408-Tk8t?utm_source=share&utm_medium=member_desktop" className=" px-4 py-1 border-black text-blue-800 text-sm border rounded-full mt-4 hover:bg-primary hover:text-white hover:border-none" >Read more</a>



                        </div>
                        <div className="space-y-4 text-start">
                            <h2 className="font-semibold h-16">
                                i2V Command and control (PSIM) along with VMS successfully installed in Saharanpur Smart city
                            </h2>
                            <div className="">

                                {/* <div>
                  <iframe src='/assets/video/newsOne.mp4'
                    frameborder='0'
                    controls="controls" type="video/mp4" autoplay="false" preload="none"
                    title='video'
                    className="w-full h-52 rounded"
                  />
                </div> */}
                                <video src='/assets/video/successTwo.mp4' className="w-full h-52" controls="controls" autoplay muted />

                            </div>

                            <p className="text-xs text-justify">
                                {t("i2V is delighted to be part of Saharanpur Smart city Ltd. Team i2V worked together with the customer to achieve the highest standard in surveillance software to make the city safe and secure.")}
                            </p>
                            <div className="pb-4">
                                <p className="text-xs text-justify pb-2">i2V offered the following Applications:</p>
                                <ul className="text-xs list-disc px-3">
                                    <li>Enterprise Video Management Software(VMS)</li>
                                    <li>Integrated Command & Control Center(ICCC) Application Platform</li>
                                    <li>Enterprise AI-Based Video Analytics Software(AI-VAS)</li>
                                </ul>
                            </div>
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6998979647199719424" className=" px-4 py-1 border-black text-blue-800 text-sm border rounded-full mt-4 hover:bg-primary hover:text-white hover:border-none" >Read more</a>



                        </div>
                        <div className="space-y-4 text-start">
                            <h2 className="font-semibold h-16">
                                i2V Crowd Management & VMS successfully installed in Haridwar for MahaKumbh festival
                            </h2>
                            <div className="">
                                {/* <img src="/assets/images/events1.png" alt="i2v" /> */}
                                {/* <div>
                  <iframe src='/assets/video/newsOne.mp4'
                    frameborder='0'
                    controls="controls" type="video/mp4" autoplay="false" preload="none"
                    title='video'
                    className="w-full h-52 rounded"
                  />
                </div> */}
                                <video src='/assets/video/successThree.mp4' className="w-full h-52" controls="controls" autoplay muted />

                            </div>

                            <p className="text-xs text-justify pb-4">
                                {t("i2V was excited to participate in the MahaKumbh festival in Haridwar, attended by millions daily. Over a year ago, i2V installed software that received positive feedback from the Haridwar police for its performance. i2V provided solutions like enterprise video management, AI-based video analytics, crowd monitoring, and incident management. The event was under high-tech surveillance, focusing on crowd and traffic management. i2V sees this as an opportunity to showcase its advanced services.")}
                            </p>
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7011305544267231232" className=" px-4 py-1 border-black text-blue-800 text-sm border rounded-full mt-4 hover:bg-primary hover:text-white hover:border-none" >Read more</a>



                        </div>
                    </div>
                </div>



            </div>
            <ContactFooter />
            <Footer />
        </>
    );
};

export default SuccessStories;
