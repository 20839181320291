import * as Yup from "yup";

export const careerSchema = Yup.object({
    name:Yup.string().min(2).max(25).required("please enter your name"),
    phone:Yup.number().min(10).required("enter valid phone number"),
    email:Yup.string().email().required("please enter your email"),
    applied:Yup.string().required("enter position"),
    file:Yup.mixed().required("upload file")
});

export const contactSchema = Yup.object({
    email:Yup.string().email().required("please enter your email"),
    phone:Yup.number().required("enter phone number")
});

export const contactFooterSchema = Yup.object({
    fname:Yup.string().min(5).required("please enter your full name"),

    email:Yup.string().email().required("please enter valid email"),
    phone:Yup.number().required("enter phone number")
});

