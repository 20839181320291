import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Banner from "../../components/common/Banner";

const VideoPerimeterTripwire = () => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    return (
        <>
            <Navbar />
            <Banner
                img="/assets/images/ip-video.png"
                pageTitle="i2V Video Analytics are developed using machine learning, deep learning and artificial
                intelligence in image processing & computer vision technology."
                title="VIDEO"
                title1=" PERIMETER TRIPWIRE"
                pos="bottom"
            />
            <div className="">
                <div className="lg:px-[4rem] px-5 lg:pt-12 pt-7">
                    <motion.div className="grid grid-cols-1  gap-10  lg:gap-0 lg:grid-cols-2   justify-between container-default pb-12 items-center"
                        ref={ref}
                        
                        initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                        animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                        transition={{ duration: isInView ? 1 : 0 }}
                    >
                        <div className="flex flex-col space-y-3 z-10">
                            <h3 className="text-[26px] text-black font-bold text-start w-full">
                                <span className="text-primary"> Video </span>    Perimeter Tripwire
                            </h3>
                            <div
                                className="h-1 w-full bg-primary"

                            ></div>
                            <p className="text-black text-base leading-relaxed text-justify w-[95%] pt-6">
                                This analytic aims at providing an Intelligent Solution for Outdoor, Large Indoor Perimeter Protection applications. It involves detecting the possible moving objects using intelligent object validation techniques. Our system have minimal false alarm rate. System captures the zoomed image of the object causing the Perimeter Breach and raises the appropriate alarm to the security staff or designated individuals. It will also store the time duration of pre and post event information of Perimeter Breach.
                            </p>
                            

                            <Link to="/contact-us" className="font-bold tracking-wide lg:w-1/2 w-full px-10 py-2  text-white bg-black hover:bg-primary hover:text-black k button-animate  text-sm">
                                Request for Trial
                            </Link>
                        </div>

                        <div
                            className="w-full md:flex md:justify-end z-10">
                            <div className="lg:w-[80%] w-full  border border-gray-500 rounded bg-black lg:p-8 p-4 flex flex-col space-y-5  ">
                                <img src="/assets/images/Perimeter1.jpg" alt="i2v" />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className=' lg:px-[4rem] px-5 lg:py-10'>

                <div className='bg-black lg:px-8 px-5 rounded'>
                    <div className='py-8 '>
                        <h1 className='lg:text-xl text-base text-white text-left tracking-tighter font-bold'><span className='bg-primary lg:px-10 px-3  py-1 rounded-t-md'> features Highlights </span></h1>
                        <div className="h-[0.10rem] lg:w-[70%] bg-primary  w-[90%] mt-1 px-"></div>
                        <motion.ul className="list-disc text-white text-start md:p-6 py-6 pl-6 space-y-2"
                            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
                            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
                            transition={{ duration: isInView ? 1 : 0 }}
                        >
                            <li>Supports virtual lines of all types Vertical, Horizontal and Slanted.</li>
                            <li>Effective for 24x7 round the clock monitoring with even minimum illumination.</li>
                            <li>User can select any number of objects to be watched by the Analytic.</li>
                            <li>Very good performance even for watching multiple objects in the single video.</li>
                            <li>Well suited to Indian Requirements</li>
                            <li>Very useful for round the clock monitoring of valuables in Museums.</li>
                            <li>Can be used of protecting the sensitive objects put for show case like gold, ornaments etc.</li>
                            <li>Can be used for Equipment monitoring of computers, servers in sensitive server rooms.</li>
                            <li>Can be used for protection of assets in banks and other financial locations.</li>
                            <li>Useful for eye watch of items put for display during product exhibitions.</li>
                        </motion.ul>
                    </div>
                </div>
            </div>
            
            <Footer />
        </>
    )
}

export default VideoPerimeterTripwire