import React, { useRef } from "react";
import Navbar from "../components/common/Navbar";
import ContactFooter from "../components/Home.js/ContactFooter";
import Footer from "../components/common/Footer";
import { useInView } from "framer-motion";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { AiFillContacts } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { MdContactMail, MdContactPhone, MdEmail } from "react-icons/md";

const CarrearPages = () => {
  const data = [
    {
      id: 1,
      jobid: "i2V/CVE/01",
      title: " Computer Vision Engineer",
      des: " We are looking for highly skilled computer vision engineers who are comfortable with the development and optimization of computer vision systems. You will be responsible for solving customer problems using computer vision, you will have to train models, optimize them and create inference solutions that can run those models/solutions on the scale and edge hardware.",
      path: "/computer-vision-engineer"
    },
    {
      id: 2,
      jobid: "i2V/FSE/02",
      title: " Full Stack Developer",
      path: "/fullstack-developer",
      des: "We are looking for highly skilled computer programmers who are comfortable with both front and back-end programming. You are responsible for developing and designing frontend and backend architecture, ensuring the responsiveness and scaling of applications. You will be required to see out a project from conception to the final product, requiring good organizational skills and attention to detail."
    },
    {
      id: 3,
      jobid: "i2V/SE/03",
      title: " Software Developer",
      path: "/software-developer",
      des: "We are looking for passionate Software Engineers to design, and develop software solutions. You will be responsible for developing and designing architecture, ensuring the responsiveness and scaling of applications. You will be required to see out a project from conception to the final product, requiring good organizational skills and attention to detail. What you bring to the table is more than just your technical skills - your unique perspective, ideas, and cultures will help us create better products and services."
    },
    {
      id: 4,
      jobid: "i2V/RSM/04",
      title: " Regional Sales Manager West/South/East India",
      path: "/regionalsales-manager",
      des: <ul className="list-disc px-6">
        <li>Managing sales in assigned territory through channel management.</li>
        <li>Develop channels by exploring additional channel partners.</li>
        <li>Develop and implement strategic sales plans to meet revenue objectives.</li>
      </ul>
    },
    {
      id: 5,
      jobid: "i2V/TSE-S/05",
      title: " Technical Sales Engineer - South India",
      path: "/technical-sales-engineer",
      des: <ul className="list-disc px-6">
        <li>New business development & existing Client relationship management.</li>
        <li>Prepare technical solutions & proposals.</li>
        <li>Delivering product demos and presentations.</li>
        <li>Project coordination & order execution.</li>
        <li>Billing & Collections.</li>
      </ul>
    },
    {
      id: 6,
      jobid: "i2V/ASE-S/06",
      title: " Software Support Professional",
      path: "/Software-Support-Professional",
      des: <ul className="list-disc px-6">
        <li>We are looking for a Technical Support Engineer to provide enterprise-level assistance to our customers. You will diagnose and troubleshoot software and hardware problems and help our customers install applications and programs.
</li>

      </ul>
    },
    {
      id: 7,
      jobid: "i2V/BSE-S/07",
      title: " Quality Assurance Professional",
      path: "/Quality-Assurance-Professional",
      des: <ul className="list-disc px-6">
        <li>We are looking for skilled software test engineers to join our QA team. As a Quality Assurance Professional, you will be responsible for evaluating software functions and designing test protocols to ensure that products work as intended. You will also be responsible for completing all testing procedures, suggesting performance changes, and preparing performance reports for the development team to improve on.</li>

      </ul>
    },
  ]

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })



  return (
    <div>
      <Navbar />

      <div className="pt-[70px]">
        <div
          className="w-full lg:h-[60vh] h-[40vh] bg-no-repeat bg-center bg-cover rounded-sm"
          style={{ backgroundImage: 'url("/assets/images/careerpage.jpg")', }}
        >
          <div className="w-full lg:h-[60vh] h-[40vh] bg-black bg-opacity-50 flex justify-center items-center">
            <div className="lg:space-y-6">
              <motion.div

                initial={{ x: -50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1 }}
                className=" w-full">
                <h1 className="space-y-2 fontfamily text-center  textshadow  text-xl p-3  lg:text-5xl text-white   font-semibold textshadow" style={{ textShadow: "2px 2px 5px black" }}>
                  <span className="text-white"> CAREERS </span>  AT i2V
                </h1>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 lg:px-[4rem]  px-5  ">
        <div className="grid grid-cols-1 gap-16 lg:grid-cols-2 justify-items-center items-start">
          <div className="">
            <h1 className="text-[26px] text-black font-bold text-start w-full">
            Life @
              <strong className="text-[#EA1D24] font-semibold"> i2V SYSTEMS</strong>
            </h1>
            <p className="bg-primary h-[2px]"></p>
            <p className="text-[16px] text-[#000000] text-justify pt-5">
            At i2V, we strongly believe that "Innovation is the future". Our focus lies in bringing aboard some of the finest minds in the industry and providing them with opportunities that will match their drive and ability. At i2V, we've created a difference in high-end Video Based Security Solutions by ensuring that we not only have the best solutions in the industry but the best people too. i2V team is inspired, professional, and committed to achieving a common goal of creating a difference in IP Video Solutions over the globe. If you want to become an integral part of an organization that is built and driven by people then you are the right person we are looking for.
            </p>
            <p className="text-[16px] text-[#000000] text-justify pt-5">
            We are an equal opportunities employer with a comprehensive policy backed up by appropriate procedures, training, and monitoring. We are committed to the principles of Investors in people and ensure that every employee receives the guidance & training they require to achieve their full potential and goals.</p>

            <p className="text-[16px] text-[#000000] text-justify pt-5">
            The successful candidates will involve in the design, development, and testing of world-class video security software products. Job openings at i2V call for a lot of dedication and passion towards design and developing innovative products.</p>
          </div>

          <motion.div className=" md:px-4 space-y-4 "
            ref={ref}
            initial={{ y: isInView ? 0 : 200, opacity: isInView ? 0 : 1 }}
            animate={{ y: isInView ? 0 : 100, opacity: isInView ? 1 : 0 }}
            transition={{ duration: isInView ? 1 : 0 }}
          >
            {/* <div className="bg-black  p-8 space-y-8 rounded-lg shadow">
              <div>
                <h2 className="text-white text-justify text-bold   uppercase">
                  Search Job Here...
                </h2>
                <p className="w-full    bg-white text-white h-1"></p>
              </div>
              <div className="grid grid-cols-2 gap-4  ">
                <div className="relative">
                  <img
                    src="/assets/images/title.png"
                    className="absolute py-2 left-2"
                  />
                  <input
                    type="text"
                    name="game"
                    placeholder="Title"
                    className="text-white bg-black border border-white w-full rounded-md p-4 h-[25px] pl-9"
                  />
                </div>
                <div className="relative">
                  <img
                    src="/assets/images/location.png"
                    className="absolute py-2 left-2"
                  />
                  <input
                    type="text"
                    name="game"
                    placeholder="Location"
                    className="text-white  bg-black border border-white w-full rounded-[5px] p-4 h-[25px] pl-7"
                  />
                </div>
              </div>
              <button className="w-full  py-2 bg-[#EA1D24] hover:bg-white hover:text-black cursor-pointer hover:font-bold text-white rounded-md">
                Search Job
              </button>
            </div> */}



            <div
              className="shadow-2xl shadow-gray-700 rounded-2xl">
              <div className="lg:p-12 p-8 space-y-8 ">
                <div className="lg:px-16 px-5">
                  <div className="text-center bg-black hover:text-black text-black  py-2 rounded-md">
                    <h3 className="text-white uppercase ">Popular Jobs</h3>
                  </div>
                </div>

                <div className="bg-[#EA1D24] rounded  p-4 flex flex-col space-y-6 ">
                  <div className=" flex ">
                    <div className=" lg:p-2 p-4 bg-white">
                      <img
                        src="/assets/images/jobs.png"
                        className="lg:px-2    "
                      />
                    </div>
                    <div className="flex flex-col px-3">
                      <p className="text-white text-start lg:text-[18px]  "><strong className="font-bold">Title</strong> : Software Support Professional</p>
                      <h1 className="text-white text-start pt-2">JOB ID : i2V/ASE-S/06</h1>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    {/* <h2 className="text-white text-left md:text-[15px] lg:text-[16px] text-[15px] pr-1">55 People around for the jobs</h2> */}

                    <Link to="/Software-Support-Professional"> <button className="btn bg-white text-black px-7 py-1 font-bold rounded-md  hover:bg-black hover:font-bold  hover:text-white">Open</button>
                    </Link>
                  </div>
                </div>

                <div className="bg-[#EA1D24] rounded  p-4 flex flex-col space-y-6 ">
                  <div className=" flex ">
                    <div className=" lg:p-2 p-4 bg-white">
                      <img
                        src="/assets/images/jobs.png"
                        className="lg:px-2    "
                      />
                    </div>
                    <div className="flex flex-col px-3">
                      <p className="text-white text-left lg:text-[18px]  "><strong className="font-bold">Title</strong> : Quality Assurance Professional</p>
                      <h1 className="text-white text-start pt-2">JOB ID : i2V/BSE-S/07</h1>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    {/* <h2 className="text-white text-left md:text-[15px] lg:text-[16px] text-[15px] pr-1">32 People around for the jobs</h2> */}

                    <Link to="/Quality-Assurance-Professional"><button className="btn bg-white text-black px-7 py-1 font-bold rounded-md  hover:bg-black hover:font-bold  hover:text-white">Open</button>
                    </Link>
                  </div>
                </div>



              </div>
            </div>
          </motion.div>

        </div>
      </div>


<div
                className="lg:px-20 px-5 pt-12" style={{marginBottom: '-57px'}}
                >
                    <h3 className="text-[26px] text-black font-bold text-start w-full">
                CONTACT FOR CAREER AND JOBS
                </h3>
                <div className="h-1 w-full bg-primary"></div>


                <div className="flex md:flex-row flex-col justify-between my-8 md:divide-x-2 divide-x-0 md:divide-y-0 divide-y-2 gap-4 pb-4">
                  <div className="space-y-2">
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <AiFillContacts size={33} className="text-primary" />
                      </p>
                      <p className="text-black text-left pt-1 lg:text-md text-sm font-semibold">
                      Monika Khetarpal
                      </p>
                    </div>
                    <div className="flex">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactMail size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                      careers@i2vsys.com
                      </p>
                    </div>
                    <div className="flex ">
                      <p className="  lg:text-xl   text-lg font-bold text-body-color leading-relaxed  mx-5">
                        <MdContactPhone size={30} className="text-primary" />
                      </p>
                      <p className="text-black text-left  lg:text-md text-sm font-semibold">
                        +91 880-059-7104
                      </p>
                    </div>
                  </div>
</div>
</div>

      <div className="lg:px-20 px-5 pt-12">
        <h3 className="text-[26px] text-black font-bold text-start w-full">
          Current Job Openings
        </h3>
        <div className="h-1 w-full bg-primary"></div>

      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12  md:my-16 my-8 gap-8 px-[5%]">
        {
          data.map((obj, e) => (
            <div className="col-span-4 px-5 py-5 bg-black rounded-2xl flex flex-col justify-between">
              <h1 className="text-white text-left py-2">
                <strong className="text-[#EA1D24]">JOB ID :</strong> {obj.jobid}
              </h1>
              <h1 className="text-white text-left ">
                <strong className="text-[#EA1D24]">Title :</strong>{obj.title}
              </h1>
              <p className="text-sm text-white text-justify py-6">
                <strong className="text-[#EA1D24]">Job Description :</strong> {obj.des}
              </p>
              <Link to={obj.path}> <h4 className="text-[#EA1D24] hover:text-white text-right">Read More +</h4></Link>
            </div>
          ))
        }

      </div>
      <ContactFooter />
      <Footer />
    </div>
  );
};

export default CarrearPages;
